import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

export const banner = cdn('images/sharing/banner.png', 2000);
export const carousel = [
    {
        image: cdn('images/sharing/case-1.png', 700),
        text: {
            title: '只二 奢侈品Vintage线下探店',
            desc: '合作亮点：助力品牌线上线下渠道融合 ',
        },
    },
    {
        image: cdn('images/sharing/case-2.png', 700),
        text: {
            title: 'DIOR包包',
            desc: '合作亮点：高级质感达人  ，精准定位目标人群',
        },
    },
    {
        image: cdn('images/sharing/case-3.png', 700),
        text: {
            title: '太平鸟男装',
            desc: '合作亮点：cpe2.8; 阅读量70w+，休闲潮流年轻穿搭达人助力新品推广',
        },
    },
    {
        image: cdn('images/sharing/case-4.png', 700),
        text: {
            title: 'TF 新品 金箔唇膏釉',
            desc: '合作亮点：cpe2.27；阅读量120w+；互动量过千爆文6篇',
        },
    },
    {
        image: cdn('images/sharing/case-5.png', 700),
        text: {
            title: '芭比波朗 橘子面霜',
            desc: '合作亮点：总互动=25487 cpe=2.75  阅读量837077  爆文量：百赞以上12位。3天发布300+笔记， 高效执行服务。',
        },
    },
    {
        image: cdn('images/sharing/case-6.png', 700),
        text: {
            title: '科颜氏 A醇精华专柜探店',
            desc: '合作亮点：新品上市，线上线下联动推广 ，立体种草',
        },
    },
    {
        image: cdn('images/sharing/case-7.png', 700),
        text: {
            title: '芭比波朗卸妆油 ',
            desc: '合作亮点：cpe2.36  阅读量83W+助力产品口碑沉淀 打造爆款',
        },
    },
];

export const koc = cdn('images/sharing/koc.png');
export const logos = cdn('images/sharing/logos.png');
export const minip = cdn('images/sharing/minip.png');
export const minipBrand = cdn('images/sharing/minipBrand.png');
export const prodInfo = cdn('images/sharing/prodInfo.png');
export const qrcode = cdn('images/sharing/qrcode.png');
export const wxQrcode = cdn('images/sharing/wx-qrcode.png');

let arrayList = [];
for (let i = 1; i < 37; i++) {
    arrayList.push(cdn(`images/sharing/logos/logo-${i}.png`));
}
export const logoList = arrayList;

/*
https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/sharing/case-1.png?x-oss-process=image/resize,w_200/auto-orient,1*
* */
