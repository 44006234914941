import React, { Component, Fragment } from 'react';
import useAssets from '../../common/useAssets';
const cdn = useAssets(false);

class Footer extends Component {
    render() {
        const { imgurl,videoPath, muted = false } = this.props;
        if(imgurl)
        return (
            <Fragment>
                <img style={{width:'100%'}} className="" src={imgurl} alt="bannner" />
            </Fragment>
        );
        else if(videoPath){
            return   <Fragment><video muted={muted} width="100%" autoPlay loop src={cdn(videoPath)} ></video></Fragment>
        }
    }
}

export default Footer;