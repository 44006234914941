import React, { Component, Fragment } from 'react';
import Header from '../../layout/header/index';
import Footer from '../../layout/footer';
import Banner from '../../layout/banner';
import ContentItem from '../../element/contentItem';
import './index.css';
import Arcboard from './element/arcboard';
import Waterfall from './element/waterfall';
import {Subtract} from "../advertising/data";


class coBranding extends Component {
    componentDidMount() {
        document.title = '如涵品牌合作-如涵官网';
    }
    render() {
 
        return (
            <Fragment>
                <div className="coBranding flexccc" >
                    <Header />
                    <div style={{ width: '100%', marginTop: '-72px' }}>
                        <Banner muted videoPath={'video/coBranding/banner.mp4'} />
                    </div>
                    <ContentItem name="品牌合作" >
                        <img className="Subtract" src={Subtract} alt="圈" />
                        <div className="coBrandingTitle" >
                            作为网红电商的开创者和领导者
                        </div>
                        <div className="coBrandingTitle">
                            为3000+国内外品牌提供从广告到转化的全链路营销获客解决方案
                        </div>
                        <div className="coBrandingTitle">
                            致力于为更多品牌实现品效合一
                        </div>
                        <div className="coBrandingTitle">
                            2020年天猫双11带货机构Top1
                        </div>
                        <Arcboard containerWidth={1260} boxHeight={360} duration={20} logoSize={80} />
                        <Waterfall />
                    </ContentItem>
                    <Footer />
                </div>
            </Fragment>
        )
    }
}
export default coBranding;