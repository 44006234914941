import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);
export const detailList = [{
        name: '@祖祖zuzu_',
        id: null,
        tags: ['伦敦艺术大学留学背景', '时尚敏感度拉满的精致女孩', '热衷美妆时尚的恋爱达人'],
        introduction: '她是撩人于无形的纯欲少女，热爱记录分享生活；她是高级又精致的港风美人，钟爱美妆颜值出挑；用图片表达属于自己的韵味，是独一无二的祖祖',
        hotSpots: [
            '伦敦艺术大学留学背景，珠宝设计专业，对时尚领域了解颇深，口播能力强',
            '粉丝年龄涵盖17～45岁女性，以一二线数量居多，粉丝购买能力强粘性高',
            '曾于兰蔻、雅诗兰黛、赫莲娜、YSL合作'
        ],
        images: [
            cdn('images/introduce/祖祖zuzu_/1.jpg'),
            cdn('images/introduce/祖祖zuzu_/2.jpg'),
            cdn('images/introduce/祖祖zuzu_/3.jpg'),
            cdn('images/introduce/祖祖zuzu_/4.jpg'),
            cdn('images/introduce/祖祖zuzu_/5.jpg')
        ]
    },
    {
        name: '@張人0',
        id: null,
        tags: ['镜头表现力抓眼的邻家弟弟'],
        introduction: '他是日杂漫画里的男孩；是下课铃响时，女孩们在书本下偷看的影子；他喜欢用镜头记录生活的点滴；让粉丝们看见他的绚烂世界',
        hotSpots: [
            '合作过LA MER、香奈儿、阿玛尼、CPB、雅诗兰黛、飞利浦、索尼等一线品牌',
            '账号互动数据好，基本每周一篇破万点赞爆文'
        ],
        images: [
            cdn('images/introduce/張人0/1.jpg'),
            cdn('images/introduce/張人0/2.jpg'),
            cdn('images/introduce/張人0/3.jpg'),
            cdn('images/introduce/張人0/4.jpg'),
            cdn('images/introduce/張人0/5.jpg')
        ]
    },
    {
        name: '@元气菊仔',
        id: 50,
        tags: ['热爱美食的肌肉女孩', '爆款频出的健身达人', '口播风格超嗨的分享型博主'],
        introduction: '她是热衷安利运动技巧与运动穿搭的健身博主,用镜头传递快乐，输出元气生活观',
        hotSpots: [
            '曾合作LA MER、娇韵诗、安德玛、特步、Ulike、雀巢、Blankme等多品类品牌',
            '微博爆款视频#帕梅拉跟练详解#播放量330w',
            'B站爆款视频#30天减重15斤经验分享#播放量251w'
        ],
        images: [
            cdn('images/introduce/50/1.jpg'),
            cdn('images/introduce/50/2.jpg'),
            cdn('images/introduce/50/3.jpg'),
            cdn('images/introduce/50/4.jpg'),
            cdn('images/introduce/50/5.jpg')
        ]
    },
    {
        name: '@-鱼九九-',
        id: 44,
        tags: ['复古风时尚穿搭红人', '轻敏混油肌彩妆分享达人', '96年嫩颜已婚少妇'],
        introduction: '撞脸易梦玲、神似倪妮，复古和时尚兼容，少女外表下蕴藏着轻熟气质还有个同样颜值狙击、唱跳全能的弟弟共同演绎现实版“家有儿女”',
        hotSpots: [
            '杭州国际时尚周2020年度时尚颁奖典礼 获时尚新锐红人奖',
            'B站爆款#亲弟弟被我化成韩系美少女？#获全区排行榜TOP1',
            '微博爆款#乖乖女打扮夜店女郎风# 获微博VLOG红人榜TOP3',
            '受邀参加Dior全新迪奥烈艳蓝金唇膏发布会',
            '受邀参加芭比布朗x许光汉线下见面活动'
        ],
        images: [
            cdn('images/introduce/44/1.jpg'),
            cdn('images/introduce/44/2.jpg'),
            cdn('images/introduce/44/3.jpg'),
            cdn('images/introduce/44/4.jpg'),
            cdn('images/introduce/44/5.jpg')
        ]
    },
    {
        name: '@怡含怡含',
        id: 34,
        tags: ['韩系时尚彩妆分享红人', '韩国音乐留学生'],
        introduction: '她是热爱记录生活分享美的韩系少女；她是艳压群芳的精致美人',
        hotSpots: ['2020年度微博（浙江）新锐红人', '合作过LA MER、祖玛珑 、FENDI 、CHANEL、卡地亚等一线大牌'],
        images: [
            cdn('images/introduce/34/1.jpg'),
            cdn('images/introduce/34/2.jpg'),
            cdn('images/introduce/34/3.jpg'),
            cdn('images/introduce/34/4.jpg'),
            cdn('images/introduce/34/5.jpg')
        ]
    },
    {
        name: '@笑笑同学啊_',
        id: 12,
        tags: ['镜头感超强的前职业摄影师', '爆款频出的时尚美妆达人'],
        introduction: '穿搭分享，爆文频出；帅气知性随意切换；风格百变引领潮流',
        hotSpots: [
            '超高粉丝粘性，粉丝群人数5000+',
            '美妆护肤3C数码全品类深度种草',
            '合作过兰蔻、雅诗兰黛、娇韵诗、戴森、马歇尔、华为、飞利浦等多元品牌'
        ],
        images: [
            cdn('images/introduce/12/1.jpg'),
            cdn('images/introduce/12/2.jpg'),
            cdn('images/introduce/12/3.jpg'),
            cdn('images/introduce/12/4.jpg'),
            cdn('images/introduce/12/5.jpg')
        ]
    },
    {
        name: '@小野智恩',
        id: 10,
        tags: ['小个子日韩系穿搭达人', '亲和力超强的生活分享达人'],
        introduction: '幼态感超强的话痨小可爱，善于在广告中融入自己的灵魂，路人好感度强；距离地表158的小个子大能量时尚博主，女团既视感',
        hotSpots: [
            '三次登上微博热搜榜，累计曝光 4000w+',
            '私域规模达7000+，粉丝粘性极高',
            '迪士尼及各大奢侈品牌的宠儿，多次顺利合作'
        ],
        images: [
            cdn('images/introduce/10/1.jpg'),
            cdn('images/introduce/10/2.jpg'),
            cdn('images/introduce/10/3.jpg'),
            cdn('images/introduce/10/4.jpg'),
            cdn('images/introduce/10/5.jpg')
        ]
    },
    {
        name: '@小魏huhu',
        id: 22,
        tags: ['高颜值播音系学姐', '欢乐学生党美妆博主'],
        introduction: '她是神似欧阳娜娜和宋妍霏的高级脸学生党，颜值出挑，酷炫拽姐；她外表高冷，内心沙雕，喜欢干饭和麻将，花样安利宿舍精致好物',
        hotSpots: ['合作过赫莲娜、资生堂、卡姿兰、逐本、943等品牌'],
        images: [
            cdn('images/introduce/22/1.jpg'),
            cdn('images/introduce/22/2.jpg'),
            cdn('images/introduce/22/3.jpg'),
            cdn('images/introduce/22/4.jpg'),
            cdn('images/introduce/22/5.jpg')
        ]
    },
    {
        name: '@王宛尘',
        id: 11,
        tags: ['毒蛇宝藏重庆“王子文”', '轻熟龄肌，分享30+小姐姐质感生活'],
        introduction: '8年美妆路，妆前妆后反差巨大，输出新手小白也学得会的干货宝典；5年老综艺咖，口播生动超接地气',
        hotSpots: [
            '杭州国际时尚周2020年度时尚颁奖典礼 获年度时尚红人奖',
            '微博2019年超级红人节 获年度新锐时尚美妆红人',
            '微博爆款 #救命！大胸厚背千万别买的死亡毛衣# 获微博时尚美妆类目日榜TOP1',
            '爆款 #3D化妆视频# 上榜小红书美妆区热门；微博时尚美妆榜第二名',
            '作为《真相吧花花万物》常驻嘉宾，和小S、蔡康永、林更新等人同台，通过分析艺人购物账单，揭露隐藏在购物车里的秘密，输出个人的消费观点和生活态度'
        ],
        images: [
            cdn('images/introduce/11/1.jpg'),
            cdn('images/introduce/11/2.jpg'),
            cdn('images/introduce/11/3.jpg'),
            cdn('images/introduce/11/4.jpg'),
            cdn('images/introduce/11/5.jpg')
        ]
    },
    {
        name: '@陶四七-',
        id: 49,
        tags: ['同济大学毕业的艺术系高知美女', '高奢宠爱的行走画报', '高甜撒糖cp'],
        introduction: '她是自带高级感的气质美女，热爱分享记录生活她是爱买爱种草的时尚博主，颜值出挑种草力强',
        hotSpots: [
            '双胞胎姐妹，颜值姐妹花double种草力，双胞胎博主可做联动内容',
            '受邀参加Byfar、TomFord线下展',
            '合作过三宅一生、YSL、雅诗兰黛、阿玛尼、祖玛珑、兰蔻等一线大牌'
        ],
        images: [
            cdn('images/introduce/49/1.jpg'),
            cdn('images/introduce/49/2.jpg'),
            cdn('images/introduce/49/3.jpg'),
            cdn('images/introduce/49/4.jpg'),
            cdn('images/introduce/49/5.jpg')
        ]
    },
    {
        name: '@桃瑞思',
        id: 52,
        tags: ['东华大学毕业的高颜值时髦博主', '集"高奢"与"生活"于一身的分享达人'],
        introduction: '她是多金高产的气质白富美，小红书优质爆文制造机;她是时髦高级的时尚博主，掌握一手时尚、美妆流量密码;情侣爆笑日常与超有趣干货vlog;',
        hotSpots: [
            '受邀参加Byfar、TomFord、华伦天奴、LV等线下活动, 资生堂线下直播活动，时尚活动宠儿',
            '合作过CHANEL、DIOR、YSL、华伦天奴等一线高奢品牌，以及LA MER、赫莲娜、雅诗兰黛、资生堂、宝洁、祖玛珑，浪凡、三宅一声、太平鸟、唯品会等多元品类品牌'
        ],
        images: [
            cdn('images/introduce/52/1.jpg'),
            cdn('images/introduce/52/2.jpg'),
            cdn('images/introduce/52/3.jpg'),
            cdn('images/introduce/52/4.jpg'),
            cdn('images/introduce/52/5.jpg')
        ]
    },
    {
        name: '@汤几一',
        id: 20,
        tags: ['日系时尚ICON'],
        introduction: '她是广美毕业的干货美妆达人；古灵精怪，粉丝宠爱；气质独特的日系时尚博主，从穿搭到日化，钟爱分享',
        hotSpots: ['合作过娇韵诗、祖玛珑、海马体、OPPO等多元品类品牌'],
        images: [
            cdn('images/introduce/20/1.jpg'),
            cdn('images/introduce/20/2.jpg'),
            cdn('images/introduce/20/3.jpg'),
            cdn('images/introduce/20/4.jpg'),
            cdn('images/introduce/20/5.jpg')
        ]
    },
    {
        name: '@琪崽崽',
        id: 39,
        tags: ['学生党博主', '可爱幼龄感少女'],
        introduction: '日韩风格、甜美气质、小红书最爱的少女感甜妹；网感在线，优质网图女头代言人，爆款多多，粉丝宠爱',
        hotSpots: ['女性粉丝占比超97%，Z世代粉丝占比超82%', '合作过LA MER、菲洛嘉、科颜氏、施华蔻、舒肤佳等多元品类品牌'],
        images: [
            cdn('images/introduce/39/1.jpg'),
            cdn('images/introduce/39/2.jpg'),
            cdn('images/introduce/39/3.jpg'),
            cdn('images/introduce/39/4.jpg'),
            cdn('images/introduce/39/5.jpg')
        ]
    },
    {
        name: '@彭特务',
        id: 3,
        tags: ['敏感肌彩妆换头技术流', 'TVC质感大片出品'],
        introduction: '她是一线大牌宠儿，持续产出爆款内容的高产达人，优质栏目“美妆情报局”多次荣登微博热搜，优质栏目“buybuy”成为百万粉丝心目中的每月种草指南；常年做客各大品牌直播间担任直播嘉宾，均场数据环比上涨200%+',
        hotSpots: [
            '受邀参加LV、YSL、雅诗兰黛、雪花秀等大牌线下活动',
            '广告数据优异且稳定，内容平均阅读量600W+，互动平均7W+'
        ],
        images: [
            cdn('images/introduce/3/1.jpg'),
            cdn('images/introduce/3/2.jpg'),
            cdn('images/introduce/3/3.jpg'),
            cdn('images/introduce/3/4.jpg'),
            cdn('images/introduce/3/5.jpg')
        ]
    },
    {
        name: '@腻腻ninii',
        id: 30,
        tags: ['超模质感的时髦达人', '高奢挚爱，高调品宣'],
        introduction: '她是可盐可欲的反转女孩，感染力拉满，大牌青睐；她是时髦高挑的穿搭博主，任何风格轻松驾驭',
        hotSpots: [
            '合作过FENDI 、DIOR、CHANEL、BVLGARI  、LV等一线大牌广告',
            '美宝莲彩妆合作模特',
            '受邀参与《尼龙》、《ELLE》、《卷宗 Wallpaper》等杂志拍摄',
            '作为女主参演小鬼MV'
        ],
        images: [
            cdn('images/introduce/30/1.jpg'),
            cdn('images/introduce/30/2.jpg'),
            cdn('images/introduce/30/3.jpg'),
            cdn('images/introduce/30/4.jpg'),
            cdn('images/introduce/30/5.jpg')
        ]
    },
    {
        name: '@梁一口echo',
        id: 24,
        tags: ['B站知名up主', '邻家妹妹系生活VLOG分享红人'],
        introduction: '她是天生睫毛精的憨憨少女，拥有邻家妹妹的外表却能驾驭超质感时尚大片；她是热爱分享生活碎片的鬼马少女，#闺蜜系列#、#周末企划vlog#、#三万步挑战#给无数粉丝带来治愈和快乐',
        hotSpots: [
            '受邀参加杭州 #看见 LV# 开幕活动',
            '受邀参加 #FENDI Summer Vertigo#',
            '官宣成为 ELLE 潮镜主理人',
            '官方认证特斯拉“旅行特约体验官”',
            '作为知名时尚、视频博主共创视频内容并发布，后续将为#尼龙20218月刊#拍摄内页'
        ],
        images: [
            cdn('images/introduce/24/1.jpg'),
            cdn('images/introduce/24/2.jpg'),
            cdn('images/introduce/24/3.jpg'),
            cdn('images/introduce/24/4.jpg'),
            cdn('images/introduce/24/5.jpg')
        ]
    },
    {
        name: '@梁潇Ivan',
        id: null,
        tags: ['治愈系暖男', '生活分享vlogger', '哈工大硕士高学历背书'],
        introduction: '他是热衷于记录温馨生活的vlogger，通过治愈画面,打造质感家居场景，传递积极的生活理念',
        hotSpots: ['曾与玛莎拉蒂、Tiffany、dior浪凡、祖玛珑、馥马尔等多品类一线大牌合作'],
        images: [
            cdn('images/introduce/梁潇Ivan/1.jpg'),
            cdn('images/introduce/梁潇Ivan/2.jpg'),
            cdn('images/introduce/梁潇Ivan/3.jpg'),
            cdn('images/introduce/梁潇Ivan/4.jpg'),
            cdn('images/introduce/梁潇Ivan/5.jpg')
        ]
    },
    {
        name: '@精致大姨夫',
        id: 4,
        tags: ['专业护肤产品测评成分党'],
        introduction: '他是深耕护肤垂类行业17年的专业成分党，真实测评上万款护肤品，以亲切人设，幽默口播，真诚实力干货，深得粉丝喜爱',
        hotSpots: [
            '943品牌复投合作6次，助力国货从0到1逐步打爆声量，建立品牌影响力',
            '2021淘美妆年度最具影响KOL/2021美伊最具影响力匠人创客大奖/2021千瓜小红书全领域最具影响力创作',
            '参加小红书国货节拍摄，作为国货推荐官登上小红书开屏'
        ],
        images: [
            cdn('images/introduce/4/1.jpg'),
            cdn('images/introduce/4/2.jpg'),
            cdn('images/introduce/4/3.jpg'),
            cdn('images/introduce/4/4.jpg'),
            cdn('images/introduce/4/5.jpg')
        ]
    },
    {
        name: '@鸽鸽dovie',
        id: 16,
        tags: ['清纯韩系原相机女孩', '淡颜风格彩妆分享家'],
        introduction: '她是干敏皮痘痘肌，热衷分享超多护肤干活；她是韩系青春女孩，学院风穿搭实力种草',
        hotSpots: ['女性粉丝占比超95%，Z世代粉丝占比超68%', '曾与雅诗兰黛、MAC、戴森、PMPM、covernat 等品牌合作'],
        images: [
            cdn('images/introduce/16/1.jpg'),
            cdn('images/introduce/16/2.jpg'),
            cdn('images/introduce/16/3.jpg'),
            cdn('images/introduce/16/4.jpg'),
            cdn('images/introduce/16/5.jpg')
        ]
    },
    {
        name: '@邓二二二单',
        id: null,
        tags: ['生活vlogger', '穿搭类分享达人'],
        introduction: '她是自信阳光的vlog博主，分享生活传递能量 她是创意满满的时尚达人，分享穿搭表达态度',
        hotSpots: ['曾与Dior、兰蔻、东风汽车、悦木之源、蕉下等多品类品牌合作'],
        images: [
            cdn('images/introduce/邓二二二单/1.jpg'),
            cdn('images/introduce/邓二二二单/2.jpg'),
            cdn('images/introduce/邓二二二单/3.jpg'),
            cdn('images/introduce/邓二二二单/4.jpg'),
            cdn('images/introduce/邓二二二单/5.jpg')
        ]
    },
    {
        name: '@吃瓜金仔',
        id: null,
        tags: ['轻剧情型美妆达人', '甜酷风时尚红人'],
        introduction: '她是超爱演的的戏精少女，热衷于分享关于美的一切事物；擅长各种甜酷妆容与穿搭，专业红人职业素养配合度超级高！不世俗不媚男！大胆鼓励女生独立做自己！',
        hotSpots: ['以1条优质内容就涨粉10w+', '曾与娇韵诗、花西子、NARS 、美宝莲、卡姿兰、彩棠、珀莱雅等品牌合作'],
        images: [
            cdn('images/introduce/吃瓜金仔/1.jpg'),
            cdn('images/introduce/吃瓜金仔/2.jpg'),
            cdn('images/introduce/吃瓜金仔/3.jpg'),
            cdn('images/introduce/吃瓜金仔/4.jpg'),
            cdn('images/introduce/吃瓜金仔/5.jpg')
        ]
    },
    {
        name: '@曾子容',
        id: 13,
        tags: ['野生但在努力的美妆博主'],
        introduction: '她是正能量欧美风美女，鼓励粉丝自信酷飒做自己，用镜头表达属于自己的态度；美女的事情你少管，美女的事情自己担',
        hotSpots: [
            '抖音爆款视频 #人间富贵花妆# 单篇播放量1574.5w+，互动量62.7w+',
            '曾与YSL、Urban Decay、毛戈平、植村秀、ZELENS、NARS 等彩妆品牌合作'
        ],
        images: [
            cdn('images/introduce/13/1.jpg'),
            cdn('images/introduce/13/2.jpg'),
            cdn('images/introduce/13/3.jpg'),
            cdn('images/introduce/13/4.jpg'),
            cdn('images/introduce/13/5.jpg')
        ]
    },
    {
        name: '@布兰尼老田田',
        id: 6,
        tags: ['专业美妆技术流', '沉浸式专栏爆款频出'],
        introduction: '她是换头级美妆博主，拥有国际注册高级化妆师资格证背书；实力打造沉浸式专栏，沉浸式爆款护肤/仿妆/妆教笔记层出不穷；具备专业博主职业素养，商务合作低至CPE1.3元/次',
        hotSpots: [
            '曾与La mer、HR、YSL、雅诗兰黛、Tom Ford、资生堂、欧莱雅等大牌合作',
            '小红书爆款视频#最适合新手的妆原来这么化#单篇播放量372w+，互动量55w+'
        ],
        images: [
            cdn('images/introduce/6/1.jpg'),
            cdn('images/introduce/6/2.jpg'),
            cdn('images/introduce/6/3.jpg'),
            cdn('images/introduce/6/4.jpg'),
            cdn('images/introduce/6/5.jpg')
        ]
    },
    {
        name: '@宝剑嫂',
        id: 2,
        tags: ['B站百大UP主', '戏精搞笑，邻家亲切'],
        introduction: '她是微博、B站双平台TOP级美妆博主,以亲切搞笑的风格获得大批粉丝的喜爱;曾与郭采洁、金莎、陈坤等明星合作栏目;受邀参加《非正式会谈》、《去你家吃饭好吗》、《90婚介所》等综艺,资源丰富',
        hotSpots: [
            '获得2020金狮国际广告影片奖—最具商业价值KOL',
            '2019—2020 TOPKLOUT AWARDS—最具影响力时尚自媒体 ',
            '2019—2020 TOPKLOUT AWARDS—最具影响力种草红人',
            '微博2020潮流趋势大赏十大美妆博主 ',
            '众多合作明星：金莎、于贞、郭采洁、陈坤、周洁琼、张雨绮等等'
        ],
        images: [
            cdn('images/introduce/2/1.jpg'),
            cdn('images/introduce/2/2.jpg'),
            cdn('images/introduce/2/3.jpg'),
            cdn('images/introduce/2/4.jpg'),
            cdn('images/introduce/2/5.jpg')
        ]
    },
    {
        name: '@UG你大哥',
        id: 14,
        tags: ['整容级美妆大佬', '学生党宠爱的大眼彩妆博主'],
        introduction: '粉丝眼中的“整容级美妆大佬”，风格百变，妆前妆后差异大，爆款频出，产出过互动量32w+的爆款学生妆容，品牌种草力max',
        hotSpots: [
            '以优质的妆容内容40天内涨粉15w+',
            '互动数据活跃，单条小红书笔记平均阅读量15w+、互动量5000+',
            '曾获得过小红书官方时尚C计划排行榜TOP1'
        ],
        images: [
            cdn('images/introduce/14/1.jpg'),
            cdn('images/introduce/14/2.jpg'),
            cdn('images/introduce/14/3.jpg'),
            cdn('images/introduce/14/4.jpg'),
            cdn('images/introduce/14/5.jpg')
        ]
    },
    {
        name: '@MC萱萱RubyLi',
        id: 47,
        tags: ['海归双语主持人', '时尚优雅高知博主'],
        introduction: "她具备极强时尚明锐度,善于从主持人的专业经历出发，分享变美干货、气质提升、品质生活、Girl's power;带货种草能力强，小红书首次直播GMV破30w，ROI最高达6",
        hotSpots: [
            '女性粉丝占比超95%，Z世代粉丝占比超55%',
            '作为主持受邀参与时尚COSMO  、LANEIGE X LadyS Elite 、Montblanc 、Sisley线下活动',
            '受邀参与伦敦时装周 、米兰时装周 、上海时装周、Super ELLE时尚盛典',
            '小红书爆款笔记 #眼神管理# 单篇视频播放量194w+ ,互动量43w+'
        ],
        images: [
            cdn('images/introduce/47/1.jpg'),
            cdn('images/introduce/47/2.jpg'),
            cdn('images/introduce/47/3.jpg'),
            cdn('images/introduce/47/4.jpg'),
            cdn('images/introduce/47/5.jpg')
        ]
    },
    {
        name: '@子皿耳东',
        id: null,
        tags: ['被美妆耽误的相声博主'],
        introduction: '思路型变美小老师,人间开心果，国家一级笑声歌唱家hāháhähà～～',
        hotSpots: [
            '曾与欧莱雅、DW、完美日记、小奥汀等品牌合作；并多次与众国货彩妆护肤品牌合作',
            '抖音广告爆款视频 #素颜神器# 121.4w播放、7.5w赞'
        ],
        images: [
            cdn('images/introduce/子皿耳东/1.jpg'),
            cdn('images/introduce/子皿耳东/2.jpg'),
            cdn('images/introduce/子皿耳东/3.jpg'),
            cdn('images/introduce/子皿耳东/4.jpg'),
            cdn('images/introduce/子皿耳东/5.jpg')
        ]
    },
    {
        name: '@祝安AN',
        id: null,
        tags: ['@@被美妆耽误的相声博主'],
        introduction: '@@思路型变美小老师,人间开心果，国家一级笑声歌唱家hāháhähà～～',
        hotSpots: [
            '@@曾与欧莱雅、DW、完美日记、小奥汀等品牌合作；并多次与众国货彩妆护肤品牌合作',
            '@@抖音广告爆款视频#素颜神器#121.4w播放、7.5w赞'
        ],
        images: [
            cdn('images/introduce/祝安AN/1.jpg'),
            cdn('images/introduce/祝安AN/2.jpg'),
            cdn('images/introduce/祝安AN/3.jpg'),
            cdn('images/introduce/祝安AN/4.jpg'),
            cdn('images/introduce/祝安AN/5.jpg')
        ]
    },
    {
        name: '@舟好甜',
        id: 37,
        tags: ['行走的小个子穿搭种草机', '氧气感四川女孩儿', '川式搞笑情侣CP'],
        introduction: '偶尔拍拍美妆护肤vlog的小个子穿搭博主;有可爱有趣的情侣宠物日常，超多生活好物实测时分享；粉丝眼中超亲切的种草小达人;',
        hotSpots: [
            '各平台女性粉丝比例95%以上,超高粉丝粘性',
            '曾与LA MER、雅诗兰黛、兰蔻、HR、纪梵希、科颜氏等品牌合作',
            '也多次与太平鸟、FILA、特步、安踏、斯凯奇等服饰品牌合作'
        ],
        images: [
            cdn('images/introduce/37/1.jpg'),
            cdn('images/introduce/37/2.jpg'),
            cdn('images/introduce/37/3.jpg'),
            cdn('images/introduce/37/4.jpg'),
            cdn('images/introduce/37/5.jpg')
        ]
    },
    {
        name: '@只小萌',
        id: 18,
        tags: ['韩系通勤风穿搭博主', '对镜拍教科书', '内容超下饭的生活Vlogger'],
        introduction: '她是温柔甜美的韩系穿搭博主、带货达人；她是热爱生活、乐于分享，粉丝心中的邻家姐姐',
        hotSpots: [
            '曾获2019新浪十大新锐红人、微博人气时尚博主荣誉',
            '曾于LA MER、雅诗兰黛、资生堂、植村秀、罗意威、华为等品牌合作'
        ],
        images: [
            cdn('images/introduce/18/1.jpg'),
            cdn('images/introduce/18/2.jpg'),
            cdn('images/introduce/18/3.jpg'),
            cdn('images/introduce/18/4.jpg'),
            cdn('images/introduce/18/5.jpg')
        ]
    },
    {
        name: '@章馨心Erin',
        id: 88,
        tags: ['服装设计专业的清冷调时尚达人', '双胞胎博主'],
        introduction: '@@她是温柔甜美的韩系穿搭博主、带货达人；她是热爱生活、乐于分享，粉丝心中的邻家姐姐',
        hotSpots: ['女性粉丝占比70% ,90后消费主力军占比90%', '曾于兰蔻、祖玛珑、心相印等品牌合作'],
        images: [
            cdn('images/introduce/88/1.jpg'),
            cdn('images/introduce/88/2.jpg'),
            cdn('images/introduce/88/3.jpg'),
            cdn('images/introduce/88/4.jpg'),
            cdn('images/introduce/88/5.jpg')
        ]
    },
    {
        name: '@章馨贝',
        id: 87,
        tags: ['欧美风时尚穿搭达人', '双胞胎博主'],
        introduction: '@@她是温柔甜美的韩系穿搭博主、带货达人；她是热爱生活、乐于分享，粉丝心中的邻家姐姐',
        hotSpots: [
            '女性粉丝占比94% ,90后消费主力军占比63%',
            '曾于太平鸟、FILA、兰蔻、PMPM等多品类品牌合作',
            '参与嘉人杂志X天猫美妆洗护线下活动'
        ],
        images: [
            cdn('images/introduce/87/1.jpg'),
            cdn('images/introduce/87/2.jpg'),
            cdn('images/introduce/87/3.jpg'),
            cdn('images/introduce/87/4.jpg'),
            cdn('images/introduce/87/5.jpg')
        ]
    },
    {
        name: '@张曼慢慢长胖',
        id: 84,
        tags: ['娱乐圈在逃时尚博主'],
        introduction: '她是拥有丰富演艺经历与恋综经历的时尚博主；韩系风格清纯可爱，私下性格活泼有趣，深受粉丝宠爱;中文系文艺学霸一枚，文案强，口播强，视频有梗有趣',
        hotSpots: ['曾于雅诗兰黛、拉面说、王饱饱、NARS品牌合作'],
        images: [
            cdn('images/introduce/84/1.jpg'),
            cdn('images/introduce/84/2.jpg'),
            cdn('images/introduce/84/3.jpg'),
            cdn('images/introduce/84/4.jpg'),
            cdn('images/introduce/84/5.jpg')
        ]
    },
    {
        name: '@张浩昕',
        id: null,
        tags: ['清冷挂的韩系时尚达人', '恋爱综艺《怦然心动20岁》女嘉宾', '徐秉龙新歌《天蝎》MV女主'],
        introduction: '她是韩系清冷挂的氧气美女，甜辣酷飒性感风格随性拿捏！ 高级精致的品宣脸，素颜依旧能打！ 性格开朗有趣的天蝎座，停不下想分享的心！',
        hotSpots: ['合作过香奈儿、赫莲娜、迪奥、祖玛珑、HR赫莲娜、兰蔻等品牌'],
        images: [
            cdn('images/introduce/张浩昕/1.jpg'),
            cdn('images/introduce/张浩昕/2.jpg'),
            cdn('images/introduce/张浩昕/3.jpg'),
            cdn('images/introduce/张浩昕/4.jpg'),
            cdn('images/introduce/张浩昕/5.jpg')
        ]
    },
    {
        name: '@张宝利ciel',
        id: null,
        tags: ['时尚达人'],
        introduction: '@@她是拥有丰富演艺经历与恋综经历的时尚博主;韩系风格清纯可爱，私下性格活泼有趣，深受粉丝宠爱；中文系文艺学霸一枚，文案强，口播强，视频有梗有趣',
        hotSpots: ['@@曾于雅诗兰黛、拉面说、王饱饱、NARS品牌合作'],
        images: [
            cdn('images/introduce/张宝利ciel/1.jpg'),
            cdn('images/introduce/张宝利ciel/2.jpg'),
            cdn('images/introduce/张宝利ciel/3.jpg'),
            cdn('images/introduce/张宝利ciel/4.jpg'),
            cdn('images/introduce/张宝利ciel/5.jpg')
        ]
    },
    {
        name: '@早见遥',
        id: null,
        tags: ['纯欲风时尚达人', '氛围感美女'],
        introduction: '她是法国电影下的文艺少女，钟爱美妆颜值出挑，纯欲从来不是她追求的风格，而是与生俱来的气质，用镜头表达属于自己的故事，用每个内容传递独特审美',
        hotSpots: ['曾于香奈儿、赫莲娜、迪奥、祖玛珑、阿玛尼等一线品牌合作'],
        images: [
            cdn('images/introduce/早见遥/1.jpg'),
            cdn('images/introduce/早见遥/2.jpg'),
            cdn('images/introduce/早见遥/3.jpg'),
            cdn('images/introduce/早见遥/4.jpg'),
            cdn('images/introduce/早见遥/5.jpg')
        ]
    },
    {
        name: '@有竹-',
        id: 51,
        tags: ['高级复古精致脸，高奢宠爱', '精致调性生活，传递独特的生活方式', '新中式风格KOL'],
        introduction: '她热衷营造精致生活氛围，拥有与高冷外表截然不同的超亲切东北口播反差；她喜欢中古，是新中式穿搭小先锋；调性拉满,传递优雅向上的生活价值观',
        hotSpots: [
            '受邀参加LV、华伦天奴、YSL、科颜氏线下活动',
            '曾与YSL、LA MER、雅诗兰黛、红布林、纪梵希、祖玛珑、周大福等品牌合作'
        ],
        images: [
            cdn('images/introduce/51/1.jpg'),
            cdn('images/introduce/51/2.jpg'),
            cdn('images/introduce/51/3.jpg'),
            cdn('images/introduce/51/4.jpg'),
            cdn('images/introduce/51/5.jpg')
        ]
    },
    {
        name: '@有毒的苏小姐',
        id: null,
        tags: ['治愈系独居女孩vlog', '医美属性', '专业护肤经验'],
        introduction: '她是治愈系vlog博主，也是精致生活少女 ',
        hotSpots: ['曾于资生堂、汤臣倍健、良品铺子、农夫山泉等品牌合作'],
        images: [
            cdn('images/introduce/有毒的苏小姐/1.jpg'),
            cdn('images/introduce/有毒的苏小姐/2.jpg'),
            cdn('images/introduce/有毒的苏小姐/3.jpg'),
            cdn('images/introduce/有毒的苏小姐/4.jpg'),
            cdn('images/introduce/有毒的苏小姐/5.jpg')
        ]
    },
    {
        name: '@优里娜',
        id: null,
        tags: ['时尚辣妈', '资深彩妆搭配师', '明星仿妆达人'],
        introduction: '她在国外居住11年，现居上海和新加坡。在最早期的博客时代，优里娜就拥有一批追随者，后来凭借对彩妆的独到理解，流利 的英文、甜美的长相，优秀的品格被某法国奢侈品品牌聘用，成为该品牌公关。转型后创业，在上海成立自己的自媒体工作室； 她对产品推广的程式、消费者导流的方式、达人与品牌合作的模式有专业的认知，外加形象与气质 受到众多国际品牌的邀约。作为一个时尚的辣妈，优里娜对于孩子和家庭也是格外的注重，日常内容也包含儿童穿搭、情感生活分享等趣味内容。',
        hotSpots: [
            '曾担任OLAY微代言',
            '与欧珀莱 合作的“时光锁联名礼盒”,天猫旗舰店双11卖出6.8w+套',
            '曾受邀参加资生堂、兰蔻、兰芝等品牌线下活动'
        ],
        images: [
            cdn('images/introduce/优里娜/1.jpg'),
            cdn('images/introduce/优里娜/2.jpg'),
            cdn('images/introduce/优里娜/3.jpg'),
            cdn('images/introduce/优里娜/4.jpg'),
            cdn('images/introduce/优里娜/5.jpg')
        ]
    },
    {
        name: '@一只梁不了',
        id: 65,
        tags: ['B站美妆生活UP主', '清冷优雅精致生活的vlogger', '医美顾问属性'],
        introduction: '她是时尚精致生活的vlog创作者，气质清冷优雅，调性高级；她也是招猫逗狗的铲屎官，家有三只猫咪一只狗狗，可爱可配合出境；她的美妆护肤技术一流，熟悉医美护肤知识，有过医美顾问相关经验',
        hotSpots: ['曾于LA MER、雅诗兰黛、兰蔻、MAC、欧莱雅等品牌合作', '曾受邀参加Dior新品发布会'],
        images: [
            cdn('images/introduce/65/1.jpg'),
            cdn('images/introduce/65/2.jpg'),
            cdn('images/introduce/65/3.jpg'),
            cdn('images/introduce/65/4.jpg'),
            cdn('images/introduce/65/5.jpg')
        ]
    },
    {
        name: '@一只淡淡',
        id: null,
        tags: ['学生党寝室生活vlogger'],
        introduction: '@@是时尚精致生活的vlog创作者，气质清冷优雅，调性高级；她也是招猫逗狗的铲屎官，家有三只猫咪一只狗狗，可爱可配合出境；她的美妆护肤技术一流，熟悉医美护肤知识，有过医美顾问相关经验',
        hotSpots: ['@@曾于LA MER、雅诗兰黛、兰蔻、MAC、欧莱雅等品牌合作', '曾受邀参加Dior新品发布会'],
        images: [
            cdn('images/introduce/一只淡淡/1.jpg'),
            cdn('images/introduce/一只淡淡/2.jpg'),
            cdn('images/introduce/一只淡淡/3.jpg'),
            cdn('images/introduce/一只淡淡/4.jpg'),
            cdn('images/introduce/一只淡淡/5.jpg')
        ]
    },
    {
        name: '@一鮮Poppy/Po痞',
        id: 86,
        tags: ['韩系风时尚辣妈', '精致生活博主'],
        introduction: '她是百变造型的时尚博主，也是气质清冷的美女辣妈，长相高级调性高级，记录一家三口辛福生活的vlog博主，日常内容包含时尚穿搭、护肤美妆、亲子互动、情侣日常等多方面的丰富内容',
        hotSpots: [
            '女性粉丝占例91%,Z时代占比超91%',
            '曾于美赞成、兰蔻、欧莱雅等品牌合作',
            '受邀参加解禁TOMFORD世界&天猫超级品牌日'
        ],
        images: [
            cdn('images/introduce/86/1.jpg'),
            cdn('images/introduce/86/2.jpg'),
            cdn('images/introduce/86/3.jpg'),
            cdn('images/introduce/86/4.jpg'),
            cdn('images/introduce/86/5.jpg')
        ]
    },
    {
        name: '@一雯白',
        id: null,
        tags: ['甜辣风时尚穿搭达人', '情侣CP'],
        introduction: '她是甜酷性感高颜值美女；抖音“出圈”百万情侣博主,情头制造机；拥有令人羡慕的神仙姐弟恋',
        hotSpots: ['曾于YSL、雅诗兰黛、联合利华等品牌合作'],
        images: [
            cdn('images/introduce/一雯白/1.jpg'),
            cdn('images/introduce/一雯白/2.jpg'),
            cdn('images/introduce/一雯白/3.jpg'),
            cdn('images/introduce/一雯白/4.jpg'),
            cdn('images/introduce/一雯白/5.jpg')
        ]
    },
    {
        name: '@一颗七崽',
        id: 72,
        tags: ['日韩风时尚穿搭彩妆分享红人'],
        introduction: '她是零距离感的邻家元气少女，拥有敏锐的时尚嗅觉和天生的镜头感，高甜情侣日常中可爱大条的反差性格，圈粉无数',
        hotSpots: ['曾于香奈儿、雅诗兰黛、PUMA、阿玛尼、资生堂、HR等一线大牌合作'],
        images: [
            cdn('images/introduce/72/1.jpg'),
            cdn('images/introduce/72/2.jpg'),
            cdn('images/introduce/72/3.jpg'),
            cdn('images/introduce/72/4.jpg'),
            cdn('images/introduce/72/5.jpg')
        ]
    },
    {
        name: '@一颗鸡蛋',
        id: null,
        tags: ['可盐可甜美妆达人'],
        introduction: '她是可盐可甜的00后妹妹；她是擅长反转风格的圆脸种草机；她是满级编发小能手；她是上镜有料下播认真生活的播音学姐',
        hotSpots: ['曾与娇韵诗、欧莱雅、科颜氏、悦木之源、OLAY等多元品牌合作'],
        images: [
            cdn('images/introduce/一颗鸡蛋/1.jpg'),
            cdn('images/introduce/一颗鸡蛋/2.jpg'),
            cdn('images/introduce/一颗鸡蛋/3.jpg'),
            cdn('images/introduce/一颗鸡蛋/4.jpg'),
            cdn('images/introduce/一颗鸡蛋/5.jpg')
        ]
    },
    {
        name: '@叶它它',
        id: null,
        tags: ['日系休闲鬼马少女'],
        introduction: '乐天派可爱圆脸鬼马少女，表现力满分日系风格爱好者，张力十足拍照姿势鬼才美妆达人，优质审美的时尚先行者',
        hotSpots: ['曾于LA MER、兰蔻、欧莱雅、资生堂、苏菲、李宁、New Balance 等多元品牌合作'],
        images: [
            cdn('images/introduce/叶它它/1.jpg'),
            cdn('images/introduce/叶它它/2.jpg'),
            cdn('images/introduce/叶它它/3.jpg'),
            cdn('images/introduce/叶它它/4.jpg'),
            cdn('images/introduce/叶它它/5.jpg')
        ]
    },
    {
        name: '@杨霞',
        id: 19,
        tags: ['时尚辣妈', '美妆博主', '健身达人'],
        introduction: '她是爆款频出的美妆护肤达人，也是热爱生活的时尚二胎辣妈，爱健身爱跳舞，深受粉丝喜爱，多领域发展；常年做客品牌直播间，和众多一线等各大品牌合作，参加品牌各种线下活动，拍摄多个TVC广告全平台播放，带货能力超强',
        hotSpots: [
            '推爆国货品牌唯戈自动卷发棒，拜耳维生素单条视频推广致品牌爆仓',
            'notime美容仪单条视频销售额96万，roi2:3，后续推出联名款等合作',
            '穴位瘦身视频微博榜单第二，拥有数个热搜话题等'
        ],
        images: [
            cdn('images/introduce/19/1.jpg'),
            cdn('images/introduce/19/2.jpg'),
            cdn('images/introduce/19/3.jpg'),
            cdn('images/introduce/19/4.jpg'),
            cdn('images/introduce/19/5.jpg')
        ]
    },
    {
        name: '@杨十一',
        id: 67,
        tags: ['高级感轻法式时尚博主'],
        introduction: '拥有治愈笑容的法式优雅少女，自带种草力的高级品宣脸，用独特的品味分享精致生活，传达不跟随的时尚态度',
        hotSpots: ['曾于兰蔻、Dior、HR、纪梵希、戴森、潘婷、马歇尔等多元品牌合作'],
        images: [
            cdn('images/introduce/67/1.jpg'),
            cdn('images/introduce/67/2.jpg'),
            // cdn('images/introduce/67/3.jpg'),
            cdn('images/introduce/67/4.jpg'),
            cdn('images/introduce/67/5.jpg')
        ]
    },
    {
        name: '@杨九斤-',
        id: null,
        tags: ['微胖时尚穿搭达人'],
        introduction: '@@拥有治愈笑容的法式优雅少女，自带种草力的高级品宣脸，用独特的品味分享精致生活，传达不跟随的时尚态度',
        hotSpots: ['@@曾于兰蔻、Dior、HR、纪梵希、戴森、潘婷、马歇尔等多元品牌合作'],
        images: [
            cdn('images/introduce/杨九斤-/1.jpg'),
            cdn('images/introduce/杨九斤-/2.jpg'),
            cdn('images/introduce/杨九斤-/3.jpg'),
            cdn('images/introduce/杨九斤-/4.jpg'),
            cdn('images/introduce/杨九斤-/5.jpg')
        ]
    },
    {
        name: '@羊呢桃tt',
        id: null,
        tags: ['韩系穿搭辣妹'],
        introduction: '她是可盐可甜时尚达人，人形衣架纯欲教材 她是超高颜值韩系辣妹，美妆生活人形种草',
        hotSpots: ['曾于兰蔻、纪梵希、YSL、芭比布朗、祖玛珑等品牌合作'],
        images: [
            cdn('images/introduce/羊呢桃tt/1.jpg'),
            cdn('images/introduce/羊呢桃tt/2.jpg'),
            cdn('images/introduce/羊呢桃tt/3.jpg'),
            cdn('images/introduce/羊呢桃tt/4.jpg'),
            cdn('images/introduce/羊呢桃tt/5.jpg')
        ]
    },
    {
        name: '@羊咩咩和丫吖吖',
        id: null,
        tags: ['生活情侣博主', '海外留学属性'],
        introduction: '@@她是可盐可甜时尚达人，人形衣架纯欲教材 她是超高颜值韩系辣妹，美妆生活人形种草',
        hotSpots: ['@@曾于兰蔻、纪梵希、YSL、芭比布朗、祖玛珑等品牌合作'],
        images: [
            cdn('images/introduce/羊咩咩和丫吖吖/1.jpg'),
            cdn('images/introduce/羊咩咩和丫吖吖/2.jpg'),
            cdn('images/introduce/羊咩咩和丫吖吖/3.jpg'),
            cdn('images/introduce/羊咩咩和丫吖吖/4.jpg'),
            cdn('images/introduce/羊咩咩和丫吖吖/5.jpg')
        ]
    },
    {
        name: '@羊大锅锅',
        id: 62,
        tags: ['日系中性风穿搭博主', '少年感少女感并存', '艺术生插画师'],
        introduction: '她具有日系少年感，钟爱小个子无性别穿搭；她眉眼神似鹿晗、张子枫，颜值出挑拥有大波忠实颜粉；她拥有艺术生插画师身份，用镜头记录世间的美丽和美好',
        hotSpots: [
            '各平台女性粉丝占比90%，超高粉丝粘性',
            ' 曾于luna 、太平鸟、兰蔻、悦木之源品牌合作',
            '风格多样，多条穿搭爆款案例',
            '“发型”、“好物”类主题点赞均1000+，具有强种草力'
        ],
        images: [
            cdn('images/introduce/62/1.jpg'),
            cdn('images/introduce/62/2.jpg'),
            cdn('images/introduce/62/3.jpg'),
            cdn('images/introduce/62/4.jpg'),
            cdn('images/introduce/62/5.jpg')
        ]
    },
    {
        name: '@芯芯还没睡',
        id: null,
        tags: ['直爽东北美女', '纯欲风美妆博主'],
        introduction: '纯正东北血统，纯欲妆容拿捏冠军，美瞳专业户',
        hotSpots: ['曾与雅诗兰黛，MAC，索尼等品牌合作', '小红书爆款：#angelababy仿妆# 2.8w赞、1.4w收藏'],
        images: [
            cdn('images/introduce/芯芯还没睡/1.jpg'),
            cdn('images/introduce/芯芯还没睡/2.jpg'),
            cdn('images/introduce/芯芯还没睡/3.jpg'),
            cdn('images/introduce/芯芯还没睡/4.jpg'),
            cdn('images/introduce/芯芯还没睡/5.jpg')
        ]
    },
    {
        name: '@小越小越-',
        id: 35,
        tags: ['甜酷美风精致穿搭向导'],
        introduction: '她是可甜可酷的风格少女，各种风格穿搭都能hold住她是精致高级的分享女孩，彩妆护肤生活好物通通种草清冷外形和治愈笑容的碰撞，形成独一无二的“越”式风格',
        hotSpots: ['曾于LA MER、LOEW、祖玛珑、科颜氏、New Balance等多元品牌合作'],
        images: [
            cdn('images/introduce/35/1.jpg'),
            cdn('images/introduce/35/2.jpg'),
            cdn('images/introduce/35/3.jpg'),
            cdn('images/introduce/35/4.jpg'),
            cdn('images/introduce/35/5.jpg')
        ]
    },
    {
        name: '@小随崽汁',
        id: null,
        tags: ['学生党青春活力女孩', '韩系穿搭博主', '韩国留学生'],
        introduction: '她是亲和的邻家女孩，热爱分享生活好物，穿搭美妆两手抓，韩系学姐深受学生党喜爱',
        hotSpots: [
            '小红书女性粉丝占比98.48%，深受学生党喜爱',
            '曾与娇韵诗、科颜氏、DIOR、欧莱雅等一线品牌合作',
            '更多次与珀莱雅、谷雨、PMPM、彩棠等优秀国货品牌合作'
        ],
        images: [
            cdn('images/introduce/小随崽汁/1.jpg'),
            cdn('images/introduce/小随崽汁/2.jpg'),
            cdn('images/introduce/小随崽汁/3.jpg'),
            cdn('images/introduce/小随崽汁/4.jpg'),
            cdn('images/introduce/小随崽汁/5.jpg')
        ]
    },
    {
        name: '@小马晕晕yunn',
        id: 48,
        tags: ['韩风时尚分享红人', '恋爱CP属性'],
        introduction: '她是甜美治愈系，热爱穿搭她是P图小能手，钟爱分享生活她是服装设计专业，用专业表达自己独有的穿搭灵感',
        hotSpots: ['曾于Blank me、资生堂等品牌合作'],
        images: [
            cdn('images/introduce/48/1.jpg'),
            cdn('images/introduce/48/2.jpg'),
            cdn('images/introduce/48/3.jpg'),
            cdn('images/introduce/48/4.jpg'),
            cdn('images/introduce/48/5.jpg')
        ]
    },
    {
        name: '@小李朝ye',
        id: 7,
        tags: ['精分搞笑达人'],
        introduction: '他是搞笑戏精，画风有趣、金句频频、记忆点超强；他以剧情化的内容进行好物分享，种草满满；同时他也是直播达人，多场直播，带货实力在线',
        hotSpots: [
            '#精分搞笑小剧场#平均曝光量1200w+',
            '小李朝 X colorkey专场直播GMV 破110w+',
            '小李朝 X 完美日记专场直播GMV破100w+',
            '2021多次登上抖音热榜top1',
            '2019-2020克劳锐最具影响力幽默搞笑自媒体'
        ],
        images: [
            cdn('images/introduce/7/1.jpg'),
            cdn('images/introduce/7/2.jpg'),
            cdn('images/introduce/7/3.jpg'),
            cdn('images/introduce/7/4.jpg'),
            cdn('images/introduce/7/5.jpg')
        ]
    },
    {
        name: '@小今花菜',
        id: null,
        tags: ['ins风韩系girl', '美术生属性审美在线'],
        introduction: '性格开朗、幽默逗比的北方girl！很能跟粉丝打成一片，网感好，出图氛围感满满，韩系穿搭/单品种草/化妆视频都很出色！',
        hotSpots: ['合作品牌祖玛珑、MAC、兰蔻、迪奥、gentle monster、安视优美瞳等'],
        images: [
            cdn('images/introduce/小今花菜/1.jpg'),
            cdn('images/introduce/小今花菜/2.jpg'),
            cdn('images/introduce/小今花菜/3.jpg'),
            cdn('images/introduce/小今花菜/4.jpg'),
            cdn('images/introduce/小今花菜/5.jpg')
        ]
    },
    {
        name: '@咸咸子',
        id: null,
        tags: [
            '北京服装学院服装艺术设计专业',
            '非典型性欧美亚比风审美达人',
            '头部MCN美妆组长',
            '穿搭美妆多赛道发展',
            '潮流&音乐的拽系品味分享'
        ],
        introduction: '她是独具非典型性欧美亚比风的审美达人；她是曾经的头部MCN美妆组长；她是认真做内容的辣妹系博主；她是独具特色的未来服装主理人',
        hotSpots: ['合作品牌olay、兰蔻、雅诗兰黛、FLIA'],
        images: [
            cdn('images/introduce/咸咸子/1.jpg'),
            cdn('images/introduce/咸咸子/2.jpg'),
            cdn('images/introduce/咸咸子/3.jpg'),
            cdn('images/introduce/咸咸子/4.jpg'),
            cdn('images/introduce/咸咸子/5.jpg')
        ]
    },
    {
        name: '@喜悦姑娘Clara',
        id: 46,
        tags: ['lifestyle blogger', '精致高调性时尚穿搭博主'],
        introduction: '@@她是独具非典型性欧美亚比风的审美达人；她是曾经的头部MCN美妆组长；她是认真做内容的辣妹系博主；她是独具特色的未来服装主理人',
        hotSpots: ['曾于Dior、兰蔻、Tom Form、雅诗兰黛、SKii等品牌合作'],
        images: [
            cdn('images/introduce/46/1.jpg'),
            cdn('images/introduce/46/2.jpg'),
            cdn('images/introduce/46/3.jpg'),
            cdn('images/introduce/46/4.jpg'),
            cdn('images/introduce/46/5.jpg')
        ]
    },
    {
        name: '@喜大牙',
        id: 57,
        tags: ['149cm小个子日系鬼马可爱少女'],
        introduction: '她是鬼马精灵的日系小个子少女，热爱记录分享穿搭和生活',
        hotSpots: ['曾于HR、雅诗兰黛、施华蔻、天猫国际等多元品牌合作'],
        images: [
            cdn('images/introduce/57/1.jpg'),
            cdn('images/introduce/57/2.jpg'),
            cdn('images/introduce/57/3.jpg'),
            cdn('images/introduce/57/4.jpg'),
            cdn('images/introduce/57/5.jpg')
        ]
    },
    {
        name: '@五十青',
        id: 81,
        tags: ['韩风浓颜系', '时尚好物安利', '生活vlog分享'],
        introduction: '她是有6年拍摄经验的资深模特，擅长挖掘专业知识，可塑性很强；她是独具特色的个性少女，热爱记录分享生活',
        hotSpots: ['曾于LA MER、纪梵希、雅诗兰黛、兰蔻、花西子等多元品牌合作'],
        images: [
            cdn('images/introduce/81/1.jpg'),
            cdn('images/introduce/81/2.jpg'),
            cdn('images/introduce/81/3.jpg'),
            cdn('images/introduce/81/4.jpg'),
            cdn('images/introduce/81/5.jpg')
        ]
    },
    {
        name: '@五人病',
        id: null,
        tags: ['小众亚文化独特风格美女', '国美高材生'],
        introduction: '@@她是有6年拍摄经验的资深模特，擅长挖掘专业知识，可塑性很强；她是独具特色的个性少女，热爱记录分享生活',
        hotSpots: ['@@曾于LA MER、纪梵希、雅诗兰黛、兰蔻、花西子等多元品牌合作'],
        images: [
            cdn('images/introduce/五人病/1.jpg'),
            cdn('images/introduce/五人病/2.jpg'),
            cdn('images/introduce/五人病/3.jpg'),
            cdn('images/introduce/五人病/4.jpg'),
            cdn('images/introduce/五人病/5.jpg')
        ]
    },
    {
        name: '@温婉Wenwan_',
        id: 17,
        tags: ['带货女王', '停车场女孩'],
        introduction: '她是穿搭ICON，以独树一帜的审美引领粉丝；她也是精致美妆博主，颜值加持，美妆、护肤两不误',
        hotSpots: [
            '连续3年获得微博2018 / 2019 / 2020十大影响力颜值博主',
            '微博红人节 - 百大视频号，2020年微博百大视频号',
            '快手完美日记直播专场GMV100w+，微博HFP单条定制视频带货50w+，ulike脱毛仪微博带货30w+，迪雅思联名套刷累计带货100W+'
        ],
        images: [
            cdn('images/introduce/17/1.jpg'),
            cdn('images/introduce/17/2.jpg'),
            cdn('images/introduce/17/3.jpg'),
            cdn('images/introduce/17/4.jpg'),
            cdn('images/introduce/17/5.jpg')
        ]
    },
    {
        name: '@味莫斯Aretha',
        id: null,
        tags: ['仿妆技术流', '又野又潮手超稳的二次元美妆博主'],
        introduction: '她是技术流仿妆达人，视频制作精良、超高观赏性；她也是特效妆达人，完美还原，经典电影角色视效',
        hotSpots: ['曾合作完美日记、花西子、自然之名等多个品牌'],
        images: [
            cdn('images/introduce/味莫斯Aretha/1.jpg'),
            cdn('images/introduce/味莫斯Aretha/2.jpg'),
            cdn('images/introduce/味莫斯Aretha/3.jpg'),
            cdn('images/introduce/味莫斯Aretha/4.jpg'),
            cdn('images/introduce/味莫斯Aretha/5.jpg')
        ]
    },
    {
        name: '@王少女YOKI',
        id: null,
        tags: ['爱穿搭爱分享有趣的时尚红人'],
        introduction: '她是时髦又独特的穿搭博主；她是有趣又搞怪的分享达人；她是古灵精怪的种草带货达人；她也是被服装事业耽误的鬼马少女',
        hotSpots: ['曾合作Blank me、Olay、丝芙兰、珀莱雅、HFP等多个品牌'],
        images: [
            cdn('images/introduce/王少女YOKI/1.jpg'),
            cdn('images/introduce/王少女YOKI/2.jpg'),
            cdn('images/introduce/王少女YOKI/3.jpg'),
            cdn('images/introduce/王少女YOKI/4.jpg'),
            cdn('images/introduce/王少女YOKI/5.jpg')
        ]
    },
    {
        name: '@王Sir',
        id: null,
        tags: ['男性美妆博主'],
        introduction: '@@她是时髦又独特的穿搭博主；她是有趣又搞怪的分享达人；她是古灵精怪的种草带货达人；她也是被服装事业耽误的鬼马少女',
        hotSpots: ['@@曾合作Blank me、Olay、丝芙兰、珀莱雅、HFP等多个品牌'],
        images: [
            cdn('images/introduce/王Sir/1.jpg'),
            cdn('images/introduce/王Sir/2.jpg'),
            cdn('images/introduce/王Sir/3.jpg'),
            cdn('images/introduce/王Sir/4.jpg'),
            cdn('images/introduce/王Sir/5.jpg')
        ]
    },
    {
        name: '@顽童大人',
        id: 23,
        tags: ['时尚带货小能手', '全年销售破亿女装店主', '2019微博新锐红人'],
        introduction: '她是会穿会买会搭配的时髦贵婆；她热爱生活；记录美好的视频达人；她是全年销售破亿的女装店主',
        hotSpots: [
            '曾合作LA MER、雪花秀、欧莱雅、珀莱雅、戴森等多个品牌',
            '微博爆款案例 #浴室大公开# 播放量26w+，互动量3k+，直播转化100台+，GMV超30w+'
        ],
        images: [
            cdn('images/introduce/23/1.jpg'),
            cdn('images/introduce/23/2.jpg'),
            cdn('images/introduce/23/3.jpg'),
            cdn('images/introduce/23/4.jpg'),
            cdn('images/introduce/23/5.jpg')
        ]
    },
    {
        name: '@脱脂Nofat',
        id: null,
        tags: ['韩系小个子美少女', '5年模特经验'],
        introduction: '@@她是会穿会买会搭配的时髦贵婆；她热爱生活；记录美好的视频达人；她是全年销售破亿的女装店主',
        hotSpots: [
            '@@曾合作LA MER、雪花秀、欧莱雅、珀莱雅、戴森等多个品牌',
            '@@微博爆款案例 #浴室大公开# 播放量26w+，互动量3k+，直播转化100台+，GMV超30w+'
        ],
        images: [
            cdn('images/introduce/脱脂Nofat/1.jpg'),
            cdn('images/introduce/脱脂Nofat/2.jpg'),
            cdn('images/introduce/脱脂Nofat/3.jpg'),
            cdn('images/introduce/脱脂Nofat/4.jpg'),
            cdn('images/introduce/脱脂Nofat/5.jpg')
        ]
    },
    {
        name: '@囤囤er',
        id: null,
        tags: ['穿搭博主'],
        introduction: '@@她是会穿会买会搭配的时髦贵婆；她热爱生活；记录美好的视频达人；她是全年销售破亿的女装店主',
        hotSpots: [
            '@@曾合作LA MER、雪花秀、欧莱雅、珀莱雅、戴森等多个品牌',
            '@@微博爆款案例 #浴室大公开# 播放量26w+，互动量3k+，直播转化100台+，GMV超30w+'
        ],
        images: [
            cdn('images/introduce/囤囤er/1.jpg'),
            cdn('images/introduce/囤囤er/2.jpg'),
            cdn('images/introduce/囤囤er/3.jpg'),
            cdn('images/introduce/囤囤er/4.jpg'),
            cdn('images/introduce/囤囤er/5.jpg')
        ]
    },
    {
        name: '@团圆妈妈',
        id: null,
        tags: ['金融MBA', '二胎妈妈'],
        introduction: '她知性有学识，高挑且优雅；她三观五官正，高情商和高颜值并存；她是持续输出独立女性和职场干货内容的博主，也是充满正能量的二胎宝妈',
        hotSpots: ['合作过LA MER、OLAY、兰蔻，欧莱雅、娇韵诗、雅诗兰黛、CHANEL、戴森、赫莲娜、CPB等大牌'],
        images: [
            cdn('images/introduce/团圆妈妈/1.jpg'),
            cdn('images/introduce/团圆妈妈/2.jpg'),
            cdn('images/introduce/团圆妈妈/3.jpg'),
            cdn('images/introduce/团圆妈妈/4.jpg'),
            cdn('images/introduce/团圆妈妈/5.jpg')
        ]
    },
    {
        name: '@同学肖美丽',
        id: null,
        tags: ['时尚达人'],
        introduction: '@@她知性有学识，高挑且优雅；她三观五官正，高情商和高颜值并存；她是持续输出独立女性和职场干货内容的博主，也是充满正能量的二胎宝妈',
        hotSpots: ['@@合作过LA MER、OLAY、兰蔻，欧莱雅、娇韵诗、雅诗兰黛、CHANEL、戴森、赫莲娜、CPB等大牌'],
        images: [
            cdn('images/introduce/同学肖美丽/1.jpg'),
            cdn('images/introduce/同学肖美丽/2.jpg'),
            cdn('images/introduce/同学肖美丽/3.jpg'),
            cdn('images/introduce/同学肖美丽/4.jpg'),
            cdn('images/introduce/同学肖美丽/5.jpg')
        ]
    },
    {
        name: '@铁一WENDYZ',
        id: 82,
        tags: ['可盐可甜的美妆技术流', '氛围感清冷美女', '韩国留学时尚达人'],
        introduction: '她是百变风格的甜盐系少女；她是穿搭风格个性鲜明，引粉丝争相模仿的；穿搭达人；她是美妆护肤心得的分享者',
        hotSpots: [
            '曾合作雅诗兰黛、欧莱雅、资生堂、完美日记、UNNY等多个品牌',
            '受邀参与闻得到的帕尔玛限时展览、科颜氏新品发布会、光感世界光影派对纪梵希活动',
            '微博爆款案例 #春晚穿搭，复古气质# 阅读量545w+，互动量3.5k+'
        ],
        images: [
            cdn('images/introduce/82/1.jpg'),
            cdn('images/introduce/82/2.jpg'),
            cdn('images/introduce/82/3.jpg'),
            cdn('images/introduce/82/4.jpg'),
            cdn('images/introduce/82/5.jpg')
        ]
    },
    {
        name: '@甜辣酱Sweetchill',
        id: 92,
        tags: ['圆脸微胖博主', '实用分享UP主'],
        introduction: '让女生获得成长！双语主持人；墨尔本大学硕士，深圳大学播音与主持本科。曾工作于深圳电台，采访过陶晓清、齐豫、陈绮贞等众多知名人士。大家的情感知心姐姐！圆脸女孩的导师',
        hotSpots: [
            '10月赴京参与《奇葩说》录制',
            '曾与墨尔本多家剧院及国际⽂化艺术节合作，北京卫视《我是演说家》深圳赛区直接晋级选手，樊登读书“我是讲书人”墨尔本赛区季军'
        ],
        images: [
            cdn('images/introduce/92/1.jpg'),
            cdn('images/introduce/92/2.jpg'),
            cdn('images/introduce/92/3.jpg'),
            cdn('images/introduce/92/4.jpg'),
            cdn('images/introduce/92/5.jpg')
        ]
    },
    /*{
        name: '@瘦瘦璐-',
        id: 55,
        tags: ['清冷氛围感美女', '时尚穿搭博主'],
        introduction: '她是高级精致的氛围感美人，钟爱时尚颜值出挑；性格外冷内热，艺术生出身的她审美品味超高',
        hotSpots: [
            '曾合作FENDI、雅诗兰黛、科颜氏、兰蔻、Blank Me等多个品牌',
            '微博优质案例 #秋冬毛衣合集# 阅读量44w+，互动量4k+'
        ],
        images: [
            cdn('images/introduce/55/1.jpg'),
            cdn('images/introduce/55/2.jpg'),
            cdn('images/introduce/55/3.jpg'),
            cdn('images/introduce/55/4.jpg'),
            cdn('images/introduce/55/5.jpg')
        ]
    },*/
    {
        name: '@是妖妖阿',
        id: 76,
        tags: ['精分鬼马美妆博主', '美妆护肤干货分享达人'],
        introduction: '她是有着名品颜值的鬼马精分美妆博主；她是美妆护肤干货分享型达人',
        hotSpots: [
            '曾合作雅诗兰黛、卡姿兰、欧莱雅、美宝莲、京东、元气森林等多个品牌',
            '微博优质案例 #好物分享—年度平价爱用物合集# 阅读量973w+，互动量1w+'
        ],
        images: [
            cdn('images/introduce/76/1.jpg'),
            cdn('images/introduce/76/2.jpg'),
            cdn('images/introduce/76/3.jpg'),
            cdn('images/introduce/76/4.jpg'),
            cdn('images/introduce/76/5.jpg')
        ]
    },
    {
        name: '@是小柑橘子',
        id: null,
        tags: ['亲和力十足的乖巧学生党博主', '精致穿搭向导'],
        introduction: '她是大二在读学生党，亲和力十足；她是甜美；可爱的美少女博主，深受z世代粉丝宠爱',
        hotSpots: [
            '曾合作兰芝、UR、PMPM、珀莱雅、海马体、Usmile等多个品牌',
            '抖音爆款视频 #舞蹈视频# 阅读量1086.4w+，互动量58w+'
        ],
        images: [
            cdn('images/introduce/是小柑橘子/1.jpg'),
            cdn('images/introduce/是小柑橘子/2.jpg'),
            cdn('images/introduce/是小柑橘子/3.jpg'),
            cdn('images/introduce/是小柑橘子/4.jpg'),
            cdn('images/introduce/是小柑橘子/5.jpg')
        ]
    },
    {
        name: '@是微winwin_',
        id: null,
        tags: ['173甜美穿搭时尚达人', '硬核干货宝藏博主'],
        introduction: '她是无惧身高，173也要甜美穿搭的美少女；她是学生党必看的干货宝藏博主；她做了5年模特，拥有99种原相机拍照教程；她改头换面，n多硬核变美干货分享',
        hotSpots: [
            '曾合作赫莲娜、Dior、娇兰、雅诗兰黛、飞科、唯品会、天猫国际等多个品牌',
            '受邀参与小红书风潮新秀场活动、Dior新品发布会等活动',
            '小红书优质案例 #早春粉色系穿搭|高甜温柔女友风# 播放量24w+，互动量1.8w+'
        ],
        images: [
            cdn('images/introduce/是微winwin_/1.jpg'),
            cdn('images/introduce/是微winwin_/2.jpg'),
            cdn('images/introduce/是微winwin_/3.jpg'),
            cdn('images/introduce/是微winwin_/4.jpg'),
            cdn('images/introduce/是微winwin_/5.jpg')
        ]
    },
    {
        name: '@是南希Nancy_',
        id: 42,
        tags: ['韩系微胖可爱女孩', '笑容治愈'],
        introduction: '她是超会穿的韩系肉肉女孩，有很多点可爱和一点点性感；她超级有感染力的笑颜可以在一瞬间抓住所有人的视线，治愈的同时拥有超乎想象的种草力',
        hotSpots: [
            '曾合作雅诗兰黛、OLAY、美宝莲、ULike、乐町、太平鸟等多个品牌',
            '微博爆款案例 #微胖闺蜜穿搭# 阅读量4475w+，互动量6.8k+'
        ],
        images: [
            cdn('images/introduce/42/1.jpg'),
            cdn('images/introduce/42/2.jpg'),
            cdn('images/introduce/42/3.jpg'),
            cdn('images/introduce/42/4.jpg'),
            cdn('images/introduce/42/5.jpg')
        ]
    },
    {
        name: '@是静静JING_',
        id: 41,
        tags: ['韩系色彩穿搭博主', '微胖界衣架子', '美妆护肤小百科'],
        introduction: '她是拥有超精致五官的140斤女孩；她是种草力超强的韩系氛围感美女，她既是微胖版本的衣架子，也是行走的美妆护肤小百科',
        hotSpots: [
            '曾合作珀莱雅、香飘飘、爱茉莉、蒂佳婷、Blank Me等多个品牌',
            '微博爆款案例 #大头女孩帽子分享# 播放量149w+，互动量3k+'
        ],
        images: [
            cdn('images/introduce/41/1.jpg'),
            cdn('images/introduce/41/2.jpg'),
            cdn('images/introduce/41/3.jpg'),
            cdn('images/introduce/41/4.jpg'),
            cdn('images/introduce/41/5.jpg')
        ]
    },
    {
        name: '@是好运鸭',
        id: 89,
        tags: ['表演系生活UP主', '综艺感十足的阳光活力美少女'],
        introduction: '她是鬼马精灵的戏精少女，热爱分享生活；她是青春洋溢的活力少女；她是多才多艺的文艺up主，用相机记录生活，用笑容带来美好',
        hotSpots: [
            '曾合作科颜氏、花西子、雅顿、康师傅、农夫山泉等多个品牌',
            '受邀录制《极限挑战》、参与上海时装周、香奈儿5号香水百年展，登上剧场表演',
            'B站优质案例 #开学时刻记录# 播放量44.6w+，互动量1.6w+'
        ],
        images: [
            cdn('images/introduce/89/1.jpg'),
            cdn('images/introduce/89/2.jpg'),
            cdn('images/introduce/89/3.jpg'),
            cdn('images/introduce/89/4.jpg'),
            cdn('images/introduce/89/5.jpg')
        ]
    },
    {
        name: '@沈大美Shirine',
        id: 54,
        tags: ['趣味生活vlog创作者', 'TVC女主脸博主'],
        introduction: '她是讨喜的温柔姐姐，趣味生活vlog创作者；她是热爱分享生活碎片，热爱演戏，演技在线；她是TVC女主脸，也因亲近自然的形象和休闲的时尚感深得粉丝喜爱',
        hotSpots: [
            '曾合作LA MER、阿玛尼、CHANEL、戴森、华为、雅诗兰黛等多品类品牌',
            '受邀参与 #植村秀×海贼王# 线下活动、解禁TOM FORD世界线下活动、看见LV线下展览等活动',
            '微博爆款案例 #和歌手学习唱歌# 阅读量205w+，互动量3.6k+'
        ],
        images: [
            cdn('images/introduce/54/1.jpg'),
            cdn('images/introduce/54/2.jpg'),
            cdn('images/introduce/54/3.jpg'),
            cdn('images/introduce/54/4.jpg'),
            cdn('images/introduce/54/5.jpg')
        ]
    },
    {
        name: '@上下名',
        id: 43,
        tags: ['甜酷腿精种草机', '淡颜天花板女孩', '视觉美学高端玩家'],
        introduction: '她是甜美的元气少女；爱分享油痘肌好物的宠粉博主；她是服装设计在读学生党；她是偏爱撞色的穿搭红人',
        hotSpots: [
            '曾合作TOM FORD、Dior、雅诗兰黛、周大福、MLB、太平鸟等多个品牌',
            '微博爆款案例 #时尚穿搭灵感# 阅读量364w+，互动量7k+'
        ],
        images: [
            cdn('images/introduce/43/1.jpg'),
            cdn('images/introduce/43/2.jpg'),
            cdn('images/introduce/43/3.jpg'),
            cdn('images/introduce/43/4.jpg'),
            cdn('images/introduce/43/5.jpg')
        ]
    },
    {
        name: '@山生',
        id: 59,
        tags: ['日系清新氧气小美女', '天生好白皮'],
        introduction: '她偶尔鬼马俏皮，偶尔气质清新；她是日系邻家小妹，热爱记录分享生活',
        hotSpots: [
            '曾合作资生堂、雅诗兰黛、科颜氏、CPB、赫莲娜、GAP、飞利浦等多个品牌',
            '小红书爆款案例 #早春穿搭|5条少女风连衣裙# 阅读量39w+，互动量3.8w+'
        ],
        images: [
            cdn('images/introduce/59/1.jpg'),
            cdn('images/introduce/59/2.jpg'),
            cdn('images/introduce/59/3.jpg'),
            cdn('images/introduce/59/4.jpg'),
            cdn('images/introduce/59/5.jpg')
        ]
    },
    {
        name: '@三打白骨婶',
        id: null,
        tags: ['性别双修三坑换脸技术流', '二次元UP主', '古风变装达人'],
        introduction: '可盐可甜，做得了美妆玩得了COS，能驾驭国风能玩转日韩，唠嗑画画唱歌跳舞都能冲的四川妹儿',
        hotSpots: [
            '@@曾合作资生堂、雅诗兰黛、科颜氏、CPB、赫莲娜、GAP、飞利浦等多个品牌',
            '@@小红书爆款案例 #早春穿搭|5条少女风连衣裙# 阅读量39w+，互动量3.8w+'
        ],
        images: [
            cdn('images/introduce/三打白骨婶/1.jpg'),
            cdn('images/introduce/三打白骨婶/2.jpg'),
            cdn('images/introduce/三打白骨婶/3.jpg'),
            cdn('images/introduce/三打白骨婶/4.jpg'),
            cdn('images/introduce/三打白骨婶/5.jpg')
        ]
    },
    {
        name: '@肉儿呀',
        id: null,
        tags: ['哈工大高材生', '亲和力十足vlogger'],
        introduction: '她是来自哈工大的学霸妹妹；她亲和力十足，深受粉丝喜爱；她是爱分享日常的生活vlogger',
        hotSpots: ['@@曾合作资生堂、雅诗兰黛、科颜氏、CPB、赫莲娜、GAP、飞利浦等多个品牌'],
        images: [
            cdn('images/introduce/肉儿呀/1.jpg'),
            cdn('images/introduce/肉儿呀/2.jpg'),
            cdn('images/introduce/肉儿呀/3.jpg'),
            cdn('images/introduce/肉儿呀/4.jpg'),
            cdn('images/introduce/肉儿呀/5.jpg')
        ]
    },
    {
        name: '@绒耳朵儿',
        id: 29,
        tags: ['中性风甜酷穿搭博主', '艺术生插画师'],
        introduction: '她是色彩鬼马的搭配小天才，普通衣服都能驾驭的时髦精；她是行走的种草机，满满的穿搭干货；她是高艺术调性的艺术生插画师',
        hotSpots: [
            '曾合作祖玛珑、Dior、雅诗兰黛、资生堂、科颜氏等多个品牌微博爆款案例 #风格各异七天穿搭# 阅读量238w+，播放量53w+'
        ],
        images: [
            cdn('images/introduce/29/1.jpg'),
            cdn('images/introduce/29/2.jpg'),
            cdn('images/introduce/29/3.jpg'),
            cdn('images/introduce/29/4.jpg'),
            cdn('images/introduce/29/5.jpg')
        ]
    },
    {
        name: '@饶萌呀',
        id: 66,
        tags: ['圆脸可爱系美妆博主', '招猫逗狗的铲屎官', '摄影能手'],
        introduction: '她长着一张可爱圆脸，复古活泼，大大咧咧；她是招猫逗狗的铲屎官；是优质审美的时尚先行者',
        hotSpots: [
            '曾合作LA MER、赫莲娜、花西子、戴森、珀莱雅等多个品牌',
            '微博B站小红书三平台同步爆款视频 #圆脸化妆误区# 总阅读量271.6w+，总播放量65w+'
        ],
        images: [
            cdn('images/introduce/66/1.jpg'),
            cdn('images/introduce/66/2.jpg'),
            cdn('images/introduce/66/3.jpg'),
            cdn('images/introduce/66/4.jpg'),
            cdn('images/introduce/66/5.jpg')
        ]
    },
    {
        name: '@全智鹅',
        id: 69,
        tags: ['韩系鬼马少女', '综艺感十足的分享种草机'],
        introduction: '她是穿搭风格独树一帜的鬼马精灵；她是综艺感十足的生活分享种草机；她热爱分享生活碎片，是快乐调色盘',
        hotSpots: [
            '曾合作TOM FORD、FCMM、New Balance、ZOZO、HFP等多个品牌',
            '微博爆款案例 #日常拍照相机分享# 单篇互动量4.3w+'
        ],
        images: [
            cdn('images/introduce/69/1.jpg'),
            cdn('images/introduce/69/2.jpg'),
            cdn('images/introduce/69/3.jpg'),
            cdn('images/introduce/69/4.jpg'),
            cdn('images/introduce/69/5.jpg')
        ]
    },
    {
        name: '@秋野小梨',
        id: null,
        tags: ['超会拍照的女大学生', '时尚穿搭博主'],
        introduction: '她是情感博主里最会拍照的，拍照好的女大学生里最会穿搭的',
        hotSpots: [
            '@@曾合作TOM FORD、FCMM、New Balance、ZOZO、HFP等多个品牌',
            '@@微博爆款案例#日常拍照相机分享#单篇互动量4.3w+'
        ],
        images: [
            cdn('images/introduce/秋野小梨/1.jpg'),
            cdn('images/introduce/秋野小梨/2.jpg'),
            cdn('images/introduce/秋野小梨/3.jpg'),
            cdn('images/introduce/秋野小梨/4.jpg'),
            cdn('images/introduce/秋野小梨/5.jpg')
        ]
    },
    {
        name: '@浅伊____',
        id: null,
        tags: ['高冷厌世日系辣妹', '国际潮牌野生代言人'],
        introduction: '她是留美时尚大牌街拍宠儿；她是各种风格都能驾驭的穿搭时尚博主；她是动手能力强、创意灵感多的穿搭改造师',
        hotSpots: ['曾合作兰蔻、svndz、ZARA', '曾受邀参与上海时装周'],
        images: [
            cdn('images/introduce/浅伊____/1.jpg'),
            cdn('images/introduce/浅伊____/2.jpg'),
            cdn('images/introduce/浅伊____/3.jpg'),
            cdn('images/introduce/浅伊____/4.jpg'),
            cdn('images/introduce/浅伊____/5.jpg')
        ]
    },
    {
        name: '@柒七Kylin',
        id: 70,
        tags: ['技术流时尚美妆博主', 'B站换头仿妆二次元UP主'],
        introduction: '她是超强仿妆手艺人；她是精通换头术的老二次元；她是感染力Max的活力戏精女孩；她是生活和发色一样多彩的潇洒小姐',
        hotSpots: [
            '曾合作阿玛尼、雅诗兰黛、TOM FORD、MAC、阿迪达斯等多个品牌',
            'B站爆款视频 #四大美人杨玉环-羞花# 播放量47.9w'
        ],
        images: [
            cdn('images/introduce/70/1.jpg'),
            cdn('images/introduce/70/2.jpg'),
            cdn('images/introduce/70/3.jpg'),
            cdn('images/introduce/70/4.jpg'),
            cdn('images/introduce/70/5.jpg')
        ]
    },
    {
        name: '@七原里曹',
        id: null,
        tags: ['超有亲和力的学生党博主', '恋爱10年的甜蜜恋爱情侣博主'],
        introduction: '她是热爱生活VLOG分享的学生党博主；恋爱10年，CP甜蜜满满',
        hotSpots: ['@@曾合作阿玛尼、雅诗兰黛、TOM FORD、MAC、阿迪达斯等多个品牌'],
        images: [
            cdn('images/introduce/七原里曹/1.jpg'),
            cdn('images/introduce/七原里曹/2.jpg'),
            cdn('images/introduce/七原里曹/3.jpg'),
            cdn('images/introduce/七原里曹/4.jpg'),
            cdn('images/introduce/七原里曹/5.jpg')
        ]
    },
    {
        name: '@朴正义',
        id: null,
        tags: ['VLOG分享达人', '护肤、时尚达人'],
        introduction: '她是引领潮流的时尚穿搭达人；她是日常vlog走心分享的圈粉博主；她是精致护肤的好物分享官',
        hotSpots: [
            '曾合作SKⅡ、TOM FORD、OLAY、金纺、华为、美图秀秀等多品类品牌',
            '微博爆款视频 #热门零食趣味测评# 阅读量166w+'
        ],
        images: [
            cdn('images/introduce/朴正义/1.jpg'),
            cdn('images/introduce/朴正义/2.jpg'),
            cdn('images/introduce/朴正义/3.jpg'),
            cdn('images/introduce/朴正义/4.jpg'),
            cdn('images/introduce/朴正义/5.jpg')
        ]
    },
    {
        name: '@蒲mei',
        id: null,
        tags: ['圆脸可爱系美妆博主', '微胖甜辣风时尚达人'],
        introduction: '她是手把手教你百变妆容的美妆博主；她是甜辣兼修、爆文频出的穿搭达人；她是口播风趣的干货分享博主',
        hotSpots: [
            '曾合作NARS、毛戈平、Bobbi Brown、花西子、完美日记等多个美妆品牌',
            '小红书爆款视频 #小个子微胖纯欲风连衣裙分享# 阅读量35.7w+，互动量2.6w+'
        ],
        images: [
            cdn('images/introduce/蒲mei/1.jpg'),
            cdn('images/introduce/蒲mei/2.jpg'),
            cdn('images/introduce/蒲mei/3.jpg'),
            cdn('images/introduce/蒲mei/4.jpg'),
            cdn('images/introduce/蒲mei/5.jpg')
        ]
    },
    {
        name: '@屁大点事分享家',
        id: null,
        tags: ['段子频出的美食挖掘机', '学霸妹妹'],
        introduction: '她是段子频出的美食挖掘机，口播种草力满满的学霸妹妹；她是数据稳定，破千赞爆文率高，粉丝粘性强，评论区氛围好的德云社女弟子；她是美食生活属性强，后期效果足、视频趣味多的种草达人',
        hotSpots: [
            '女性粉丝99%，Z世代粉丝超70%',
            '曾合作雀巢、蕉下、花西子、珀莱雅等多品类品牌',
            '小红书平均互动700+，爆款视频 #空口白话零食大会# 点击超22w，互动6000+'
        ],
        images: [
            cdn('images/introduce/屁大点事分享家/1.jpg'),
            cdn('images/introduce/屁大点事分享家/2.jpg'),
            cdn('images/introduce/屁大点事分享家/3.jpg'),
            cdn('images/introduce/屁大点事分享家/4.jpg'),
            cdn('images/introduce/屁大点事分享家/5.jpg')
        ]
    },
    {
        name: '@帕尔哈迪迪',
        id: 60,
        tags: ['日美潮流穿搭男博主', '口播一流综艺感十足的玩梗达人'],
        introduction: '他是穿搭届迪迦，驾驭得了cityboy/日美潮流多种风格；他是综艺感十足的玩梗达人；他是多平台百花齐放，领域广泛的穿搭/男性/运动/健身/生活博主',
        hotSpots: [
            '曾合作Fila、科颜氏、优衣库、索尼、飞利浦等多个品类品牌',
            '抖音爆款视频 #男性の毛处理方法# 播放量577.2w+',
            '微博爆款视频 #迪迪登山记# 播放量95w+'
        ],
        images: [
            cdn('images/introduce/60/1.jpg'),
            cdn('images/introduce/60/2.jpg'),
            cdn('images/introduce/60/3.jpg'),
            cdn('images/introduce/60/4.jpg'),
            cdn('images/introduce/60/5.jpg')
        ]
    },
    {
        name: '@蒋国庆',
        id: null,
        tags: ['美妆博主', '好物分享属性'],
        introduction: '@@他是穿搭届迪迦，驾驭得了cityboy/日美潮流多种风格；他是综艺感十足的玩梗达人；他是多平台百花齐放，领域广泛的穿搭/男性/运动/健身/生活博主',
        hotSpots: ['@@曾合作Fila、科颜氏、优衣库、索尼、飞利浦等多个品类品牌'],
        images: [
            cdn('images/introduce/蒋国庆/1.jpg'),
            cdn('images/introduce/蒋国庆/2.jpg'),
            cdn('images/introduce/蒋国庆/3.jpg'),
            cdn('images/introduce/蒋国庆/4.jpg'),
            cdn('images/introduce/蒋国庆/5.jpg')
        ]
    },
    {
        name: '@女娲啊呀',
        id: null,
        tags: ['黄皮口红试色博主', '宿舍彩妆达人'],
        introduction: '她是黄皮无滤镜口红试色分享博主；她是宿舍变妆达人',
        hotSpots: ['@@曾合作Fila、科颜氏、优衣库、索尼、飞利浦等多个品类品牌'],
        images: [
            cdn('images/introduce/女娲啊呀/1.jpg'),
            cdn('images/introduce/女娲啊呀/2.jpg'),
            cdn('images/introduce/女娲啊呀/3.jpg'),
            cdn('images/introduce/女娲啊呀/4.jpg'),
            cdn('images/introduce/女娲啊呀/5.jpg')
        ]
    },
    {
        name: '@你的慢慢_',
        id: null,
        tags: ['优质审美时尚博主', '名品高颜值达人'],
        introduction: '她是走在潮流前端的时尚穿搭博主；她是长着一张名品脸的高颜值达人',
        hotSpots: ['曾合作小奥汀、Amanda一叶一菩提、红谷、水柚、reenex医美等多品类品牌'],
        images: [
            cdn('images/introduce/你的慢慢_/1.jpg'),
            cdn('images/introduce/你的慢慢_/2.jpg'),
            cdn('images/introduce/你的慢慢_/3.jpg'),
            cdn('images/introduce/你的慢慢_/4.jpg'),
            cdn('images/introduce/你的慢慢_/5.jpg')
        ]
    },
    {
        name: '@尼扣LonelyGOD',
        id: null,
        tags: ['@@优质审美时尚博主'],
        introduction: '她是对新锐国货有独特洞察研究的CEO',
        hotSpots: ['@@曾合作小奥汀、Amanda一叶一菩提、红谷、水柚、reenex医美等多品类品牌'],
        images: [
            cdn('images/introduce/尼扣LonelyGOD/1.jpg'),
            cdn('images/introduce/尼扣LonelyGOD/2.jpg'),
            cdn('images/introduce/尼扣LonelyGOD/3.jpg'),
            cdn('images/introduce/尼扣LonelyGOD/4.jpg'),
            cdn('images/introduce/尼扣LonelyGOD/5.jpg')
        ]
    },
    {
        name: '@男人相',
        id: 45,
        tags: ['时尚变美技巧分享小达人', '东北迪丽热巴', '韩系氛围感美女'],
        introduction: '她是美护时尚、发型秘籍教程全分享的时尚变美博主；她是爱分享时尚vlog情感类的东北“迪丽热巴”；她是商业合作爆款制造机，真情实感种草机',
        hotSpots: [
            '曾合作TOM FORD、LA MER、CHANEL、OLAY、资生堂等多个大牌',
            '女性粉丝占比94.1%，Z世代粉丝超50%',
            '小红书爆文案例#抗老我能帮你的只能到这了#阅读量超187w'
        ],
        images: [
            cdn('images/introduce/45/1.jpg'),
            cdn('images/introduce/45/2.jpg'),
            cdn('images/introduce/45/3.jpg'),
            cdn('images/introduce/45/4.jpg'),
            cdn('images/introduce/45/5.jpg')
        ]
    },
    {
        name: '@奶油Niel',
        id: null,
        tags: ['服装设计在读选手', '时尚分享达人'],
        introduction: '她是学生党宠爱的韩系时尚博主；是冷门彩妆挖掘机，小众产品分享爱好者',
        hotSpots: ['女粉占比近97%，Z世代粉丝超73%', '曾合作爱敬、彩棠、UNNY等多个彩妆品牌'],
        images: [
            cdn('images/introduce/奶油Niel/1.jpg'),
            cdn('images/introduce/奶油Niel/2.jpg'),
            cdn('images/introduce/奶油Niel/3.jpg'),
            cdn('images/introduce/奶油Niel/4.jpg'),
            cdn('images/introduce/奶油Niel/5.jpg')
        ]
    },

    {
        name: '@陈鱼白',
        id: 68,
        tags: ['140斤微胖穿搭红人', '显瘦穿搭高手'],
        introduction: '她是超会穿，超自信的140斤大码女孩；她是热爱生活的元气少女，不被定义的微胖女生；她是热爱分享的宠粉博主',
        hotSpots: [
            '曾合作唯品会、森马、MLB、桑扶兰等多个品牌',
            '微博爆款视频 #两位数包包分享# 阅读量85w+',
            '抖音爆款视频 #ins博主推荐# 阅读量184w+'
        ],
        images: [
            cdn('images/introduce/68/1.jpg'),
            cdn('images/introduce/68/2.jpg'),
            cdn('images/introduce/68/3.jpg'),
            cdn('images/introduce/68/4.jpg'),
            cdn('images/introduce/68/5.jpg')
        ]
    },
    {
        name: '@Angel阿-',
        id: null,
        tags: ['00后vlog博主邻家可爱型小个子美女'],
        introduction: '她是热爱生活的00后浪漫主义少女，也是爱捕捉美好镜头的拍照小能手喜欢给生活制造惊喜，深受学生党喜爱',
        hotSpots: [
            '女性粉丝占比超95%，Z世代粉丝占比超50%',
            '广告数据稳定且优异，抖音商单平均点赞1w+',
            '曾合作OLAY、施华蔻、珀莱雅、完美日记、星巴克、格力高等多个品牌'
        ],
        images: [
            cdn('images/introduce/Angel阿-/1.jpg'),
            cdn('images/introduce/Angel阿-/2.jpg'),
            cdn('images/introduce/Angel阿-/3.jpg'),
            cdn('images/introduce/Angel阿-/4.jpg'),
            cdn('images/introduce/Angel阿-/5.jpg')
        ]
    },
    {
        name: '@ashui-AS',
        id: null,
        tags: ['时尚达人'],
        introduction: '@@她是热爱生活的00后浪漫主义少女，也是爱捕捉美好镜头的拍照小能手喜欢给生活制造惊喜，深受学生党喜爱',
        hotSpots: [
            '@@女性粉丝占比超95%，Z世代粉丝占比超50%',
            '@@广告数据稳定且优异，抖音商单平均点赞1w+',
            '@@曾合作OLAY、施华蔻、珀莱雅、完美日记、星巴克、格力高等多个品牌'
        ],
        images: [
            cdn('images/introduce/ashui-AS/1.jpg'),
            cdn('images/introduce/ashui-AS/2.jpg'),
            cdn('images/introduce/ashui-AS/3.jpg'),
            cdn('images/introduce/ashui-AS/4.jpg'),
            cdn('images/introduce/ashui-AS/5.jpg')
        ]
    },
    {
        name: '@Babeei張張-',
        id: 36,
        tags: ['时尚穿搭博主', '涂鸦小能手', '小众好物挖掘机'],
        introduction: '她是喜欢玩转色彩的时尚穿搭博主也是古灵精怪、分享欲超强的鬼马少女超会淘各种可爱又小众的东西经常有很多古灵精怪的灵感，做更有趣的热点内容',
        hotSpots: [
            '曾合作LA MER、YSL、雅诗兰黛、施华洛世奇、飞利浦等多个品牌',
            '微博爆款案例 #年度穿搭总结# 阅读量1.03亿+，互动量4.3w+'
        ],
        images: [
            cdn('images/introduce/36/1.jpg'),
            cdn('images/introduce/36/2.jpg'),
            cdn('images/introduce/36/3.jpg'),
            cdn('images/introduce/36/4.jpg'),
            cdn('images/introduce/36/5.jpg')
        ]
    },
    {
        name: '@BabyVenerer',
        id: null,
        tags: ['彩妆试色垂类博主', '高级感'],
        introduction: '她是小众彩妆挖掘机，大牌新品指南针；她是想把心动最真实的颜色分享给大家的彩妆试色垂类博主',
        hotSpots: [
            '曾合作阿玛尼、植村秀、CHANEL、YSL、Dior、兰蔻等多个彩妆品牌',
            '受邀参与娇兰线下活动，获得植村秀、露华浓淘内授权',
            '小红书爆款案例 #冷门色试色# 阅读量42.5w+，互动量2w+'
        ],
        images: [
            cdn('images/introduce/BabyVenerer/1.jpg'),
            cdn('images/introduce/BabyVenerer/2.jpg'),
            cdn('images/introduce/BabyVenerer/3.jpg'),
            cdn('images/introduce/BabyVenerer/4.jpg'),
            cdn('images/introduce/BabyVenerer/5.jpg')
        ]
    },
    {
        name: '@BB大哥',
        id: 33,
        tags: ['港风高级脸', '分差鬼马精灵', '美妆护肤推荐王'],
        introduction: '她是鬼马精灵的戏精少女，热爱记录分享生活；她是高级精致的港风美人，钟爱美妆颜值出挑；用镜头表达属于自己的故事，做到病毒式传播的快乐崇拜',
        hotSpots: [
            '曾合作LA MER、CHANEL、SKⅡ、YSL、TOM FORD、雅诗兰黛等多个大牌',
            '受邀参与路易威登《看见LV》全球巡回展览活动',
            '微博爆款视频 #裸雾battle妆# 播放量717w+',
            'B站爆款视频 #把2020淘宝年度丑东西穿上身# 播放量130w+，互动量10w+'
        ],
        images: [
            cdn('images/introduce/33/1.jpg'),
            cdn('images/introduce/33/2.jpg'),
            cdn('images/introduce/33/3.jpg'),
            cdn('images/introduce/33/4.jpg'),
            cdn('images/introduce/33/5.jpg')
        ]
    },
    {
        name: '@bibibiu',
        id: null,
        tags: ['少女感穿搭博主'],
        introduction: '@@她是鬼马精灵的戏精少女，热爱记录分享生活；她是高级精致的港风美人，钟爱美妆颜值出挑；用镜头表达属于自己的故事，做到病毒式传播的快乐崇拜',
        hotSpots: [
            '@@曾合作LA MER、CHANEL、SKⅡ、YSL、TOM FORD、雅诗兰黛等多个大牌',
            '@@受邀参与路易威登《看见LV》全球巡回展览活动'
        ],
        images: [
            cdn('images/introduce/bibibiu/1.jpg'),
            cdn('images/introduce/bibibiu/2.jpg'),
            cdn('images/introduce/bibibiu/3.jpg'),
            cdn('images/introduce/bibibiu/4.jpg'),
            cdn('images/introduce/bibibiu/5.jpg')
        ]
    },
    {
        name: '@BooBoo_蛋清',
        id: 78,
        tags: ['甜酷复古穿搭', '小众好物挖掘机'],
        introduction: '她是独具个人特色的小众少女，热爱分享记录生活；她是对生活有态度对内容有审美的复古高级范；她用穿搭记录审美，用镜头体现态度，不拘一格独特自然美',
        hotSpots: [
            '曾合作LA MER、雅诗兰黛、兰蔻、OLAY、puma、戴森等多个品牌',
            '受邀参与FENDI线下活动、特步秀场等活动',
            '博爆款案例 #夏日LOOK# 阅读量745w+，互动量7.4k+'
        ],
        images: [
            cdn('images/introduce/78/1.jpg'),
            cdn('images/introduce/78/2.jpg'),
            cdn('images/introduce/78/3.jpg'),
            cdn('images/introduce/78/4.jpg'),
            cdn('images/introduce/78/5.jpg')
        ]
    },
    {
        name: '@Celine D',
        id: null,
        tags: ['独立品牌设计师', '高级生活分享家', '干货穿搭教程'],
        introduction: '她是分享专业干货的时尚达人，传递独特服饰理念与穿搭技巧；她也是高级感生活方式分享家，从专业视角安利“贵妇”好物',
        hotSpots: ['女性粉丝占比超95%，25-30岁粉丝占比超20%', '曾于兰蔻、资生堂、lululemon、雅萌等多品类品牌合作'],
        images: [
            cdn('images/introduce/Celine D/1.jpg'),
            cdn('images/introduce/Celine D/2.jpg'),
            cdn('images/introduce/Celine D/3.jpg'),
            cdn('images/introduce/Celine D/4.jpg'),
            cdn('images/introduce/Celine D/5.jpg')
        ]
    },
    {
        name: '@ChiliCharlotte',
        id: null,
        tags: ['曼彻斯特大学时尚研究生', '热衷于欧美妆的百变女孩', '不被风格拘束的辣妹穿搭'],
        introduction: '她是不被风格定义的欧美风辣妹；拽姐外表下隐藏着平易近人的“姐姐”感；她是热衷于尝试不同风格的妆容造型，拍照穿搭美妆全能型博主',
        hotSpots: ['曾合作过YSL、Fenty Beauty、Urban Decay、Chanel、NARS', '曼彻斯特大学研究生背景，对时尚领域了解颇深'],
        images: [
            cdn('images/introduce/ChiliCharlotte/1.jpg'),
            cdn('images/introduce/ChiliCharlotte/2.jpg'),
            cdn('images/introduce/ChiliCharlotte/3.jpg'),
            cdn('images/introduce/ChiliCharlotte/4.jpg'),
            cdn('images/introduce/ChiliCharlotte/5.jpg')
        ]
    },
    {
        name: '@dorothealll',
        id: null,
        tags: ['海归治愈系vlog博主', '精致生活护肤健身达人', '分享辣妹穿搭', 'UIUC广告专业美本', '小众美护挖掘机'],
        introduction: '她是治愈系生活vlog博主；她是自律健身，但也爱吃爱玩的小女生辣妹外表又超级温柔的多面达人；留美归来，独立精致的是她的代名词',
        hotSpots: ['合作过prada、娇兰、Chanel、雪肌精、星巴克等各领域大牌'],
        images: [
            cdn('images/introduce/dorothealll/1.jpg'),
            cdn('images/introduce/dorothealll/2.jpg'),
            cdn('images/introduce/dorothealll/3.jpg'),
            cdn('images/introduce/dorothealll/4.jpg'),
            cdn('images/introduce/dorothealll/5.jpg')
        ]
    },
    {
        name: '@D登登',
        id: 79,
        tags: ['大码微胖轻熟风姐姐', '变装小达人'],
        introduction: '她是百变风格的大码女孩，自信才最美；她是化妆技术一流的妆教博主；她是有着健身减肥的经验，粉丝粘性强',
        hotSpots: ['曾合作Ulike、博朗、天猫国际、珍视明等品牌', '微博优质案例 #160斤Vs95斤胖瘦穿搭对比# 阅读量145w+'],
        images: [
            cdn('images/introduce/79/1.jpg'),
            cdn('images/introduce/79/2.jpg'),
            cdn('images/introduce/79/3.jpg'),
            cdn('images/introduce/79/4.jpg'),
            cdn('images/introduce/79/5.jpg')
        ]
    },
    {
        name: '@Elin_顾零一',
        id: 74,
        tags: ['小众甜酷穿搭博主', '美院毕业野生造型师', '高艺术调性大码微胖轻熟风姐姐'],
        introduction: '她是中国美院服装设计毕业，艺术调性极高的野生造型师；她是小众甜酷穿搭垂类博主，有“1000条裙子”专栏；她是分享精致妆容、甜蜜日常的精致生活家',
        hotSpots: [
            '曾合作Dior、华伦天奴、罗意威、卡地亚等多个高奢品牌',
            '直播间带货上新期间累计观看量101.5w+，互动量7.5k+',
            '微博爆款案例 #照片涂鸦保姆级教程放送# 阅读量756w+，互动量1.7w+'
        ],
        images: [
            cdn('images/introduce/74/1.jpg'),
            cdn('images/introduce/74/2.jpg'),
            cdn('images/introduce/74/3.jpg'),
            cdn('images/introduce/74/4.jpg'),
            cdn('images/introduce/74/5.jpg')
        ]
    },
    {
        name: '@Frequency山鸡',
        id: null,
        tags: ['时尚达人', '潮酷ICON'],
        introduction: '她是高级感满分、受大牌青睐的气质美女；她是风格鲜明独特的潮流时尚ICON',
        hotSpots: [
            '曾合作LA MER、DIOR、力士、拉面说、轻颜等多个品牌',
            '微博优质内容 #AHA洗面奶植入图文# 阅读量133w+，互动量1.3k+'
        ],
        images: [
            cdn('images/introduce/Frequency山鸡/1.jpg'),
            cdn('images/introduce/Frequency山鸡/2.jpg'),
            cdn('images/introduce/Frequency山鸡/3.jpg'),
            cdn('images/introduce/Frequency山鸡/4.jpg'),
            cdn('images/introduce/Frequency山鸡/5.jpg')
        ]
    },
    {
        name: '@freshgirl_',
        id: null,
        tags: ['时尚达人', '成分党'],
        introduction: '她是专业成分党，深入浅出剖析护肤知识；她是职场女强人，真实励志分享职场干货；她是文风鬼马，风格百变，可甜可盐的斜杠艺术家',
        hotSpots: [
            '曾合作雅诗兰黛、施华蔻、OLAY、多芬、护舒宝、潘婷等多个品牌',
            '受邀参与TOM FORD线下活动',
            '微博爆款视频 #花光一个月的工资，我买了啥# 阅读量340w+，互动量4.5k+'
        ],
        images: [
            cdn('images/introduce/freshgirl_/1.jpg'),
            cdn('images/introduce/freshgirl_/2.jpg'),
            cdn('images/introduce/freshgirl_/3.jpg'),
            cdn('images/introduce/freshgirl_/4.jpg'),
            cdn('images/introduce/freshgirl_/5.jpg')
        ]
    },
    {
        name: '@Jasmine茉莉与冰川',
        id: null,
        tags: ['旅行时尚vlogger', '北欧气质生活分享家时尚达人'],
        introduction: '她是能够独立产出时尚挪威短片的vlogger；她是崇尚温和护肤的护肤达人；她是极具北欧气息的短片导演',
        hotSpots: [
            '曾合作科颜氏、佳能、尼康、Girlcult等品牌',
            'B站优质内容 #北欧摄影短片|短片、木屋、森林# 播放量42.5w+，互动量6.4w+'
        ],
        images: [
            cdn('images/introduce/Jasmine茉莉与冰川/1.jpg'),
            cdn('images/introduce/Jasmine茉莉与冰川/2.jpg'),
            cdn('images/introduce/Jasmine茉莉与冰川/3.jpg'),
            cdn('images/introduce/Jasmine茉莉与冰川/4.jpg'),
            cdn('images/introduce/Jasmine茉莉与冰川/5.jpg')
        ]
    },
    {
        name: '@LiLi长不大',
        id: 64,
        tags: ['美妆技术流', '购物小天才', '微胖穿搭博主', '情侣CP'],
        introduction: '她是为美妆而生的百变妆容技术流；她是很有品位很会买的购物小天才；她是微胖届的穿搭新星；她是分享情侣CP精致生活的分享家',
        hotSpots: [
            '曾合作TOM FORD、YSL、纪梵希、资生堂、兰蔻、欧莱雅等多个大牌',
            '微博爆款视频 #微胖女生到底可以多性感# 播放量93w+，互动量8.5k+'
        ],
        images: [
            cdn('images/introduce/64/1.jpg'),
            cdn('images/introduce/64/2.jpg'),
            cdn('images/introduce/64/3.jpg'),
            cdn('images/introduce/64/4.jpg'),
            cdn('images/introduce/64/5.jpg')
        ]
    },
    {
        name: '@Lsy秦公子',
        id: 80,
        tags: ['美妆好物分享达人', '时尚穿搭博主'],
        introduction: '她调性高级清冷，简约风格鲜明，时尚感满分；她对美的苛求深入每个细节；高级审美得到粉丝们的喜爱和追随；她是通过镜头分享精致生活的优质创作者，传播专属于个人的时尚属性',
        hotSpots: [
            '曾合作祖玛珑、Bobbi Brown、科颜氏、sisley、雪花秀等品牌',
            '小红书优质内容 #圣诞约会穿搭# 阅读量20.1w+，互动2.3w+'
        ],
        images: [
            cdn('images/introduce/80/1.jpg'),
            cdn('images/introduce/80/2.jpg'),
            cdn('images/introduce/80/3.jpg'),
            cdn('images/introduce/80/4.jpg'),
            cdn('images/introduce/80/5.jpg')
        ]
    },
    {
        name: '@lu.meng',
        id: 27,
        tags: ['皇家艺术学院当代艺术研究生', '小众有调性穿搭博主', '超高艺术审美', '女性力量输出'],
        introduction: '她是高艺术审美的高学历艺术系美女；她有着独特穿搭风格的时尚穿搭博主；她是融合了当代艺术审美和思维的干货输出达人；她是致力于小众穿搭&女性力量输出的魅力女孩',
        hotSpots: [
            '曾合作祖马龙、Gucci、Armani、Tom Ford、兰蔻等国际大牌',
            '当代艺术电影导演，作品获的海外电影节多个提名与奖项'
        ],
        images: [
            cdn('images/introduce/27/1.jpg'),
            cdn('images/introduce/27/2.jpg'),
            cdn('images/introduce/27/3.jpg'),
            cdn('images/introduce/27/4.jpg'),
            cdn('images/introduce/27/5.jpg')
        ]
    },
    {
        name: '@LUSON妈-',
        id: null,
        tags: ['三胎妈妈', '微博母婴育儿大V'],
        introduction: '她对内是三个孩子的全能妈妈，对外是事业成功的独立女强人；她时刻保持积极的生活状态，用镜头传递能量',
        hotSpots: [
            '2018年，LUSON妈获得微博十大成长母婴育儿大V称号，淘宝优秀红人奖',
            '2020年，先后荣获深圳年度消费影响力KOL、淘宝认证“亲宝贝带货官”、淘宝母婴行业优秀主播、母婴亲子淘明星奖、辣妈帮童装童鞋口碑奖等奖项',
            '2021年6月，带领LUSON成功当选深圳服装行业协会副会长单位',
            '微博视频单条浏览3185万，点赞3.1万'
        ],
        images: [
            cdn('images/introduce/LUSON妈-/1.jpg'),
            cdn('images/introduce/LUSON妈-/2.jpg'),
            cdn('images/introduce/LUSON妈-/3.jpg'),
            cdn('images/introduce/LUSON妈-/4.jpg'),
            cdn('images/introduce/LUSON妈-/5.jpg')
        ]
    },
    {
        name: '@Mt文文婷',
        id: null,
        tags: ['复古穿搭达人', '时尚种草机'],
        introduction: '她是镜头感十足的搭配鬼才；她是具有摩登气质、完美身材的穿搭博主',
        hotSpots: [
            '@@微博视频单条浏览3185万，点赞3.1万'
        ],
        images: [
            cdn('images/introduce/Mt文文婷/1.jpg'),
            cdn('images/introduce/Mt文文婷/2.jpg'),
            cdn('images/introduce/Mt文文婷/3.jpg'),
            cdn('images/introduce/Mt文文婷/4.jpg'),
            cdn('images/introduce/Mt文文婷/5.jpg')
        ]
    },
    {
        name: '@nnuuxx',
        id: null,
        tags: ['淡颜韩系美妆博主', '初恋感甜妹', '在读音乐系留学生'],
        introduction: '她是清纯淡颜系的代名词；她是移动的时尚种草机；她是淡颜系妆容的爆款制造机',
        hotSpots: [
            '曾合作Dior、兰蔻、Tom Ford、华伦天奴、赫莲娜、阿玛尼等多个品牌',
            '小红书爆款案例 #淡颜出游妆# 阅读量8.9w+，互动量9k+'
        ],
        images: [
            cdn('images/introduce/nnuuxx/1.jpg'),
            cdn('images/introduce/nnuuxx/2.jpg'),
            cdn('images/introduce/nnuuxx/3.jpg'),
            cdn('images/introduce/nnuuxx/4.jpg'),
            cdn('images/introduce/nnuuxx/5.jpg')
        ]
    },
    {
        name: '@RealGloGlow',
        id: null,
        tags: ['欧美风', '时尚穿搭博主', '旅行vlog达人', '情侣内容爆款制造机'],
        introduction: '她是来自北京的性感辣妹，欧美风格百变穿搭；她精致妆容走心分享；她是旅行vlog分享达人；她是情侣类内容爆款制造机',
        hotSpots: ['合作赫莲娜、雅诗兰黛、OLAY、施华蔻等多个品牌', 'B站爆款视频 #好物开箱试穿# 播放量219.4w+，互动量8.3w+'],
        images: [
            cdn('images/introduce/RealGloGlow/1.jpg'),
            cdn('images/introduce/RealGloGlow/2.jpg'),
            cdn('images/introduce/RealGloGlow/3.jpg'),
            cdn('images/introduce/RealGloGlow/4.jpg'),
            cdn('images/introduce/RealGloGlow/5.jpg')
        ]
    },
    {
        name: '@RMIUC',
        id: 32,
        tags: ['年近40，年轻保养系博主', '大片制造机，深受大牌喜爱', '新西兰留学背景', '专业素养+高配合度=高质量内容输出'],
        introduction: '他是戛纳电影节邀请嘉宾；大片级别图文视频输出机；他帅气温柔的《奇葩说》辩手；他是兼俱时尚度和热点的《爱上超模》导师；他是时尚美护生活等多赛道博主',
        hotSpots: [
            '曾和Prada、Tomford、Calvin Klein、Diesel、Tiffany等国际一线大牌合作',
            '《奇葩说》第一季、第二季辩手；戛纳电影节邀请嘉宾；《爱上超模》导师',
            '与BAZZAR、ELLEMEN、1626等多家海内外时尚杂志合作拍摄'
        ],
        images: [
            cdn('images/introduce/32/1.jpg'),
            cdn('images/introduce/32/2.jpg'),
            cdn('images/introduce/32/3.jpg'),
            cdn('images/introduce/32/4.jpg'),
            cdn('images/introduce/32/5.jpg')
        ]
    },
    {
        name: '@Rmoong龙龙',
        id: 38,
        tags: ['韩系圆脸妹妹', '妈生皮原创博主', '百变妆容技术流'],
        introduction: '她是超级有路人缘的圆脸妹妹；她是百变妆容，一脸沦陷的换头美妆技术流',
        hotSpots: [
            '曾合作雅诗兰黛、YSL、LA MER、科颜氏、悦木之源等多个品牌',
            '小红书爆款案例 #口红合集分享# 阅读量113.9w+，互动量10w+'
        ],
        images: [
            cdn('images/introduce/38/1.jpg'),
            cdn('images/introduce/38/2.jpg'),
            cdn('images/introduce/38/3.jpg'),
            cdn('images/introduce/38/4.jpg'),
            cdn('images/introduce/38/5.jpg')
        ]
    },
    {
        name: '@Sasaaaa',
        id: 26,
        tags: ['精致生活导师', '医美直播带货达人'],
        introduction: '她是医美直播带货达人，品宣优质深受大牌青睐；她是具有甜美气质，分享精致生活方式的美妆达人；她是爆款频出，风格百变的穿搭博主',
        hotSpots: [
            '曾合作LV、香奈儿、LA MER、TOM FORD、Dior、雅诗兰黛等多个品牌',
            '受邀参与走进香奈儿的展览、DIOR BEAUTY新品香水发布会、2018超级红人节等多个活动'
        ],
        images: [
            cdn('images/introduce/26/1.jpg'),
            cdn('images/introduce/26/2.jpg'),
            cdn('images/introduce/26/3.jpg'),
            cdn('images/introduce/26/4.jpg'),
            cdn('images/introduce/26/5.jpg')
        ]
    },
    {
        name: '@Tuitui_',
        id: 61,
        tags: ['单眼皮换脸美妆博主', '英国NTU 时尚管理IFM'],
        introduction: '她是韩系甜辣美妆达人；她是单眼皮圆脸宝藏博主；她是英国NTU研究生毕业，时尚管理IFM',
        hotSpots: [
            '曾合作LA MER、阿玛尼、TOM FORD、资生堂、NARS、DIOR、科颜氏等多个品牌',
            '受邀参与天猫双十一全球潮流盛典、3CE线下活动、维多利亚的秘密、Charlotte、Tilbury线下活动等多个活动',
            '小红书爆款案例 #单眼皮·甜丧辣妹妆# 阅读量19.9w+，互动量2.3w+'
        ],
        images: [
            cdn('images/introduce/61/1.jpg'),
            cdn('images/introduce/61/2.jpg'),
            cdn('images/introduce/61/3.jpg'),
            cdn('images/introduce/61/4.jpg'),
            cdn('images/introduce/61/5.jpg')
        ]
    },
    {
        name: '@Vivekatt',
        id: 8,
        tags: [
            'B站百大up 美妆区前三博主',
            'vogue等多家国际时尚杂志合作职业造型师',
            '北欧follower最多的油管博主',
            '北欧&海外lifestyle'
        ],
        introduction: '她是Vogue Sweden旗下造型师，专业度性；她是内容超干货，肿单眼皮/130斤大骨架逆袭的博主；她是分享超甜异国姐弟恋的cp趣事博主；她是有一只高冷猫咪的标准猫奴',
        hotSpots: [
            '曾合作雅诗兰黛、资生堂、丝芙兰、科颜氏、欧莱雅、纽西之谜等多个品牌',
            'Issac Beauty Academy化妆师认证；与H&M/Vogue杂志固定合作',
            '微博B站爆款案例 #金高银淡颜灵感妆# 双平台播放量747w+',
            '露华浓品牌合作，单条视频带货金额38w+，ROI: 2.1',
            '雅诗兰黛品牌合作，单条视频导流UV80w+',
            'Ulike单条图文合作，带货金额310w+，ROI：50'
        ],
        images: [
            cdn('images/introduce/8/1.jpg'),
            cdn('images/introduce/8/2.jpg'),
            cdn('images/introduce/8/3.jpg'),
            cdn('images/introduce/8/4.jpg'),
            cdn('images/introduce/8/5.jpg')
        ]
    },
    {
        name: '@文不叮/Winnie文',
        id: 5,
        tags: ['小红书TOP级KOL', 'B站2020百大UP主'],
        introduction: '她是努力分享干货和生活，深受粉丝喜爱的30岁小阿姨；她是挖掘美护好物分享时尚生活的爆文生产机；她频出变美攻略、偶尔又撒点狗粮。看她的视频就像是隔壁家温柔亲切的大姐姐，总是能让人温暖又有所收货',
        hotSpots: [
            '哔哩哔哩2020百大UP主',
            '粉丝粘性高，其中小红书粉丝互动率达9%',
            '合作过LA MER、赫莲娜、YSL、雅诗兰黛、兰蔻、阿玛尼、资生堂、松下、飞利浦等各大品牌',
            '连续3月登顶小红书时尚向/红人向榜单'
        ],
        images: [
            cdn('images/introduce/5/1.jpg'),
            cdn('images/introduce/5/2.jpg'),
            cdn('images/introduce/5/3.jpg'),
            cdn('images/introduce/5/4.jpg'),
            cdn('images/introduce/5/5.jpg')
        ]
    },
    {
        name: '@yonni李佳润',
        id: null,
        tags: ['韩国时尚博主'],
        introduction: '@@她是努力分享干货和生活，深受粉丝喜爱的30岁小阿姨；看她的视频就像是隔壁家温柔亲切的大姐姐，总是能让人温暖又有所收货',
        hotSpots: [
            '@@哔哩哔哩2020百大UP主',
            '@@连续3月登顶小红书时尚向/红人向榜单'
        ],
        images: [
            cdn('images/introduce/yonni李佳润/1.jpg'),
            cdn('images/introduce/yonni李佳润/2.jpg'),
            cdn('images/introduce/yonni李佳润/3.jpg'),
            cdn('images/introduce/yonni李佳润/4.jpg'),
            cdn('images/introduce/yonni李佳润/5.jpg')
        ]
    },
    {
        name: '@阿斗Adou-',
        id: 90,
        tags: ['日系复古高级感', '时尚穿搭博主', '情侣CP'],
        introduction: '她是超有品的时尚儿，独具个人特色；她用镜头记录自己的时尚态度；不拘一格的她，是复古也是新潮',
        hotSpots: [
            '曾合作雅诗兰黛、FILA、OLAY、New Balance、太平鸟、华为等多个品牌',
            '微博爆款视频 #秋冬穿搭# 阅读量265w+，互动量4.5k+m'
        ],
        images: [
            cdn('images/introduce/90/1.jpg'),
            cdn('images/introduce/90/2.jpg'),
            cdn('images/introduce/90/3.jpg'),
            cdn('images/introduce/90/4.jpg'),
            cdn('images/introduce/90/5.jpg')
        ]
    },
    {
        name: '@阿图tuki',
        id: null,
        tags: ['单眼皮美妆博主', '干货成分种草小能手', '甜蜜姐弟恋'],
        introduction: '她是单眼皮美妆博主—单眼皮形象独特；她是美妆技巧控的生活博主；她清新可爱，生活气息十足',
        hotSpots: ['曾合作雅诗兰黛、橘朵、护舒宝、贝德玛、护舒宝等多个品牌', '微博爆款视频 #美妆保姆级教程# 播放量140w+'],
        images: [
            cdn('images/introduce/阿图tuki/1.jpg'),
            cdn('images/introduce/阿图tuki/2.jpg'),
            cdn('images/introduce/阿图tuki/3.jpg'),
            cdn('images/introduce/阿图tuki/4.jpg'),
            cdn('images/introduce/阿图tuki/5.jpg')
        ]
    },
    {
        name: '@半途鹅飞',
        id: null,
        tags: ['元气美少女', '交大保研学霸', '《怦然心动20岁》综艺嘉宾'],
        introduction: '她是上海交大的设计系研一学姐，性格可爱的自律学霸；她是惹人喜爱的灵气少女，热爱生活热爱时尚，用镜头讲述自己的独家vlog',
        hotSpots: ['曾合作HR、SKii、Bobbi Brown 、科颜氏、毛戈平、花西子、NARS等多个品牌'],
        images: [
            cdn('images/introduce/半途鹅飞/1.jpg'),
            cdn('images/introduce/半途鹅飞/2.jpg'),
            cdn('images/introduce/半途鹅飞/3.jpg'),
            cdn('images/introduce/半途鹅飞/4.jpg'),
            cdn('images/introduce/半途鹅飞/5.jpg')
        ]
    },
    {
        name: '@变脸达人猕猴桃',
        id: null,
        tags: ['黄黑皮美妆博主', '专业彩妆学院毕业', '在职大学老师'],
        introduction: '她是拥有专业彩妆技术的时尚美妆博主；她是曾被宝格丽后台邀请化妆的彩妆学院毕业生',
        hotSpots: ['@@曾合作HR、SKii、Bobbi Brown 、科颜氏、毛戈平、花西子、NARS等多个品牌'],
        images: [
            cdn('images/introduce/变脸达人猕猴桃/1.jpg'),
            cdn('images/introduce/变脸达人猕猴桃/2.jpg'),
            cdn('images/introduce/变脸达人猕猴桃/3.jpg'),
            cdn('images/introduce/变脸达人猕猴桃/4.jpg'),
            cdn('images/introduce/变脸达人猕猴桃/5.jpg')
        ]
    },
    {
        name: '@曾永仪_',
        id: null,
        tags: ['舒适时髦分享型达人', '可甜酷可辣妹'],
        introduction: '舒服随性是她的关键词；拒绝跟随，拒绝做作，坚持做舒适随性又时髦的分享者；外表高冷，实际可塑性超强，可甜可盐；她气质高级，调性高，极受大牌青睐',
        hotSpots: [
            '曾合作香奈儿、卡地亚、华伦天奴、迪奥、赫莲娜、雅诗兰黛、资生堂、花西子等',
            '多个品牌，微博爆款案例 #穿搭对镜拍# 阅读量512w+'
        ],
        images: [
            cdn('images/introduce/曾永仪_/1.jpg'),
            cdn('images/introduce/曾永仪_/2.jpg'),
            cdn('images/introduce/曾永仪_/3.jpg'),
            cdn('images/introduce/曾永仪_/4.jpg'),
            cdn('images/introduce/曾永仪_/5.jpg')
        ]
    },
    {
        name: '@超K',
        id: null,
        tags: ['男装反串旗袍博主', '10年主播经验'],
        introduction: '他是平价好货天才买手身材自信、容貌自信的典范；偶尔作精、偶尔憨憨，深受家庭主妇的喜爱',
        hotSpots: [
            '曾合作多款国货护肤品、彩妆产品',
            '粉丝画像：30-50岁的家庭主妇',
            '擅长带货大消费品日用品、平价美妆护肤产品、大服饰'
        ],
        images: [
            cdn('images/introduce/超K/1.jpg'),
            cdn('images/introduce/超K/2.jpg'),
            cdn('images/introduce/超K/3.jpg'),
            cdn('images/introduce/超K/4.jpg'),
            cdn('images/introduce/超K/5.jpg')
        ]
    },
    {
        name: '@超级轩-',
        id: 58,
        tags: ['甜盐系高颜少女', '时尚穿搭达人', '知名美妆VLOG博主'],
        introduction: '她是高颜值穿搭引得粉丝争相模仿的时尚达人；她是美妆教程、护肤心得两不误的教程博主；她是可甜可盐，自带名品颜值的甜盐系少女',
        hotSpots: [
            '曾合作LA MER、OLAY、丝芙兰、自然堂、美宝莲、3CE等多个品牌',
            '微博爆款视频 #妆容教程，视觉干货双重享受# 播放量279w+'
        ],
        images: [
            cdn('images/introduce/58/1.jpg'),
            cdn('images/introduce/58/2.jpg'),
            cdn('images/introduce/58/3.jpg'),
            cdn('images/introduce/58/4.jpg'),
            cdn('images/introduce/58/5.jpg')
        ]
    },
    {
        name: '@晁然然然然',
        id: 21,
        tags: ['文艺气质国民学姐', '全平台带货达人', '明星红人'],
        introduction: '她是《最好的我们》里的洛枳学姐；她是笑容满满，知性温柔的气质国民学姐；她是生活分享、种草力MAX的带货达人',
        hotSpots: [
            '曾合作TOM FORD、YSL、赫莲娜、雅诗兰黛、兰蔻、资生堂等多个品牌',
            '晁然学姐团购日淘宝直播全场GMV超1500w+',
            '微博爆款视频 #PR礼盒，精彩开箱# 播放量877w+，互动量1.5w+'
        ],
        images: [
            cdn('images/introduce/21/1.jpg'),
            cdn('images/introduce/21/2.jpg'),
            cdn('images/introduce/21/3.jpg'),
            cdn('images/introduce/21/4.jpg'),
            cdn('images/introduce/21/5.jpg')
        ]
    },
    {
        name: '@陳佩佩ArialChen',
        id: null,
        tags: ['台湾美妆达人', '“分手妆”主角', '生活家庭vlog分享博主'],
        introduction: '她是仿妆实力超强，干货满满的仿妆大神；她是美妆护肤两不误的美妆达人；她是喜欢用vlog视频记录家庭生活的分享型博主',
        hotSpots: ['曾合作帮宝适、施华蔻、verve衣物护理液等品牌', '微博爆款视频 #晚间护肤，倾情分享# 播放量39w+'],
        images: [
            cdn('images/introduce/陳佩佩ArialChen/1.jpg'),
            cdn('images/introduce/陳佩佩ArialChen/2.jpg'),
            cdn('images/introduce/陳佩佩ArialChen/3.jpg'),
            cdn('images/introduce/陳佩佩ArialChen/4.jpg'),
            cdn('images/introduce/陳佩佩ArialChen/5.jpg')
        ]
    },
    {
        name: '@崔十八',
        id: null,
        tags: ['换头技术流', '古风二次元'],
        introduction: '@@她是仿妆实力超强，干货满满的仿妆大神；她是美妆护肤两不误的美妆达人；她是喜欢用vlog视频记录家庭生活的分享型博主',
        hotSpots: ['@@曾合作帮宝适、施华蔻、verve衣物护理液等品牌', '微博爆款视频 #晚间护肤，倾情分享# 播放量39w+'],
        images: [
            cdn('images/introduce/崔十八/1.jpg'),
            cdn('images/introduce/崔十八/2.jpg'),
            cdn('images/introduce/崔十八/3.jpg'),
            cdn('images/introduce/崔十八/4.jpg'),
            cdn('images/introduce/崔十八/5.jpg')
        ]
    },
    {
        name: '@大bopa',
        id: null,
        tags: ['穿搭美妆达人', '母婴博主'],
        introduction: '她是用娴熟技巧征服粉丝的美妆博主；她是有着优质审美引领穿搭风潮的时尚红人；她是日常vlog走心圈粉的分享达人',
        hotSpots: [
            '曾合作花西子、大宇、谷雨、Freeplus、PWU等品牌',
            '微博爆款视频 #大脸哑光妆容教程# 阅读量1781w+，互动量2.2k+'
        ],
        images: [
            cdn('images/introduce/大bopa/1.jpg'),
            cdn('images/introduce/大bopa/2.jpg'),
            cdn('images/introduce/大bopa/3.jpg'),
            cdn('images/introduce/大bopa/4.jpg'),
            cdn('images/introduce/大bopa/5.jpg')
        ]
    },
    {
        name: '@大阿奔奔奔奔奔',
        id: null,
        tags: ['上大在读学生党博主', '幽默感十足', '甜妹/少年完美驾驭'],
        introduction: '她是有梗会说，幽默感十足的口播小天才；她是上海大学在读的学生党博主，粉丝黏性高；她擅长发型、妆容类视频植入',
        hotSpots: [
            '@@曾合作花西子、大宇、谷雨、Freeplus、PWU等品牌',
            '@@微博爆款视频 #大脸哑光妆容教程# 阅读量1781w+，互动量2.2k+'
        ],
        images: [
            cdn('images/introduce/大阿奔奔奔奔奔/1.jpg'),
            cdn('images/introduce/大阿奔奔奔奔奔/2.jpg'),
            cdn('images/introduce/大阿奔奔奔奔奔/3.jpg'),
            cdn('images/introduce/大阿奔奔奔奔奔/4.jpg'),
            cdn('images/introduce/大阿奔奔奔奔奔/5.jpg')
        ]
    },
    {
        name: '@大七仔呀',
        id: 63,
        tags: ['小众时尚穿搭达人', '外冷内热傻大妞'],
        introduction: '她是偶尔内敛、偶尔沙雕，热爱生活、热爱穿搭的分享博主；她是外表高冷内心炙热深受粉丝喜爱的傻大妞',
        hotSpots: [
            '曾合作TOM FORD、Dior、兰蔻、资生堂、雅诗兰黛、YSL等多个品牌',
            '微博优质内容 #空瓶购物分享# 阅读量270w+，互动量2.8k+'
        ],
        images: [
            cdn('images/introduce/63/1.jpg'),
            cdn('images/introduce/63/2.jpg'),
            cdn('images/introduce/63/3.jpg'),
            cdn('images/introduce/63/4.jpg'),
            cdn('images/introduce/63/5.jpg')
        ]
    },
    {
        name: '@大侠不是大虾哦',
        id: null,
        tags: ['平面模特', '穿搭变美时尚博主'],
        introduction: '她是做了三年平面模特的小个子穿搭仙女；她是拥有甜美颜值的甜心少女；她是平价安利，爆款频出日常分享家',
        hotSpots: [
            '曾合作Ubras、轩妈、蕉下、元气森林、韩都衣舍、小仙炖等多品类品牌',
            '小红书优质内容 #小个子女生穿搭|香芋奶昔波点毛衣# 阅读量53w+，互动量2.7w+'
        ],
        images: [
            cdn('images/introduce/大侠不是大虾哦/1.jpg'),
            cdn('images/introduce/大侠不是大虾哦/2.jpg'),
            cdn('images/introduce/大侠不是大虾哦/3.jpg'),
            cdn('images/introduce/大侠不是大虾哦/4.jpg'),
            cdn('images/introduce/大侠不是大虾哦/5.jpg')
        ]
    },
    {
        name: '@戴黎婷',
        id: 73,
        tags: ['微电影感高颜值女主', '甜酷穿搭', '甜蜜情侣CP'],
        introduction: '她是颜值出挑的情侣cp博主可甜可盐，热爱分享；擅长美妆护肤和甜酷穿搭；真诚自然，就像你身边的好姐妹没有距离；她是日常有趣生活的vlog创作者；娴熟的铲屎官一枚；优质审美的时尚先行者',
        hotSpots: [
            '曾合作雅诗兰黛、资生堂、兰蔻、欧莱雅、祖玛珑等多个品牌',
            '微博爆款视频 #14天深度测评OLAY淡斑小白瓶# 阅读量8470w+,互动量5k+'
        ],
        images: [
            cdn('images/introduce/73/1.jpg'),
            cdn('images/introduce/73/2.jpg'),
            cdn('images/introduce/73/3.jpg'),
            cdn('images/introduce/73/4.jpg'),
            cdn('images/introduce/73/5.jpg')
        ]
    },
    {
        name: '@邓邓Jt',
        id: 28,
        tags: ['时尚邻家大姐姐', '购物分享达人'],
        introduction: '她是自带甜美气质，擅长日韩妆容的美妆达人；她是玩转穿搭色彩的时尚穿搭博主',
        hotSpots: [
            '曾合作海蓝之谜、赫莲娜、LA MER、娇兰、MAC、雅诗兰黛等多个大牌',
            '受邀参与小红书2021风潮新秀场活动、“闻得到的帕尔玛”线下限时展览、赫莲娜新品发布会等多个活动',
            '抖音爆款视频 #缪缪戏精女孩# 互动量11.1w+'
        ],
        images: [
            cdn('images/introduce/28/1.jpg'),
            cdn('images/introduce/28/2.jpg'),
            cdn('images/introduce/28/3.jpg'),
            cdn('images/introduce/28/4.jpg'),
            cdn('images/introduce/28/5.jpg')
        ]
    },
    {
        name: '@多老师Dora',
        id: null,
        tags: ['海外小众护肤挖宝达人', '小个子穿搭达人', '英文购物小天才'],
        introduction: '她是平价好物挖掘的小天才，韩国彩妆迷妹；她是推崇有效护肤的成分党博主；她是小个子时尚icon',
        hotSpots: ['曾合作LA MER、赫莲娜、资生堂、YSL、科颜氏、OLAY等多个品牌', '女性粉丝占比超92%，Z世代粉丝占比54%'],
        images: [
            cdn('images/introduce/多老师Dora/1.jpg'),
            cdn('images/introduce/多老师Dora/2.jpg'),
            cdn('images/introduce/多老师Dora/3.jpg'),
            cdn('images/introduce/多老师Dora/4.jpg'),
            cdn('images/introduce/多老师Dora/5.jpg')
        ]
    },
    {
        name: '@恩真Blaga',
        id: null,
        tags: ['韩国时尚美妆穿搭达人', '美妆护肤成分党'],
        introduction: '她是用精致的情侣日常点缀不一般的异国生活的韩国女生；用丰富的妆面和穿搭技巧组合出独特的时尚理念；她像从韩剧中走来的女主角，亦像是你身边的知心姐姐',
        hotSpots: [
            '曾合作赫莲娜、雅诗兰黛、OLAY、施华蔻、花西子等多个品牌',
            'B站爆款视频 #月经杯是不丢脸的# 阅读量59.8w+，互动量2.3w+'
        ],
        images: [
            cdn('images/introduce/恩真Blaga/1.jpg'),
            cdn('images/introduce/恩真Blaga/2.jpg'),
            cdn('images/introduce/恩真Blaga/3.jpg'),
            cdn('images/introduce/恩真Blaga/4.jpg'),
            cdn('images/introduce/恩真Blaga/5.jpg')
        ]
    },
    {
        name: '@二门_',
        id: 9,
        tags: ['仿妆博主'],
        introduction: '@@她是用精致的情侣日常点缀不一般的异国生活的韩国女生；用丰富的妆面和穿搭技巧组合出独特的时尚理念；她像从韩剧中走来的女主角，亦像是你身边的知心姐姐',
        hotSpots: [
            '@@曾合作赫莲娜、雅诗兰黛、OLAY、施华蔻、花西子等多个品牌',
            '@@B站爆款视频 #月经杯是不丢脸的# 阅读量59.8w+，互动量2.3w+'
        ],
        images: [
            cdn('images/introduce/9/1.jpg'),
            cdn('images/introduce/9/2.jpg'),
            cdn('images/introduce/9/3.jpg'),
            cdn('images/introduce/9/4.jpg'),
            cdn('images/introduce/9/5.jpg')
        ]
    },
    {
        name: '@还是陈老师',
        id: null,
        tags: ['中韩情侣CP', '整蛊达人'],
        introduction: '她是擅长做情侣趣味视频的中韩情侣博主；她可盐可甜多风格可轻松驾驭；她是能扔下偶像包袱的整蛊达人',
        hotSpots: [
            '曾合作美宝莲、戴森、colorkey、卡姿兰、红地球等多个品牌',
            '微博爆款视频 #真实整蛊，搞笑日常# 播放量95w+，互动量9.2w+'
        ],
        images: [
            cdn('images/introduce/还是陈老师/1.jpg'),
            cdn('images/introduce/还是陈老师/2.jpg'),
            cdn('images/introduce/还是陈老师/3.jpg'),
            cdn('images/introduce/还是陈老师/4.jpg'),
            cdn('images/introduce/还是陈老师/5.jpg')
        ]
    },
    {
        name: '@憨娜hannah',
        id: 25,
        tags: ['大码时尚穿搭博主', '甜美风美妆达人'],
        introduction: '她是不被定义的大码女孩，有着可爱的娃娃脸；她是又正义感爆棚，爱吐槽，爱打抱不平的可爱微胖博主，为胖女孩发声的勇敢少女',
        hotSpots: [
            '曾合作LA MER 、欧莱雅、王饱饱、网易严选、元气森林等多个品牌',
            '曾在 #杨天真选最美大码女孩# 活动中，被选中登上尼龙杂志10月封面，曾登上时尚芭莎杂志、男人装杂志',
            '微博爆款视频 #胖女孩直男审美穿搭挑战# 播放量147w+，阅读量721w+'
        ],
        images: [
            cdn('images/introduce/25/1.jpg'),
            cdn('images/introduce/25/2.jpg'),
            cdn('images/introduce/25/3.jpg'),
            cdn('images/introduce/25/4.jpg'),
            cdn('images/introduce/25/5.jpg')
        ]
    },
    {
        name: '@姬晨在路上',
        id: null,
        tags: ['摄影师', 'VLOG博主'],
        introduction: '@@她是不被定义的大码女孩，有着可爱的娃娃脸；她是又正义感爆棚，爱吐槽，爱打抱不平的可爱微胖博主，为胖女孩发声的勇敢少女',
        hotSpots: [
            '@@曾合作LA MER 、欧莱雅、王饱饱、网易严选、元气森林等多个品牌',
        ],
        images: [
            cdn('images/introduce/姬晨在路上/1.jpg'),
            cdn('images/introduce/姬晨在路上/2.jpg'),
            cdn('images/introduce/姬晨在路上/3.jpg'),
            cdn('images/introduce/姬晨在路上/4.jpg'),
            cdn('images/introduce/姬晨在路上/5.jpg')
        ]
    },
    {
        name: '@嘉島也柏',
        id: null,
        tags: ['多才多艺，真正的时尚教主', '口播能力超群，热衷干货输出', '拒绝伪成分论的成分党'],
        introduction: '京都大学情报研究科大学院硕士；工作中毒舌犀利，测评产品有理有据逻辑清晰；日常却温柔可爱，被粉丝戏称清纯教主本着「不精不诚 不能动人」原则 ，不服从任何物质利益和外在目的，输出内容真诚、负责，讨厌扮丑买弄；虽放弃时尚穿搭内容输出，但依旧因为自身的小众风格和极佳的时尚感牢牢抓住粉丝眼球',
        hotSpots: [
            '曾合作CPB、YSL、兰蔻、雅诗兰黛、毛戈平、资生堂、伊丽莎白雅顿等多个美妆品牌',
            '受邀参与AW21 MAC全球彩妆趋势发布会、PRADA MODE 、VALENTINO RE-SIGNIFY、2020PUMA新品发布会等多个国际大牌时尚活动',
            '曾在巴黎欧莱雅2021新品发布以脱口秀演员身份登台，天猫同步直播',
            '刊登《NYLON尼龙》内页，以KOL身份推荐秋季时尚男装',
            '微博热搜 #秋冬无脑穿搭# 阅读1.6亿；#你买过最不后悔的口红# 阅读1.4亿',
            '微博视频单篇最高视频播放量破千万次干货输出爆款视频均200-300万次播放',
            '护肤美妆ROI高 具备带货属性'
        ],
        images: [
            cdn('images/introduce/嘉島也柏/1.jpg'),
            cdn('images/introduce/嘉島也柏/2.jpg'),
            cdn('images/introduce/嘉島也柏/3.jpg'),
            cdn('images/introduce/嘉島也柏/4.jpg'),
            cdn('images/introduce/嘉島也柏/5.jpg')
        ]
    },
    {
        name: '@煎饼',
        id: null,
        tags: ['@@多才多艺，真正的时尚教主'],
        introduction: '@@京都大学情报研究科大学院硕士；工作中毒舌犀利，测评产品有理有据逻辑清晰；日常却温柔可爱，被粉丝戏称清纯教主本着「不精不诚 不能动人」原则 ，不服从任何物质利益和外在目的，输出内容真诚、负责，讨厌扮丑买弄；虽放弃时尚穿搭内容输出，但依旧因为自身的小众风格和极佳的时尚感牢牢抓住粉丝眼球',
        hotSpots: [
            '@@曾合作CPB、YSL、兰蔻、雅诗兰黛、毛戈平、资生堂、伊丽莎白雅顿等多个美妆品牌',
            '@@受邀参与AW21 MAC全球彩妆趋势发布会、PRADA MODE 、VALENTINO RE-SIGNIFY、2020PUMA新品发布会等多个国际大牌时尚活动'
        ],
        images: [
            cdn('images/introduce/煎饼/1.jpg'),
            cdn('images/introduce/煎饼/2.jpg'),
            cdn('images/introduce/煎饼/3.jpg'),
            cdn('images/introduce/煎饼/4.jpg'),
            cdn('images/introduce/煎饼/5.jpg')
        ]
    },
    {
        name: '@蒋橙橙KZ',
        id: 75,
        tags: ['微博时尚美妆博主', 'VLOG短视频达人'],
        introduction: '她以超高化妆技巧及护肤经验吸引粉丝；她用独到眼光、名品颜值，引领时尚风向标',
        hotSpots: [
            '曾合作LA MER、香奈儿、兰蔻、赫莲娜、Bobbi Brown等多个品牌',
            '微博爆款视频 #高圆圆周芷若仿妆# 播放量43w+，互动量4.4k+',
            'B站爆款视频 #和我一起剪短发# 播放量20.3w+,互动量4.7k+'
        ],
        images: [
            cdn('images/introduce/75/1.jpg'),
            cdn('images/introduce/75/2.jpg'),
            cdn('images/introduce/75/3.jpg'),
            cdn('images/introduce/75/4.jpg'),
            cdn('images/introduce/75/5.jpg')
        ]
    },
    {
        name: '@晶晶stunna',
        id: 85,
        tags: ['轻欧美风技术流美妆博主', '热辣风格穿搭达人'],
        introduction: '她是欧美风cool girl ，是美妆狂热爱好者；她是小个子，但是气场完全hold住T台的超模fashion girl ；她妆后可塑性强，因为品味的极佳时尚感深得粉丝喜爱',
        hotSpots: [
            '曾合作雅诗兰黛、欧莱雅、ZOZO、卡姿兰、珀莱雅等多个品牌',
            '微博爆款视频 #无眉星人画眉2.0# 播放量426w+，互动量6w+'
        ],
        images: [
            cdn('images/introduce/85/1.jpg'),
            cdn('images/introduce/85/2.jpg'),
            cdn('images/introduce/85/3.jpg'),
            cdn('images/introduce/85/4.jpg'),
            cdn('images/introduce/85/5.jpg')
        ]
    },
    {
        name: '@静静和Nancy_',
        id: null,
        tags: ['微胖姐妹花', '韩系色彩感穿搭/美妆宝藏博主'],
        introduction: '她们是拥有超精致五官的140斤韩系姐妹花；微胖版本的衣架子，ins风闺蜜穿搭；超会穿的同时也是行走的美妆护肤小百科，种草力max',
        hotSpots: [
            '曾合作雅诗兰黛、OLAY、珀莱雅、爱茉莉、乐町、太平鸟等多个品牌',
            '微博爆款案例 #微胖闺蜜穿搭# 阅读量4475w+，互动量6.8k+'
        ],
        images: [
            cdn('images/introduce/静静和Nancy_/1.jpg'),
            cdn('images/introduce/静静和Nancy_/2.jpg'),
            cdn('images/introduce/静静和Nancy_/3.jpg'),
            cdn('images/introduce/静静和Nancy_/4.jpg'),
            cdn('images/introduce/静静和Nancy_/5.jpg')
        ]
    },
    {
        name: '@就是呜',
        id: null,
        tags: ['法式浪漫穿搭博主', '精致生活家'],
        introduction: '@@她们是拥有超精致五官的140斤韩系姐妹花；微胖版本的衣架子，ins风闺蜜穿搭；超会穿的同时也是行走的美妆护肤小百科，种草力max',
        hotSpots: [
            '@@曾合作雅诗兰黛、OLAY、珀莱雅、爱茉莉、乐町、太平鸟等多个品牌',
            '@@微博爆款案例 #微胖闺蜜穿搭# 阅读量4475w+，互动量6.8k+'
        ],
        images: [
            cdn('images/introduce/就是呜/1.jpg'),
            cdn('images/introduce/就是呜/2.jpg'),
            cdn('images/introduce/就是呜/3.jpg'),
            cdn('images/introduce/就是呜/4.jpg'),
            cdn('images/introduce/就是呜/5.jpg')
        ]
    },
    {
        name: '@卡卡会发光guang',
        id: null,
        tags: ['时尚生活美妆类全能博主', '接地气小个子全能博主', '阳光灿烂美少女'],
        introduction: '她是时尚美妆生活类全能型博主；穿搭、卷发、花式戴帽子法样样精通；她是个性鲜明的搞怪段子手，绝对的搞笑担当',
        hotSpots: [
            '曾合作兰蔻、爱敬、UNNY、韩后、网易严选等多品牌',
            '女性粉丝占比超96%，Z世代粉丝占比超76%',
            '微博爆款视频 #干货放松——减肥攻略# 阅读量244w+，互动量5.3k+'
        ],
        images: [
            cdn('images/introduce/卡卡会发光guang/1.jpg'),
            cdn('images/introduce/卡卡会发光guang/2.jpg'),
            cdn('images/introduce/卡卡会发光guang/3.jpg'),
            cdn('images/introduce/卡卡会发光guang/4.jpg'),
            cdn('images/introduce/卡卡会发光guang/5.jpg')
        ]
    },
    {
        name: '@可爱炸炸-',
        id: null,
        tags: ['鬼马甜酷穿搭精灵', '色彩运用小天才'],
        introduction: '她是超懂玩色的时尚精灵；甜酷时尚的穿搭博主；可爱炸了是她的态度风格',
        hotSpots: [
            '曾合作LA MER、YSL、雅诗兰黛、科颜氏、欧莱雅等品牌',
            '微博爆款 #小皮鞋分享# 阅读量105w+，互动量2w+',
            '小红书爆款 #冬季穿搭小心机# 阅读量71.5w+，互动量3.5w+'
        ],
        images: [
            cdn('images/introduce/可爱炸炸-/1.jpg'),
            cdn('images/introduce/可爱炸炸-/2.jpg'),
            cdn('images/introduce/可爱炸炸-/3.jpg'),
            cdn('images/introduce/可爱炸炸-/4.jpg'),
            cdn('images/introduce/可爱炸炸-/5.jpg')
        ]
    },
    {
        name: '@李璐Gemma',
        id: 31,
        tags: ['10年抗痘经验背书的成分党博主', '轻欧美系美妆时尚达人'],
        introduction: '她是有着十年痘肌护肤经验的成分党博主；她自带欧美气质还拥有高超的化妆技巧，擅长模仿欧美妆容；她热爱生活，对家居布置有独到见解，也是拥有7只猫的养猫选手',
        hotSpots: [
            '曾合作LA MER、阿玛尼、娇兰、雅诗兰黛、卡诗、修丽可等多个大牌',
            '微博爆款视频 #如何保持头发蓬松# 播放量460w+'
        ],
        images: [
            cdn('images/introduce/31/1.jpg'),
            cdn('images/introduce/31/2.jpg'),
            cdn('images/introduce/31/3.jpg'),
            cdn('images/introduce/31/4.jpg'),
            cdn('images/introduce/31/5.jpg')
        ]
    },
    {
        name: '@莉贝琳',
        id: 1,
        tags: ['休闲风干货分享型穿搭达人', '口播感染力满满的生活分享博主'],
        introduction: '她是37岁想要挑战更年轻穿搭的博主；显瘦穿法、配饰细节，她有一个单品拯救一套穿搭的魔法，各种风格、所有季节，没有她解决不了的场景搭配；10年店主经验，多年穿搭干货持续输出纠正普通人穿搭的常见误区',
        hotSpots: [
            '小红书3个月累计暴涨60万粉',
            '获2021年6月小红书红人影响力榜Top3',
            '小红书爆款视频 #穿搭干货：少女和阿姨的区别# 单篇阅读量712.7w+，互动量29.5w+'
        ],
        images: [
            cdn('images/introduce/1/1.jpg'),
            cdn('images/introduce/1/2.jpg'),
            cdn('images/introduce/1/3.jpg'),
            cdn('images/introduce/1/4.jpg'),
            cdn('images/introduce/1/5.jpg')
        ]
    },
    {
        name: '@良潇_',
        id: 40,
        tags: ['高级感时尚博主', '生活vlog分享达人', '健身属性', '专业彩妆学习经验背书'],
        introduction: '她是担任过彩妆师的专业女王，妆教属性拉满；是高级精致的时尚女王，频频受邀参与各种大牌线下展；也是两人一狗的生活家，质感生活打动粉丝',
        hotSpots: [
            '曾合作TOM FORD、阿玛尼、兰蔻、纪梵希、OLAY等多个美妆品牌',
            '受邀参与YSL、LV、梵克雅宝等多个大牌线下活动',
            '微博爆款视频 #无瑕底妆大法# 阅读量2.27亿+，播放量3853w+'
        ],
        images: [
            cdn('images/introduce/40/1.jpg'),
            cdn('images/introduce/40/2.jpg'),
            cdn('images/introduce/40/3.jpg'),
            cdn('images/introduce/40/4.jpg'),
            cdn('images/introduce/40/5.jpg')
        ]
    },
    {
        name: '@林零亿',
        id: 53,
        tags: ['精致微胖穿搭博主', '口播带货达人'],
        introduction: '她是会穿、会买的精致生活治愈型微胖博主；超级自信、热爱生活、热爱分享',
        hotSpots: ['曾合作Dior、飞利浦、GAP、wonderlap等品牌', '小红书爆款视频 #显瘦穿搭分享# 播放量100w+'],
        images: [
            cdn('images/introduce/53/1.jpg'),
            cdn('images/introduce/53/2.jpg'),
            cdn('images/introduce/53/3.jpg'),
            cdn('images/introduce/53/4.jpg'),
            cdn('images/introduce/53/5.jpg')
        ]
    },
    {
        name: '@刘梓萌嗎',
        id: 77,
        tags: ['欧美甜酷辣妹', '时尚美妆', '医美达人'],
        introduction: '她是酷飒时尚，不被定义的欧美潮酷辣妹；她是风格多变的美妆时尚达人，vlog博主',
        hotSpots: ['合作LA MER、阿玛尼、小奥汀、欧莱雅、医美等'],
        images: [
            cdn('images/introduce/77/1.jpg'),
            cdn('images/introduce/77/2.jpg'),
            cdn('images/introduce/77/3.jpg'),
            cdn('images/introduce/77/4.jpg'),
            cdn('images/introduce/77/5.jpg')
        ]
    },
    {
        name: '@吕根lg',
        id: 71,
        tags: ['痞帅的阳光大男孩', '时尚穿搭的教科书', '生活分享型博'],
        introduction: '他是穿搭至上的时尚潮人，热衷穿出自我品味；他是认真生活的独居男孩，钟爱生活好物分享；用镜头表达属于自己的味道，做一个简单的快乐分享仔',
        hotSpots: ['合作过香奈儿、迪奥、Tom Ford、Tiffany、纪梵希等国际大牌', '小红书爆款合作案例 #球场拍照# 阅读量32.4w+'],
        images: [
            cdn('images/introduce/71/1.jpg'),
            cdn('images/introduce/71/2.jpg'),
            cdn('images/introduce/71/3.jpg'),
            cdn('images/introduce/71/4.jpg'),
            cdn('images/introduce/71/5.jpg')
        ]
    },
    {
        name: '@麦麦麦里',
        id: null,
        tags: ['野生浓颜美妆博主', '混血御姐范'],
        introduction: '她是浓颜轻欧美风美妆博主，用妆容saly全场；她是御姐范高颜值达人，没人能不为她的惊艳亮相点赞；分享每一个妆容小技巧，和更多辣妹一起变美',
        hotSpots: ['曾合作TOM FORD、BOBBI BROWN、娇兰、欧莱雅等多个美妆品牌'],
        images: [
            cdn('images/introduce/麦麦麦里/1.jpg'),
            cdn('images/introduce/麦麦麦里/2.jpg'),
            cdn('images/introduce/麦麦麦里/3.jpg'),
            cdn('images/introduce/麦麦麦里/4.jpg'),
            cdn('images/introduce/麦麦麦里/5.jpg')
        ]
    },
    {
        name: '@麦麦薯',
        id: 91,
        tags: ['痘肌美妆博主', '音乐系毕业的韩系清新女孩'],
        introduction: '她是落落大方的标准美女，真实记录生活的每一份快乐；她是韩系本韩清新本清，镜头感十足甜美自然；分享真实生活点点滴滴，希望带来纯粹的美好',
        hotSpots: ['曾合作TOM FORD、DIOR、雅诗兰黛、NARS、兰蔻等多个美妆品牌', '小红书爆文 #整容级鼻影教程# 阅读量44.6w+'],
        images: [
            cdn('images/introduce/91/1.jpg'),
            cdn('images/introduce/91/2.jpg'),
            cdn('images/introduce/91/3.jpg'),
            cdn('images/introduce/91/4.jpg'),
            cdn('images/introduce/91/5.jpg')
        ]
    },
    {
        name: '@满杯谌谌',
        id: 83,
        tags: ['初恋脸甜酷女孩', '小个子穿搭达人', '写真分享元祖博主'],
        introduction: '她是爱分享探店、写真、小个子甜酷穿搭的00后博主；她是长相甜美清纯的JK少女，喜欢一切少女心爆棚的东西',
        hotSpots: [
            '曾合作祖玛珑、赫莲娜、DIOR、雅诗兰黛、OPPO、New Balance等多品类品牌',
            '小红书爆款视频 #刘海打理教程# 阅读量40w+'
        ],
        images: [
            cdn('images/introduce/83/1.jpg'),
            cdn('images/introduce/83/2.jpg'),
            cdn('images/introduce/83/3.jpg'),
            cdn('images/introduce/83/4.jpg'),
            cdn('images/introduce/83/5.jpg')
        ]
    },
    {
        name: '@满满Cyim',
        id: 15,
        tags: ['韩风甜美系穿搭达人', '专业彩妆学习经验的时尚博主'],
        introduction: '她是正能量满满的活力少女，热爱分享韩系穿搭和美妆好物，用镜头记录自己的故事，携手粉丝一起走花路；她是模特/美妆达人/淘宝店主/摄影基地老板娘/甜蜜情侣多身份切换的美女博主',
        hotSpots: [
            '曾合作LA MER、赫莲娜、资生堂、兰蔻、天猫国际、修丽可等多个品牌',
            '受邀参与#ELLE×天猫风尚大选#、植村秀线下活动、潮人盛典',
            '微博爆款视频 #圣诞冰雪公主妆# 播放量107w+'
        ],
        images: [
            cdn('images/introduce/15/1.jpg'),
            cdn('images/introduce/15/2.jpg'),
            cdn('images/introduce/15/3.jpg'),
            cdn('images/introduce/15/4.jpg'),
            cdn('images/introduce/15/5.jpg')
        ]
    },
    {
        name: '@毛毛虫的毛哥',
        id: null,
        tags: ['@@韩风甜美系穿搭达人'],
        introduction: '@@她是正能量满满的活力少女，热爱分享韩系穿搭和美妆好物，用镜头记录自己的故事，携手粉丝一起走花路；她是模特/美妆达人/淘宝店主/摄影基地老板娘/甜蜜情侣多身份切换的美女博主',
        hotSpots: ['@@曾合作LA MER、赫莲娜、资生堂、兰蔻、天猫国际、修丽可等多个品牌'],
        images: [
            cdn('images/introduce/毛毛虫的毛哥/1.jpg'),
            cdn('images/introduce/毛毛虫的毛哥/2.jpg'),
            cdn('images/introduce/毛毛虫的毛哥/3.jpg'),
            cdn('images/introduce/毛毛虫的毛哥/4.jpg'),
            cdn('images/introduce/毛毛虫的毛哥/5.jpg')
        ]
    },
    {
        name: '@每文ReRe',
        id: null,
        tags: ['Internet girl', '暗黑个性风技术流彩妆博主'],
        introduction: '她是极具个人风格的亚气拽姐变妆就是变脸，是美妆技术一流的美术系女大学生',
        hotSpots: ['曾合作TOM FORD、BOBBI BROWN、NARS等多个知名美妆品牌'],
        images: [
            cdn('images/introduce/每文ReRe/1.jpg'),
            cdn('images/introduce/每文ReRe/2.jpg'),
            cdn('images/introduce/每文ReRe/3.jpg'),
            cdn('images/introduce/每文ReRe/4.jpg'),
            cdn('images/introduce/每文ReRe/5.jpg')
        ]
    },
    {
        name: '@美希Mazing',
        id: 56,
        tags: ['配音演员级美妆妆教大佬', '护肤干货专家', '微胖时尚穿搭达人'],
        introduction: '她是可爱又有趣的美妆妆教大佬；她是抖音耀眼的新星，原相机美貌两次登上热点榜；她是B站资深精致lo娘UP主',
        hotSpots: [
            '曾合作雅诗兰黛、小奥汀、娇韵诗、橘朵、谷雨、美的等做个品牌',
            'B站爆款视频 #140斤显瘦羽绒服穿搭分享# 播放量76w+',
            '一周暴涨10w粉丝。#爱国穿搭#，#原相机美貌#内容两次登上热点榜'
        ],
        images: [
            cdn('images/introduce/56/1.jpg'),
            cdn('images/introduce/56/2.jpg'),
            cdn('images/introduce/56/3.jpg'),
            cdn('images/introduce/56/4.jpg'),
            cdn('images/introduce/56/5.jpg')
        ]
    },
    {
        name: '@萌丫丫萌萌哒',
        id: null,
        tags: ['良心测评学术派毒舌博主', '大女主三观输出', '本科华工港城硕士', '10年抗痘史，3年整容史'],
        introduction: '她是医学美容生活美容两手抓两手都要硬的知识博主，谁说好看的皮囊不能拥有智慧的灵魂，谁说拒绝消费陷阱就要活得单一乏味；“永远相信女性力量，永远帮助女性”是她的人生信条；“女孩子是最美好的生物”是她想要表达的价值观',
        hotSpots: ['曾合作娇韵诗、修丽可、雅诗兰黛、雅丽莎白·雅顿、赫莲娜等品牌'],
        images: [
            cdn('images/introduce/萌丫丫萌萌哒/1.jpg'),
            cdn('images/introduce/萌丫丫萌萌哒/2.jpg'),
            cdn('images/introduce/萌丫丫萌萌哒/3.jpg'),
            cdn('images/introduce/萌丫丫萌萌哒/4.jpg'),
            cdn('images/introduce/萌丫丫萌萌哒/5.jpg')
        ]
    },
    {
        name: '@莫尼monika',
        id: null,
        tags: ['欧洲留学时尚辣妹', '中国卡戴珊本珊'],
        introduction: '她是欧美风美妆穿搭博主；她是口播一流、风格独特的时尚达人；她被称作中国的卡戴珊',
        hotSpots: [
            '曾合作兰蔻、Usmile、Dr.jart+、Koress、别样等品牌',
            '粉丝群体57%是本科及以上学历，经济能力较高',
            '微博爆款案例 #车厘子妆容，美颜暴击# 阅读量103w+'
        ],
        images: [
            cdn('images/introduce/莫尼monika/1.jpg'),
            cdn('images/introduce/莫尼monika/2.jpg'),
            cdn('images/introduce/莫尼monika/3.jpg'),
            cdn('images/introduce/莫尼monika/4.jpg'),
            cdn('images/introduce/莫尼monika/5.jpg')
        ]
    },
    {
        name: '@木村由佳',
        id: null,
        tags: ['美妆时尚达人'],
        introduction: '@@她是欧美风美妆穿搭博主；她是口播一流、风格独特的时尚达人；她被称作中国的卡戴珊',
        hotSpots: ['@@曾合作兰蔻、Usmile、Dr.jart+、Koress、别样等品牌'],
        images: [
            cdn('images/introduce/木村由佳/1.jpg'),
            cdn('images/introduce/木村由佳/2.jpg'),
            cdn('images/introduce/木村由佳/3.jpg'),
            cdn('images/introduce/木村由佳/4.jpg'),
            cdn('images/introduce/木村由佳/5.jpg')
        ]
    },
    {
        name: '@贝儿Belinda',
        id: 93,
        tags: ['@@美妆时尚达人'],
        introduction: '@@她是欧美风美妆穿搭博主；她是口播一流、风格独特的时尚达人；她被称作中国的卡戴珊',
        hotSpots: ['@@曾合作兰蔻、Usmile、Dr.jart+、Koress、别样等品牌'],
        images: [
            cdn('images/introduce/93/1.jpg'),
            cdn('images/introduce/93/2.jpg'),
            cdn('images/introduce/93/3.jpg'),
            cdn('images/introduce/93/4.jpg'),
            cdn('images/introduce/93/5.jpg')
        ]
    },
    {
        name: '@曾永仪',
        id: 94,
        tags: ['时尚', '穿搭'],
        introduction: '时尚调性高，表现力好，出片率高，配合度高。',
        images: [
            cdn('images/introduce/94/1.jpg'),
            cdn('images/introduce/94/2.jpg'),
            cdn('images/introduce/94/3.jpg'),
            cdn('images/introduce/94/4.jpg'),
            cdn('images/introduce/94/5.jpg')
        ]
    },
    {
        name: '@笑笑同学啊',
        id: 95,
        tags: ['美妆', '护肤（成分）', '情侣'],
        introduction: '爆款频出的美妆时尚博主，超强种草力，擅长“化妆换头”。',
        images: [
            cdn('images/introduce/95/1.jpg'),
            cdn('images/introduce/95/2.jpg'),
            cdn('images/introduce/95/3.jpg'),
            cdn('images/introduce/95/4.jpg'),
            cdn('images/introduce/95/5.jpg')
        ]
    },
    {
        name: '@ug你大哥',
        id: 96,
        tags: ['美妆'],
        introduction: '干货满满的美妆“换头”博主，整容级妆教一绝。',
        images: [
            cdn('images/introduce/96/1.jpg'),
            cdn('images/introduce/96/2.jpg'),
            cdn('images/introduce/96/3.jpg'),
            cdn('images/introduce/96/4.jpg'),
            cdn('images/introduce/96/5.jpg')
        ]
    },
    {
        name: '@半途鹅飞',
        id: 97,
        tags: ['好物分享'],
        introduction: '美妆、护肤、穿搭、颜值、学习、发型全面开花；数据又好又稳定；不拖稿，配合度超高。',
        images: [
            cdn('images/introduce/97/1.jpg'),
            cdn('images/introduce/97/2.jpg'),
            cdn('images/introduce/97/3.jpg'),
            cdn('images/introduce/97/4.jpg'),
            cdn('images/introduce/97/5.jpg')
        ]
    },
    {
        name: '@張人0',
        id: 98,
        tags: ['穿搭', '好物分享', '颜值', 'vlog'],
        introduction: '高颜值氛围感帅哥；爆款话题制造者，万赞达人；氛围感制造者。',
        images: [
            cdn('images/introduce/98/1.jpg'),
            cdn('images/introduce/98/2.jpg'),
            cdn('images/introduce/98/3.jpg'),
            cdn('images/introduce/98/4.jpg'),
            cdn('images/introduce/98/5.jpg')
        ]
    },
    {
        name: '@Babeei张张',
        id: 99,
        tags: ['穿搭', '好物分享', '时尚', '美妆'],
        introduction: '风格突出，粉丝黏性高，很会穿的全能好物种草机。',
        images: [
            cdn('images/introduce/99/1.jpg'),
            cdn('images/introduce/99/2.jpg'),
            cdn('images/introduce/99/3.jpg'),
            cdn('images/introduce/99/4.jpg'),
            cdn('images/introduce/99/5.jpg')
        ]
    },
    {
        name: '@兔染染Summer',
        id: 100,
        tags: ['干货分享', '学生', '美妆', '护肤'],
        introduction: '变美百科全书、美白爆文制造机、韩国梨大学生、氛围感美女。',
        images: [
            cdn('images/introduce/100/1.jpg'),
            cdn('images/introduce/100/2.jpg'),
            cdn('images/introduce/100/3.jpg'),
            cdn('images/introduce/100/4.jpg'),
            cdn('images/introduce/100/5.jpg')
        ]
    },
];