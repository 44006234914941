import React,{ Fragment } from 'react';

function PlacePart({ children, className, visible = false }) {
  if (visible) {
    if(className){
    return (
        <div className={className}>
            <Fragment>
            {children}
            </Fragment>
        </div>
        );
    }
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
  return <></>;
}

export default PlacePart;
