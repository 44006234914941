
import useAssets from '../../../../common/useAssets';
import React,{Component} from "react";
import Slider from "react-slick";
import Title from "../../starResources/title"
const cdn = useAssets(false);

const brandList = new Array(60).fill(1).map((i, index) => ({index: index + 321}));

class brand extends Component{
    state = {
        list: chunk(brandList, 5),
    }

    render() {
        const {slider} = this.props;
        const {list = []} = this.state;

        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            draggable: false,
            swipeToSlide: true,
            speed: 1500,
            autoplaySpeed: 1500,
            pauseOnHover: false,
            variableWidth: false,
            cssEase: "linear"
        };

        return (
            <div>
                {slider ? (
                    <>
                        <Title title="合作品牌"/>
                        <div className='brand-list' style={{marginTop: 30}}>
                            <Slider {...settings}>
                                {list.map((item = [], index) => (
                                    <div key={index} className='brand-item'>
                                        {item.map(j => (
                                            <div key={j.index} className='brand-section'>
                                                <img alt="品牌" src={cdn(`images/onlineRetailers/brand/group-${j.index}.png`, 300)} />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </>
                ) : (
                    <div className='brand-list'>
                        <img alt="品牌" src={cdn('images/onlineRetailers/brand.png', 1000)} style={{marginTop: '34px'}}/>
                    </div>
                )}
            </div>
        )
    }
}
export default brand;


function chunk(arr, size) {
    const arr2=[];
    for(let i = 0; i < arr.length; i= i + size){
        arr2.push(arr.slice(i,i+size));
    }
    return arr2;
}