import React, { Component,Fragment } from 'react';
import Header from '../layout/header/index';
import Footer from '../layout/footer';


 

class Error404 extends Component {


    render() {
        return (
             <Fragment>
                <Header />

404

                <Footer />

             </Fragment>
        )
    }
}
export default Error404;