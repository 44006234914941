import React,{ Component, Fragment } from 'react';
import Title from './title';
import Number from './number';
class describe extends Component{
    render(){
        const {describe} = this.props
        return (<Fragment>
            <div>
                <Title title={describe.title}/>
            </div>
            <div className='content' style={{lineHeight: '37px'}}>
                {describe.content}
            </div>
            <div className='data'>
                {
                    describe.honor.map((item, index) => (
                        <div key={index}>
                            <div>
                                <Number data={item.data} time={item.time} step={item.step}/>
                                <span className='unit'>{item.unit}</span>
                            </div>
                            <div className='subTitle' style={{marginTop: 5}}>{item.title}</div>
                        </div>
                    ))
                }
            </div>
        </Fragment>)
    }
}

export default describe;