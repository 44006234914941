import React, { Component, Fragment } from 'react';
import './index.css';
import PlacePart from '../../../../layout/placePart';
import { column1, column2, column3, column4 } from './data';

class Waterfall extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
    }
    state = {
        limit: 3,
        offset: 0,
        total: 10,
    }
    onChange() {
        const { limit, total } = this.state;
        if (limit < total) {
            this.setState({
                limit: limit + 2
            });
        }
    }
   render() {
    const { limit, offset } = this.state;
        return (
            <Fragment>
                        <div className="coBrandingFlow " >
                            <div className="flowline" >
                                {column1.slice(offset, limit).map(e =>
                                (<div className="flowItem" >
                                    <img alt={e.title} src={e.src} />
                                    <label>{e.title}</label>
                                    <p>{e.description}</p>
                                </div>)
                                )}
                            </div>
                            <div className="flowline">
                                {column2.slice(offset, limit).map(e =>
                                (<div className="flowItem" >
                                    <img alt={e.title} src={e.src} />
                                    <label>{e.title}</label>
                                    <p>{e.description}</p>
                                </div>)
                                )}
                            </div>
                            <div className="flowline">
                                {column3.slice(offset, limit).map(e =>
                                (<div className="flowItem" >
                                    <img alt={e.title} src={e.src} />
                                    <label>{e.title}</label>
                                    <p>{e.description}</p>
                                </div>)
                                )}
                            </div>
                            <div className="flowline">
                                {column4.slice(offset, limit).map(e =>
                                (<div className="flowItem" >
                                    <img alt={e.title} src={e.src} />
                                    <label>{e.title}</label>
                                    <p>{e.description}</p>
                                </div>)
                                )}
                            </div>
                        </div>
                        <PlacePart visible={limit < 10}>
                            <div className="coBrandingMore" onClick={this.onChange}  >查看更多</div>
                        </PlacePart>
            </Fragment>
        )
    }
}
export default Waterfall;