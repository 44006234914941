import React,{Component} from "react";
import Title from "../../starResources/title"
class plan extends Component{
    render() {
        const {title, describe, list} = this.props;
        return (
            <div className='online-plan'>
                <div className="plan-describe">{describe}</div>
                <div className="tags">
                    {
                        list.map((item,index) => (
                            <div key={index} className="online-tag">
                                <div className='online-title'>{item.type}</div>
                                <div className='online-name'>{item.name}</div>
                                <div className='online-describe'>{item.describe}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}
export default plan;