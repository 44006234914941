import useAssets from '../../../../../common/useAssets';
const cdn = useAssets(false);
let arrayList = []
for (let i = 1; i < 10; i++) {
    if (i !== 4 && i !== 9) {
        arrayList.push(cdn(`images/marketing/avatar-${i}.png`))
    }
}
console.log(arrayList, 'arrayList');

export const avatarList = [
    cdn(`images/marketing/avatar-1.png`),
    cdn(`images/marketing/avatar-2.png`),
    cdn(`images/marketing/avatar-3.png`),
    cdn(`images/marketing/avatar-5.png`),
    cdn(`images/marketing/avatar-06.png`),
    cdn(`images/marketing/avatar-07.png`),
    cdn(`images/marketing/avatar-08.png`),
]
