import React, { Component,Fragment } from 'react';
import './index.css';
import classNames from 'classnames';

class BackToTop extends Component {

    state = {
        is_visible: false,
        hideColor: false,
    };

    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", (e) => {
            scrollComponent.toggleVisibility();
            const toBottom = document.querySelector('.App').clientHeight - window.scrollY - window.innerHeight;

            if (toBottom < 444) {
                 this.setState({
                     hideColor: true,
                 })
            } else {
                this.setState({
                    hideColor: false,
                })
            }
        });
    }

    toggleVisibility() {
        if (global['window'].pageYOffset > 300) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    scrollToTop() {
        global['window'].scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const { is_visible, hideColor } = this.state;
        return (
             <Fragment>
                    {is_visible && (
                        <div className={classNames('backtotop', {hideColor})} onClick={() => this.scrollToTop()} />
                    )}
             </Fragment>
        )
    }
}
export default BackToTop;