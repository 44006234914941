import React, { Component, Fragment } from 'react';
import {Union} from "../../pages/advertising/data";

class ContentItem extends Component {
     render(){
        const { name = "", children } = this.props;
         return (<Fragment>
            <div className="contentTitle_by" id={name}>
                <div className="contentTitle_byb">
                    <p className="contentTitle_byt" style={{ margin: '10px 0 10px 0' }}>{name}</p>
                    <p style={{ top: '48px', width: '68px' }} className="underline"></p>
                    <Fragment>{children}</Fragment>
                </div>
            </div>
    </Fragment>)
    }
}

export default ContentItem;