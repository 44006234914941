/* eslint-disable no-undef */
import React, { Component, Fragment } from 'react';
import Slider from "react-slick";
import Header from '../../layout/header/index';
import Footer from '../../layout/footer';
import Banner from '../../layout/banner';
import QrCode from '../../layout/qrCode';
import ContentTitle from '../../layout/contentTitle';
import ContentItem from '../../element/contentItem';
import Gallery from './element/gallery';
import Avatar from './element/avatar';
import Contact from './element/contact';
import './index.css';
import Swiper from '../../element/swiper';
import { banner,cast1,cast2,cast3,cast4,cast5,cast6,cast7,cast8,cast9,cast10,cast11,cast12,
    anchor,douin,redBook, weibo, bilibili, kuaishou, cooperationMode,logoList,showAnchor,arrow,enter, tabsList } from './data';
import useAssets from "../../../common/useAssets";
import {otherKol} from "../starResources/data";
import {Subtract, Union} from "../advertising/data";

const cdn = useAssets(false);

const type = {
    '微博': weibo,
    '抖音': douin,
    'bilibili': bilibili,
    '快手': kuaishou,
    '小红书': redBook,
}

const otherName = {
    '@无糖豆豆xy': '小恶魔',
    '@彩妆师幽幽': '卷毛的幽幽',
}

class Marketing extends Component {

    constructor(props){
        super(props);
        this.state={
            imgUrl:"",
            data: {},
            effect: [],
        };
        // this.changeImgUrl = this.changeImgUrl.bind(this);
        this.changeImgUrl = this.changeImgUrl.bind(this);

    }

    componentDidMount() {
        document.title = '如涵直播业务-如涵官网';
        this.changeImgUrl('', 3)
    }


    changeImgUrl=(imgSrc, index)=>{
        this.handleFetch(otherName[tabsList[index].name] || tabsList[index].name || '@王宛尘');
        this.setState({imgUrl:imgSrc, data: tabsList[index]},()=>{console.log(this.state.imgUrl)});
    }

    // 获取数据
    handleFetch(name) {
        if (!name) return;
        const param = {simpleNameLike: otherKol[name] || name.replace('@', '')};
        fetch(`https://www.layercake18.com/drp/data/mall/common/kol/fans?simpleNameLike=${param.simpleNameLike}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        })
            .then(response => response.json())
            .then(res => {
                if (!res.success) return;
                const index = name.indexOf('大姨夫');
                let {channelList = []} = res.result[0] || {};
                if (index > -1) {
                    channelList = channelList.filter(i => ![5,9].includes(i.channelId));
                }
                if (channelList) {
                    this.setState({
                        effect: channelList.map(i => ({
                            type: type[i.channelName],
                            number: mun(i.fans, 0),
                            indexUrl: i.indexUrl,
                        })),
                    })
                }
            });
    }

    render() {
        const {imgUrl, data = {}, effect = []} = this.state;
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            variableWidth: true
        };
        return (
            <Fragment>
                <div className="marketing flexccc" >
                    <Header bg={{background: '#333333'}} />
                    <div className='nav-head-img'>
                        <Banner imgurl={banner} />
                        <div className='nav-head-text'>
                            <img alt="arrow" src={cdn(`images/resources/banner/banner-text.png`)} />
                        </div>
                    </div>

                    <ContentTitle content={['优质案例', '全平台主播', '合作方式', '品牌资源']}></ContentTitle>
                    <ContentItem name="优质案例" >
                        <img className="Union" src={Union} alt="圈" />
                        <div style={{marginBottom:'6px', marginLeft: 60}} className="flexrbs caseInfo">
                            <Slider {...settings}>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast1}  alt="精致大姨夫×小红书国货节" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">
                                            精致大姨夫×小红书国货节
                                        </label>
                                        <p className='ft16'>资深成分裆精致大姨夫小红书国货节，是小红书首档国货定制节日，拥有平台S级推荐和资深党央媒合作背书，助力国货品牌逐步打爆声量，建立品牌影响力。</p>
                                    </div>
                                </div>
                                {/* <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast2}  alt="葡萄-Lee×奥运冠军陈一冰"  /></div>
                                        <label className="ft20 font-family-sc">
                                            葡萄-Lee×奥运冠军陈一冰
                                        </label>
                                        <p className='ft16'>7月19日葡萄妈与奥运冠军陈一冰跨界直播，总GMV超1000万元，累计观看人数超360w，微博平台话题累计3714w，登上微博热榜TOP15</p>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast3}  alt="晁然 x 天问一号火星计划" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">
                                            晁然 x 天问一号火星计划
                                        </label>
                                        <p className='ft16'>中国航天局邀请晁然担任“中国航天文创推荐官”。晁然与中国航天天空创想联名定制公益款“太空创想”卫衣，并进行直播，总GMV超600万元。</p>
                                    </div>
                                </div> */}
                                {/*<div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast4}  alt="宝剑嫂×露薇娜联名化妆刷" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">宝剑嫂×露薇娜联名化妆刷</label>
                                        <p className='ft16'>宝剑嫂和化妆刷品牌露薇娜深度合作，推出联名款化妆刷，一分钟销量破6000套，30分钟10000套售空，双十二当天GMV118w＋，相关视频播放量145w＋。</p>
                                    </div>
                                </div>*/}
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast5}  alt="宝剑嫂×露薇娜联名化妆刷" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">小李朝×杭州市西湖区风景名胜区</label>
                                        <p className='ft16'>8月22日，杭州市西湖区风景名胜区在七夕开展“国风七夕心动直播 云购西湖美食好物”活动，邀请小李朝进行线下直播。小李朝发布预热内容积极响应该活动，并在线下举办抖音直播活动，总曝光量一亿＋。</p>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast6}  alt="精致大姨夫×毕生之研直播小专场" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">精致大姨夫×毕生之研直播小专场</label>
                                        <p className='ft16'>精致大姨夫和毕生之研直播深度合作，在双十一期间展开品牌小专场直播，打造私域闭环，小红书直播小而美。毕生之研品牌创始人亲临直播间，给粉丝带来年终重磅新品CA瓶，以及多个线上线下福利活动。“笔记+直播+私域”，超50min的直播停留，超高的商品成交率和复购率，极强的品效合一。</p>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast7}  alt="抖音女装直播爆品榜单" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">抖音女装直播爆品榜单</label>
                                        <p className='ft16'>总GMV：6000W+；成交笔数：40W+；成交ROI：1比4以上</p>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast8}  alt="周嘎嘎儿" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">周嘎嘎儿</label>
                                        <p className='ft16'>
                                            30天冲牙器单品挂车gmv破10w<br />
                                            户外直播牛仔外套累计销量破2000件<br />
                                            防晒衣挂车单品gmv破10w</p>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast9}  alt="小李朝ye &小李朝的日记" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">小李朝ye &小李朝的日记</label>
                                        <p className='ft16'>21cm大鸭脖双月gmv破2000w 商品曝光量超4亿</p>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast10}  alt="小李朝ye &小李朝的日记" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">奇怪的奇</label>
                                        <p className='ft16'>2.5w粉丝 单场销售额破10w</p>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast11}  alt="无糖豆豆xy" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">无糖豆豆xy</label>
                                        <p className='ft16'>3k粉丝 单视频销售额90w+</p>
                                    </div>
                                </div>
                                <div>
                                    <div className=" flexcss caseInfoItem">
                                        <div className="img" ><img src={cast12}  alt="彩妆师幽幽" style={{ marginRight:'23px'}} /></div>
                                        <label className="ft20 font-family-sc">彩妆师幽幽</label>
                                        <p className='ft16'>0粉起号 单场销售额破10w 在线人数破1w+</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </ContentItem>
                    <ContentItem name="全平台主播" >
                                <div className="anchor flexrss" >
                                    <div className="header" ><img src={data.avatarUrl}  alt="全平台主播"  /></div>
                                    <div className=" flexcss" style={{flex: 1}}>
                                        <div className="anchorTitle" >{data.name}</div>
                                       <div className=" anchorTxt" >{data.title}</div>
                                        <div className=" anchorInfo">{data.content}</div>
                                         <div className=" flexrss count">
                                             {effect.map(item => (
                                                 <a href={item.indexUrl} target='_blank'>
                                                     <div className=" flexrss flexrss-count" style={{marginRight: 24}}>
                                                         <img style={{marginRight: 10}}  src={item.type} alt={item.alt}  />
                                                         <span className='label-num'>{item.number}</span>
                                                     </div>
                                                 </a>
                                             ))}
                                        </div>
                                    </div>
                                </div>
                            <Avatar changeImgUrl={this.changeImgUrl}/>
                    </ContentItem>
                    <ContentItem name="合作方式" >
                        <img className="Subtract" src={Subtract} alt="圈" />
                        <div style={{ width: 1200, marginTop: '40px' }}  >
                                <Swiper hasTab={false} touchMove={false} list={cooperationMode}>
                                    {/*{cooperationMode.map((item, index) => (
                                        <div key={item.image} className='cooperation'>
                                            <div className='cooperation-item' style={{backgroundImage: `url("${item.image}")`}}>
123456
                                            </div>
                                        </div>
                                    ))}*/}
                                </Swiper>
                            </div>
                            <a className="marketingEnter" style={{ marginTop:'40px'}} href='https://live.kol18.com' target="_blank" >
                                <label className="hand">提报商品报名入口</label>
                                <div className='iconfont icon-slice slice' />
                            </a>
                    </ContentItem>
                    <ContentItem name="联系方式" >
                            <Contact />
                    </ContentItem>
                    <ContentItem name="品牌资源" >
                            <Gallery imgList ={logoList}/>
                    </ContentItem>
                    <Footer />
                    <QrCode />
                </div>
            </Fragment>
        )
    }
}
export default Marketing;


function mun(value, fixed = 2) {
    if (!value) return 0;
    if (value > 9999) {
        return (value / 10000).toFixed(fixed) + 'w';
    }
    return value;
}