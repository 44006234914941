import React, { Component } from 'react';
import "./index.css";
import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

const advertisingUrl = cdn('images/qrCode/code.png');

class qrCode extends Component {
  state = {
    showCode: false
  }

  render() {
    const { children, style = {}, codeUrl = advertisingUrl } = this.props
    const { showCode } = this.state
    return (
      <div className="qrCode"
        style={{ ...style }}
        ref={'fixedNode'}>
        {
          children ?  children  : (
              <>
                <div
                    className="img_btn"
                    onMouseOver={() => { this.setState({ showCode: true }) }}
                    onMouseLeave={() => { this.setState({ showCode: false }) }}
                >
                  <img src={cdn('images/qrCode/message-icon.png')} alt="按钮" />
                  <div className='img_btn_text'>联系商务</div>
                </div>

                {
                  showCode && <img
                      className="img_code"
                      src={codeUrl} alt="二维码" />
                }
              </>
          )
        }
      </div>
    );
  }
}

export default qrCode;