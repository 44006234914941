import React, {Component} from 'react';
import './index.css';
import classNames from 'classnames';
import Sticky from 'react-sticky-el';

function throttle(fn, delay) {
    var timer;
    return function () {
        var _this = this;
        var args = arguments;
        if (timer) {
            return;
        }
        timer = setTimeout(function () {
            fn.apply(_this, args);
            timer = null; // 在delay后执行完fn之后清空timer，此时timer为假，throttle触发可以进入计时器
        }, delay);
    };
}

class Footer extends Component {
    constructor() {
        super();
        this.state = {
            showNum: 0,
            oldNum: 0,
            followme: 'contentTitleNotFollowme',
        };

        this.anchor = throttle(this.anchor, 200)
    }

    componentDidMount() {
        document.addEventListener('scroll', this.anchor);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.anchor);
    }

    anchor = e => {
        if (this.loading) return;
        const {content} = this.props;
        let index = -1;
        const scrollTop = window.scrollY;
        for (let i = 0; i < content.length; i++) {
            const item = content[i];
            const dom = document.getElementById(item);
            if (dom && scrollTop + 400 >= dom.offsetTop) {
                index = i;
            }
        }
        if (index === -1) {
            index = 0
        }
        this.setState({showNum: index});
    };

    //
    // toggleFollowme() {
    //     if (global['window'].pageYOffset > 573) {
    //         this.setState({
    //             followme: 'contentTitleFollowme',
    //         });
    //     } else {
    //         this.setState({
    //             followme: 'contentTitleNotFollowme',
    //         });
    //     }
    // }

    scrollInto = ele => {
        const dom = document.getElementById(ele);
        if (dom) {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 1000);
            window.scroll({
                top: dom.offsetTop - 90,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    render() {
        const {showNum = 0, oldNum, followme} = this.state;
        const {content, showWidth = 1212} = this.props;
        return (
            <div style={{width: '100%'}}>
                <Sticky stickyStyle={{zIndex: 999}}>
                    <div className={followme}>
                        <div style={{backgroundColor: '#fff'}}>
                            <div
                                className="contentTitle flexrcc "
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '72px',
                                    justifyContent: 'flex-start',
                                    marginLeft: `calc(50% - ${showWidth / 2}px)`,
                                }}
                            >
                                {content.map((item, index) => (
                                    <div
                                        className={classNames('contentTitle_b', {
                                            active: showNum === index,
                                        })}
                                        key={index}
                                        onMouseOver={() => {
                                            this.setState({showNum: index});
                                        }}
                                        onMouseLeave={() => {
                                            this.setState({showNum: oldNum});
                                        }}
                                        onClick={() => {
                                            this.setState({showNum: index, oldNum: index});
                                            this.scrollInto(item);
                                        }}
                                    >
                                        <span>{item}</span>
                                        {showNum === index && <span className="underline"></span>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Sticky>
            </div>
        );
    }
}

export default Footer;
