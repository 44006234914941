import React, {Component} from 'react';
import {contentList} from './data';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

function SampleNextArrow(props) {
    const {className, style, onClick, onNext} = props;
    return <button className={className} style={{...style}} onClick={onNext} />;
}

function SamplePrevArrow(props) {
    const {className, style, onClick, onPre} = props;
    return <button className={className} style={{...style}} onClick={onPre} />;
}
class ContentSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
        };
    }

    render() {
        const {hasArrows = true} = this.props;
        const {current} = this.state;

        const settings = {
            className: 'slick-swiper',
            infinite: true,
            arrows: hasArrows,
            centerPadding: '60px',
            slidesToScroll: 1,
            swipeToSlide: true,
            variableWidth: true,
            afterChange: index => {
                this.setState({current: index});
            },
            nextArrow: (
                <SampleNextArrow
                    onNext={() => {
                        this.slider.slickNext();
                    }}
                />
            ),
            prevArrow: (
                <SamplePrevArrow
                    onPre={() => {
                        this.slider.slickPrev();
                    }}
                />
            ),
        };

        // const col = Math.floor(csList.length / row)
        return (
            <div className="cs-contaienr">
                <Slider {...settings} ref={slider => (this.slider = slider)}>
                    {contentList.map((item, index) => {
                        return (
                            <div className="cs-item" key={item.id} style={{width: 1062}}>
                                <div className="cs-box">
                                    <img src={item.url} alt={item.title} />
                                    <div className="right">
                                        <h3>{`0${item.id}`}</h3>
                                        <h4>{item.title}</h4>
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
                {/*<ul className="cs-number">
                    <li>{current === 0 ? '01' : '·'}</li>
                    <li>{current === 1 ? '02' : '·'}</li>
                    <li>{current === 2 ? '03' : '·'}</li>
                </ul>*/}
            </div>
        );
    }
}

export default ContentSlide;
