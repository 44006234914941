/* eslint-disable no-useless-constructor */
import React, { Component, Fragment } from 'react';
import { avatarList } from './data';
import "./index.css";

class Gallery extends Component {
    
    constructor(props) {
        super(props)
       
    }

    render() {
        const imgList = avatarList
        return (
            <Fragment>
                {
                    imgList && imgList.length > 0 && <div className='avatar-container'>
                        {
                            imgList.map((imgSrc, index) => {
                                return (
                                    <div className='avatar-box' onClick={()=>{
                                        this.props.changeImgUrl(imgSrc, index)
                                      
                                    }} key={index}>
                                        <img src={imgSrc} alt="avatar"/>
                                    </div>
                                )
                                
                            })
                        }
                    </div>

                }
            </Fragment>
        )
    }
}

export default Gallery