import React, { Component, Fragment } from 'react';
import Header from '../../layout/header/index';
import Swiper from '../../element/swiper';
import Banner from '../../layout/banner';
import QrCode from '../../layout/qrCode';
import Footer from '../../layout/footer';
import { banner, list1, list2, list3, list4, weibo, redBook, bzhan, douyin, Subtract, Union, one, two, three, four } from './data';
import "./index.css";
import useAssets from "../../../common/useAssets";
const cdn = useAssets(false);

class page extends Component {
    componentDidMount() {
        document.title = '如涵广告业务-如涵官网';
    }

    render() {
        return (
            <Fragment>
                <div className="flexccc advertising" >
                    <Header bg={{background: '#333333'}} />
                    <div className='nav-head-img'>
                        <Banner imgurl={banner} />
                        <div className='nav-head-text'>
                            <img alt="arrow" src={cdn(`images/advertising/banner-text.png`)} />
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="contentTitle_by" >
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt">全平台优质资源</p>
                                <p style={{ top: '38px', width: '68px' }} className="underline"></p>
                                <div className='content-tabs'>
                                    {
                                        [{
                                            num1: '200+',
                                            title1: '独家KOL',
                                            num2: '20000+',
                                            title2: '入驻达人',
                                            url: weibo
                                        },
                                        {
                                            num1: <span>3.62<span style={{ fontSize: '24px' }}>亿</span></span>,
                                            title1: '独家KOL粉丝',
                                            num2: <span>7.2<span style={{ fontSize: '24px' }}>亿</span></span>,
                                            title2: '达人全网粉丝',
                                            url: redBook
                                        },
                                        {
                                            num1: '3000+',
                                            title1: '合作品牌',
                                            num2: '2000+',
                                            title2: '入驻商家',
                                            url: bzhan
                                        }].map((item, index) => (
                                            <div className='content-tab-item' key={index}>
                                                <div key={index} style={{ width: '100%', margin: '0', marginTop: '16px ' }} className="contentTitle_num" >
                                                    <div>
                                                        <p className="p1">{item.num1}</p>
                                                        <p className="p2">{item.title1}</p>
                                                    </div>
                                                    <div className="contentTitle_shu"></div>
                                                    <div>
                                                        <p className="p1">{item.num2}</p>
                                                        <p className="p2">{item.title2}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="contentTitle_by" >
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt">广告产品</p>
                                <p style={{ top: '38px', width: '68px' }} className="underline"></p>
                                <div className="t1">
                                    <div>如涵独创的广告产品体系，从大牌品宣、社交种草到电商节点转化，精准匹配品牌主广</div>
                                    <div>告投放需求，个性化定制使投放效能最大化，成功为3000+国内外品牌提供从广告到转</div>
                                    <div>化的全链路营销获客解决方案。</div>
                                </div>
                            </div>
                        </div>


                        <div className="contentTitle_by" >
                            <img className="Union" src={Union} alt="圈" />
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt_t">大牌品宣</p>
                                <img className="contentTitle_byt_n" src={one} alt="01" />
                                <div style={{ width: 1100, marginTop: '30px' }}>
                                    <Swiper list={list1} />
                                </div>
                            </div>
                        </div>
                        <div className="contentTitle_by" >
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt_t">社交种草</p>
                                <img className="contentTitle_byt_n" src={two} alt="02" />
                                <div style={{ width: 1100, marginTop: '30px' }}>
                                    <Swiper list={list2} />
                                </div>
                            </div>
                        </div>
                        <div className="contentTitle_by" >
                            <div className="contentTitle_byb">
                                <img className="Subtract" src={Subtract} alt="圈" />
                                <p className="contentTitle_byt_t">深度合作</p>
                                <img className="contentTitle_byt_n" src={three} alt="03" />
                                <div style={{ width: 1100, marginTop: '30px' }}>
                                    <Swiper list={list3} />
                                </div>
                            </div>
                        </div>
                        <div className="contentTitle_by" >
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt_t">创新服务</p>
                                <img className="contentTitle_byt_n" src={four} alt="04" />
                                <div style={{ width: 1100, marginTop: '30px' }}>
                                    <Swiper list={list4} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <QrCode />
                </div>
            </Fragment>
        )
    }
}
export default page;