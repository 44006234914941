import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

export const banner = cdn('images/resources/banner.png', 2000);
export const weibo = cdn('images/advertising/weibo.png');
export const redBook = cdn('images/advertising/redBook.png');
export const bzhan = cdn('images/advertising/bzhan.png');
export const douyin = cdn('images/advertising/douyin.png');

export const Subtract = cdn('images/advertising/Subtract.png', 2000);
export const Union = cdn('images/advertising/Union.png', 2000);
export const one = cdn('images/advertising/01.png');
export const two = cdn('images/advertising/02.png');
export const three = cdn('images/advertising/03.png');
export const four = cdn('images/advertising/04.png');


export const list1 = [
    {
        title: '品宣视频',
        image: cdn('images/advertising/01-1.png'),
    },
    {
        title: '品宣图文',
        image: cdn('images/advertising/01-2.png'),
    },
    {
        title: '品宣直播',
        image: cdn('images/advertising/01-3.png'),
    },
    {
        title: '线下活动',
        image: cdn('images/advertising/01-4.png'),
    },
]
export const list2 = [
    {
        title: '植入视频',
        image: cdn('images/advertising/02-1.png'),
    },
    {
        title: '植入图文',
        image: cdn('images/advertising/02-2.png'),
    },
    {
        title: '种草软植包',
        image: cdn('images/advertising/02-3.png'),
    },
]

export const list3 = [
    {
        title: '品牌代言',
        image: cdn('images/advertising/03-1.png'),
    },
    {
        title: '联名共创',
        image: cdn('images/advertising/03-2.png'),
    },
    {
        title: '品牌大使/体验官',
        image: cdn('images/advertising/03-3.png'),
    },
]


export const list4 = [
    {
        title: '0-1全案服务',
        image: cdn('images/advertising/04-1.png'),
    },
    {
        title: '单红人深度合作',
        image: cdn('images/advertising/04-2.png'),
    },
    {
        title: 'KOL Workshop',
        image: cdn('images/advertising/04-3.png'),
    },
    {
        title: '达人产品包',
        image: cdn('images/advertising/04-4.png'),
    },
]

