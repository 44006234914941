import React, {Component} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

/**
 * @list 数据列表
 * @hasArrows 是否有箭头
 * @hasTab 是否有头部Tab栏
 * @type swiper类型  banner = 单个滑动  ， slide == 3图滑动（默认）
 * @children 元素
 */
export default class SimpleSlider extends Component {
    state = {
        clickSlider: 0,
    };
    render() {
        const {clickSlider} = this.state;
        const {
            list = [],
            autoplay = false,
            touchMove = true,
            slidesToShow = 1,
            hasArrows = true,
            hasTab = true,
            type = 'slide',
            children,
            afterChange,
            beforeChange,
            className = '',
        } = this.props;
        //
        const settings = {
            autoplay: autoplay,
            arrows: hasArrows,
            touchMove: touchMove,
            slidesToShow: slidesToShow,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            swipeToSlide: true,
            className: `slick-swiper ${className}`,
            centerPadding: type === 'slide' ? '20.5%' : '0',
            centerMode: true,
            // adaptiveHeight:true,
            beforeChange: (currentSlide, nextSlide) => {
                beforeChange && beforeChange(currentSlide, nextSlide);
                // 切换暂停视频播放
                const players = document.querySelectorAll(`.slick-swiper .player${currentSlide}`);
                if (players && players.length > 0) {
                    for (let i = 0; i < players.length; i++) {
                        if (players[i].play) {
                            players[i].pause();
                        }
                    }
                }
            },
            afterChange: currentSlide => {
                afterChange && afterChange(currentSlide);
            },
        };
        return (
            <div className={`${type == 'slide' ? '' : 'noScale'}`} style={{width: '100%'}}>
                {hasTab && list.length > 0 && (
                    <div className="slick-swiper-tab">
                        <div className='slick-swiper-title-active' style={{left: `${clickSlider * (100 / list.length)}%`}} />
                        {list.map((item, index) => {
                            return (
                                <div
                                    className={`slick-swiper-title ${
                                        index === clickSlider ? 'slick-swiper-title-click' : ''
                                    }`}
                                    key={index}
                                    onClick={() => {
                                        this.setState({
                                            clickSlider: index,
                                        });
                                        this.slider && this.slider.slickGoTo(index);
                                    }}
                                >
                                    {item.title}
                                </div>
                            );
                        })}
                    </div>
                )}
                {
                    <Slider ref={slider => (this.slider = slider)} {...settings}>
                        {children
                            ? children
                            : list.map((item, index) => {
                                const last = index - 1 >= 0
                                    ? list[index - 1]
                                    : list[list.length - 1];
                                const next = index + 1 <= list.length - 1
                                    ? list[index + 1]
                                    : list[0]
                                  return (
                                      <div key={index}>
                                          <div className="flexImg ">
                                              {type == 'slide' && (
                                                  <div className="sliderLeftImg themeBg slider-img-wrap">
                                                      <div className="cover">
                                                          {!!last.content && (
                                                              <div className={'cover-text-wrap'}>
                                                                  <div className='cover-title'>
                                                                      <div style={{fontSize: 24, marginBottom: 18}}>{last.content.title}</div>
                                                                      <div>{last.content.desc}</div>
                                                                  </div>
                                                              </div>
                                                          )}
                                                          {!!last.text && (
                                                              <div className={'cover-text-wrap'}>
                                                                  <div className={'cover-text'}>
                                                                      <div className={'cover-text-title'}>
                                                                          {last.text.title}
                                                                      </div>
                                                                      <div className={'cover-text-desc'}>
                                                                          {last.text.desc}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </div>
                                                      <div className=" radius">
                                                          <img
                                                              style={{width: '100%'}}
                                                              src={
                                                                  last.image
                                                              }
                                                              alt="图片"
                                                          />
                                                      </div>
                                                  </div>
                                              )}
                                              <div className="sliderImg themeBg slider-img-wrap">
                                                  {!!item.content && (
                                                      <div className={'cover-text-wrap'}>
                                                          <div className='cover-title'>
                                                              <div style={{fontSize: 24, marginBottom: 18}}>{item.content.title}</div>
                                                              <div>{item.content.desc}</div>
                                                          </div>
                                                      </div>
                                                  )}
                                                  {!!item.text && (
                                                      <div className={'cover-text-wrap'}>
                                                          <div className={'cover-text'}>
                                                              <div className={'cover-text-title'}>
                                                                  {item.text.title}
                                                              </div>
                                                              <div className={'cover-text-desc'}>
                                                                  {item.text.desc}
                                                              </div>
                                                          </div>
                                                      </div>
                                                  )}
                                                  <div className=" radius">
                                                      {item.video && (
                                                          <div className="slick-swiper-video">
                                                              <video
                                                                  className={`player${index}`}
                                                                  src={item.video}
                                                                  controls
                                                                  loop
                                                              />
                                                          </div>
                                                      )}
                                                      <img
                                                          style={{width: '100%'}}
                                                          src={item.image}
                                                          alt="图片"
                                                      />
                                                  </div>
                                              </div>
                                              {type == 'slide' && (
                                                  <div className="sliderRightImg themeBg slider-img-wrap">
                                                      <div className="cover">
                                                          {!!next.content && (
                                                              <div className={'cover-text-wrap'}>
                                                                  <div className='cover-title'>
                                                                      <div style={{fontSize: 24, marginBottom: 18}}>{next.content.title}</div>
                                                                      <div>{next.content.desc}</div>
                                                                  </div>
                                                              </div>
                                                          )}
                                                          {!!next.text && (
                                                              <div className={'cover-text-wrap'}>
                                                                  <div className={'cover-text'}>
                                                                      <div className={'cover-text-title'}>
                                                                          {next.text.title}
                                                                      </div>
                                                                      <div className={'cover-text-desc'}>
                                                                          {next.text.desc}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )}
                                                      </div>
                                                      <div className=" radius">
                                                          <img
                                                              style={{width: '100%'}}
                                                              src={next.image}
                                                              alt="图片"
                                                          />
                                                      </div>
                                                  </div>
                                              )}
                                          </div>
                                      </div>
                                  );
                              })}
                    </Slider>
                }
            </div>
        );
    }
}
