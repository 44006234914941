import React, {Component, Fragment} from 'react';
import './index.css';
import useAssets from '../../../../../common/useAssets';
import {logoList, bgImg} from './data';

const cdn = useAssets(false);

const logo = cdn('images/footer/ruhnn.png');

/**
 * 获取样式属性中的具体值
 * @param {String} pos
 * @returns
 */
const getV = function (pos) {
    if (pos instanceof String && pos.indexOf('px') !== -1) {
        const pxPos = pos.indexOf('px');
        return parseInt(pos.substring(pxPos));
    } else {
        return pos;
    }
};

class Arcboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posList: [],
            classList: [],
            status: [],
            k: 0,
        };
        this.pedding = [];
        this.initPos.bind(this);
    }
    initPos() {
        let initShowLen = 10;
        this.initShowLen = initShowLen;
        const {containerWidth = 1260, boxHeight = 360, logoSize = 80} = this.props;
        // 通过顶点以及两侧点坐标计算运动轨迹抛物线常量k的值
        let k = (boxHeight - logoSize - 30) / Math.pow((containerWidth - logoSize) / 2, 2);

        this.setState({
            posList: Array.from({length: logoList.length}).map((item, index) => {
                if (index > initShowLen - 1) {
                    return {left: 0, bottom: 0};
                }
                const {x, y} = this.calcPos(0, 0, k, (initShowLen - index - 1) * 74);
                return {left: x, bottom: y};
            }),
            status: Array.from({length: logoList.length}).map((item, index) => {
                if (index > initShowLen - 1) {
                    return 0;
                }
                return 2;
            }),
            classList: Array.from({length: logoList.length}).map((item, index) => {
                if (index > initShowLen - 1) {
                    return '';
                }
                return 'show';
            }),
            k,
        });
    }
    /**
     * 通过当前位置计算下一帧位置
     * @param {Number} x 距离左侧距离
     * @param {Number} y 距离底部距离
     * @param {Number} k 抛物线常量
     * @returns
     */
    calcPos(x, y, k, times = 1) {
        const {containerWidth = 1260, boxHeight = 360, duration = 20, logoSize = 80} = this.props;
        if (x > containerWidth - logoSize) {
            x = 0;
            y = 0;
        } else {
            // 目前横轴还是匀速运动，可以再做一些优化
            let step = containerWidth / duration / 40;
            // console.log(step);
            x += step * times;
            const moveY = boxHeight - logoSize - 30;
            // 通过抛物线公式  y = k(x-a)^2+ b 计算y轴坐标
            y = -k * Math.pow(x - (containerWidth - logoSize) / 2, 2) + moveY;
        }

        return {x, y};
    }

    easeOutCubic(pos) {
        return Math.pow(pos - 1, 3) + 1;
    }

    /**
     * @param {Number} index 下标
     */
    moveAnimation(index) {
        const {containerWidth = 1260, logoSize = 80} = this.props;
        let {posList, status, classList, k} = this.state;

        // 每个logo的运动状态有3种，0为尚未启动, 1为pending状态setTimeout中，2为可以开始移动
        if (status[index] === 2) {
            const {left, bottom} = posList[index];
            let x1 = getV(left);
            let y1 = getV(bottom);

            const {x, y} = this.calcPos(x1, y1, k);

            // 优化，当运动到最左侧时不立刻出现在最右侧，当x离最右端还差2个logo宽度时加上hide class用来隐藏图层
            if (x > containerWidth - 2 * logoSize) {
                classList[index] = '';
                this.setState({classList: classList});
            }
            if (x === 0) {
                status[index] = 3;
                this.setState({status: status});
            }
            posList[index] = {left: x, bottom: y};
            this.setState({posList: posList});
            //未启动状态，需要根据logo的index值来设定延时时间
        } else if (status[index] === 0) {
            status[index] = 1;
            this.setState({status: status});
            setTimeout(() => {
                status[index] = 2;
                classList[index] = 'show';
                this.setState({status: status, classList: classList});
            }, 1900 * index);
        } else if (status[index] === 3) {
            setTimeout(() => {
                status[index] = 2;
                classList[index] = 'show';
                this.setState({status: status, classList: classList});
            }, 30);
        }
    }
    // 新思路 整体一起处理 通过计算一个元素的横坐标位置 推算后面的位置
    groupAnimate() {
        const {containerWidth = 1260, logoSize = 80} = this.props;
        let {posList, status, classList, k} = this.state;
        const hasReading = status.find(item => item === 0 || item === 1);
        for (let index = 0; index < logoList.length; index++) {
            if (status[index] === 0) {
                status[index] = 1;
                this.setState({status: status});
                setTimeout(() => {
                    status[index] = 2;
                    classList[index] = 'show';
                    this.setState({status: status, classList: classList});
                }, 1900 * (index + 1 - this.initShowLen));
            } else if (!hasReading && status[index] === 3 && this.pedding[0] === index) {
                status[index] = 4;
                setTimeout(() => {
                    this.pedding.shift();
                    status[index] = 2;
                    classList[index] = 'show';
                    this.setState({status: status, classList: classList});
                }, 1900);
            } else if (status[index] === 2) {
                const {left, bottom} = posList[index];
                let x1 = getV(left);
                let y1 = getV(bottom);

                const {x, y} = this.calcPos(x1, y1, k);
                // 优化，当运动到最左侧时不立刻出现在最右侧，当x离最右端还差2个logo宽度时加上hide class用来隐藏图层
                if (x > containerWidth - 2 * logoSize) {
                    classList[index] = '';
                }
                if (x === 0) {
                    classList[index] = '';
                    status[index] = 3;
                    // 先进入等待状态
                    this.pedding.push(index);
                }
                posList[index] = {left: x, bottom: y};
            }
        }
        this.setState({posList: posList});
    }

    timeoutHandle() {
        setTimeout(() => {
            this.groupAnimate();
            this.timeoutHandle();
        }, 25);
    }
    componentDidMount() {
        this.initPos();
        // this.timeout = setInterval(() => {
        //     for (let i = 0; i < logoList.length; i++) {
        //         this.moveAnimation(i)
        //     }
        // }, 30)
        this.timeout = setTimeout(() => {
            this.timeoutHandle();
        }, 1000);
    }
    render() {
        const logos = logoList;
        const {posList, classList} = this.state;
        const {containerWidth = 1260} = this.props;
        return (
            <Fragment>
                <div className="arcboard" style={{backgroundImage: `url(${bgImg})`}}>
                    <div className="logo-item">
                        <img src={logo} alt="" />
                    </div>
                    <div className="arc-container" style={{width: `${containerWidth}px`}}>
                        {logos && logos.length > 0 && (
                            <div className="arc-group">
                                {logos.map((item, index) => {
                                    return (
                                        <div
                                            className={'arc-box ' + classList[index]}
                                            key={index}
                                            style={posList[index]}
                                        >
                                            <img src={item} alt="品牌" />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Arcboard;
