import useAssets from '../../../../../common/useAssets';
const cdn = useAssets(false);
const cdnLocal = useAssets(false);

const rows0101 = cdn('images/coBranding/rows-1-1.png');
const rows0102 = cdn('images/coBranding/rows-1-2.png');
const rows0103 = cdn('images/coBranding/rows-1-3.png');
const rows0104 = cdn('images/coBranding/rows-1-4.png');

const rows0201 = cdn('images/coBranding/rows-2-1.png');
const rows0202 = cdn('images/coBranding/rows-2-2.png');
const rows0203 = cdn('images/coBranding/rows-2-3.png');
const rows0204 = cdn('images/coBranding/rows-2-4.png');

const rows0301 = cdn('images/coBranding/rows-3-1.png');
const rows0302 = cdn('images/coBranding/rows-3-2.png');
const rows0303 = cdn('images/coBranding/rows-3-3.png');
const rows0304 = cdn('images/coBranding/rows-3-4.png');

const rows0401 = cdn('images/coBranding/rows-4-1.png');
const rows0402 = cdn('images/coBranding/rows-4-2.png');
const rows0403 = cdnLocal('images/coBranding/rows-4-3.png');
const rows0404 = cdn('images/coBranding/rows-4-4.png');

const rows0501 = cdnLocal('images/coBranding/rows-5-1.png');
const rows0502 = cdnLocal('images/coBranding/rows-5-2.png');
const rows0503 = cdnLocal('images/coBranding/rows-5-3.png');
const rows0504 = cdnLocal('images/coBranding/rows-5-4.png');

const rows0601 = cdnLocal('images/coBranding/rows-6-1.png');
const rows0602 = cdnLocal('images/coBranding/rows-6-2.png');
const rows0603 = cdnLocal('images/coBranding/rows-6-3.png');
const rows0604 = cdnLocal('images/coBranding/rows-6-4.png');

const rows0701 = cdnLocal('images/coBranding/rows-7-1.png');
const rows0702 = cdnLocal('images/coBranding/rows-7-2.png');
const rows0703 = cdnLocal('images/coBranding/rows-7-3.png');
const rows0704 = cdnLocal('images/coBranding/rows-7-4.png');

const rows0801 = cdnLocal('images/coBranding/rows-8-1.png');
const rows0802 = cdnLocal('images/coBranding/rows-8-2.png');
const rows0803 = cdnLocal('images/coBranding/rows-8-3.png');
const rows0804 = cdnLocal('images/coBranding/rows-8-4.png');

const rows0901 = cdnLocal('images/coBranding/rows-9-1.png');
const rows0902 = cdnLocal('images/coBranding/rows-9-2.png');
const rows0903 = cdnLocal('images/coBranding/rows-9-3.png');
const rows0904 = cdnLocal('images/coBranding/rows-9-4.png');

const rows1001 = cdnLocal('images/coBranding/rows-10-1.png');
const rows1002 = cdnLocal('images/coBranding/rows-10-2.png');
const rows1003 = cdnLocal('images/coBranding/rows-10-3.png');
const rows1004 = cdnLocal('images/coBranding/rows-10-4.png');


export const column1 = [ 
    { src:rows0101,title:'YSL x 如涵',description:'【YSL | 自由之水 诠释独特魅力】推广总阅读量：161w；互动量：21557。' },
    { src:rows0201,title:'MCN x 腻腻ninii',description:'【经典配饰buff 打造吸睛辣妹造型】推广总阅读量：7w，互动量：1500+。' },
    { src:rows0301,title:'资生堂 x -超级轩-',description:'【全网矩阵安利 双11必入清单】推广总阅读量 561w，播放量 136.4w，互动量 18.8w。' },
    { src:rows0401,title:'oppo x 梁一口',description:'【夏日星黛妆 绝绝紫换头术】播放量 18w 互动量 2000+ 。#人间绝绝紫# 阅读4.2亿 讨论69.8。' },
    { src:rows0501,title:'宝格丽 x 鸽鸽dovie',description:'【情人节限定石榴红 吸睛100%】推广总阅读量：25w，互动量：5000+。' },
    { src:rows0601,title:'雅诗兰黛 x 满满Cyim',description:'【轻薄持久利器 打造甜辣女团妆】全天测评，实力背书。播放量480,000+ 讨论量6,849+' },
    { src:rows0701,title:'资生堂 x 全智鹅',description:'【全网矩阵安利 双11必入清单】推广总阅读量 561w，播放量 136.4w，互动量 18.8w。' },
    { src:rows0801,title:'MCN x BB大哥',description:'【经典配饰buff 打造吸睛辣妹造型】推广总阅读量：7w，互动量：1500+。' },
    { src:rows0901,title:'赫莲娜 x 彭特务',description:'【种草品宣直播收割 逐步达成营销闭环】多次合作积累粉丝信赖 深度合作助力品牌转化。阅读量650,000+ 播放量5,000,000+' },
    { src:rows1001,title:'纪梵希 x 大bopa',description:'【富家千金标配 打造新年好运Buff】#红运锦鲤金彩贺新#话题， 推广总阅读量：30w，播放量：22w，互动量：5000+。' }    
];

export const column2 = [ 
    { src:rows0102,title:'《看见LV》 x 如涵',description:'【多维角度打造奢品展览，高质感图文打造打卡热点】阅读量：1065.6w，互动量：75000。#看见LV#话题阅读量：63000w，话题讨论度：375.7w。' },
    { src:rows0202,title:'纪梵希 x BabyVenerer',description:'【富家千金标配 打造新年好运Buff 】#红运锦鲤金彩贺新#话题， 推广总阅读量：30w，播放量：22w，互动量：5000+。' },
    { src:rows0302,title:'膜法世家 x 宝剑嫂',description:'【膜法世家面膜推荐官C位出道】内容植入软性推广，官宣互动超强曝光。话题阅读量8,567,000+ ，播放量 1,551,000+' },
    { src:rows0402,title:'花西子 x 满满Cyim',description:'【赋传统以时尚 演绎东方新美学】高品质品宣图文，实力推荐#花西子苗族印象高定#话题阅读量280,000,000+ 讨论量64,000+' },
    { src:rows0502,title:'华伦天奴 x 曾子容',description:'【天生高定大V仙女盒 出街必备单品】互动量：1057，#华伦天奴美妆免税盛装登场，浏览量 5.6w。' },
    { src:rows0602,title:'梅赛德斯-奔驰 x 彭特务',description:'【精英女性 晒出C位底气】奔驰C-Class相互成就，传达女性力量。话题阅读量240,000,000+ 讨论量2,168,000+' },
    { src:rows0702,title:'祖玛珑 x 腻腻ninii',description:'【治愈系周末第一步，用香氛营造氛围感】推广总阅读量：55781，互动量：1000+。' },
    { src:rows0802,title:'943 x 精致大姨夫',description:'【助力国货从0到1 打爆声量 】总阅读量220w+，总互动量 13.2w+，品牌复投 6次。' },
    { src:rows0902,title:'MAC x 彭特务',description:'【水感妆效 纯欲天花板 】播放量：314w，互动量：52425。#MAC水漾子弹头#阅读9620.8万 ，讨论65.2万 。' },
    { src:rows1002,title:'赫莲娜 x 彭特务',description:'【买基金不如买“肌金” 大牌面霜倾情测评】播放量：232w，互动量：62630。' }    
];

export const column3 = [ 
    { src:rows0103,title:'完美日记 x 如涵',description:'【完美日记x陈漫 #留住中国美色# 公益影展】 #留住中国美色#：阅读量7184w，讨论量63w；#完美日记丹顶鹤盘：阅读量15000w，讨论量：71.2w 。' },
    { src:rows0203,title:'资生堂 x 宝剑嫂',description:'【全网矩阵安利 双11必入清单】推广总阅读量 561w，播放量 136.4w，互动量 18.8w。' },
    { src:rows0303,title:'极米 x 梁一口',description:'【24h穿越1w公里 陪你一起沉浸看世界】视频播放量 13.5w 互动量 2641。#陪你一起沉浸看世界#阅读7.9亿 讨论417.7万。' },
    { src:rows0403,title:'卡姿兰 x 彭特务',description:'【陈好仿妆 欢迎回到千禧年代】播放量：421w ，互动量：85574。' },
    { src:rows0503,title:'完美日记 x 腻腻ninii',description:'【质感大片 致敬女性力量】悬疑特工大片助力新品上市，阅读量51,884,000 + 讨论23,000+' },
    { src:rows0603,title:'植村秀 x 彭特务',description:'【派对气氛组女王猫系妆】播放量：331w，互动量：59512。' },
    { src:rows0703,title:'卡姿兰 x 彭特务',description:'【彩妆艺术家 为新品背书】精心打造TVC质感视频。阅读量458,126+  播放量 4,020,000+' },
    { src:rows0803,title:'资生堂 x 杨霞-Sunny',description:'【全网矩阵安利 双11必入清单】推广总阅读量 561w，播放量 136.4w，互动量 18.8w。' },
    { src:rows0903,title:'纪梵希 x 杨十一·',description:'【富家千金标配 打造新年好运Buff】#红运锦鲤金彩贺新#话题， 推广总阅读量：30w ，播放量：22w ，互动量：5000+。 ' },
    { src:rows1003,title:'Chanel x 宝剑嫂',description:'【5号工厂限时店 经典与实用主义体验】互动量：1.5w #香奈儿5号工厂# 阅读1.8亿，讨论24.4万。' }    
];

export const column4 = [ 
    { src:rows0104,title:'Fendi x 如涵',description:'【FENDI CAFFE限时体验 感受新系列色彩冲击】互动量：17427 #FendiSummerVertigo# 阅读2亿 讨论99.5万。' },
    { src:rows0204,title:'HR x 曾子容',description:'【治愈云朵泡泡洁面 高科技温和不紧绷】互动量：2333 。#赫莲娜洁面慕斯 浏览量258w。' },
    { src:rows0304,title:'美宝莲 x 腻腻ninii',description:'【旗舰店首位KOL彩妆模特】登入淘内banner位，常驻旗舰店首页，塑造品牌高级视觉形象' },
    { src:rows0404,title:'资生堂 x 只小萌',description:'【全网矩阵安利 双11必入清单】推广总阅读量 561w，播放量 136.4w，互动量 18.8w。' },
    { src:rows0504,title:'deepond x 满满cyim',description:'【奇幻餐厅 定制肌肤营养餐】播放量 35w 互动量 3000+。' },
    { src:rows0604,title:'施华洛世奇 x 邓邓 Jt',description:'【新年限定单品 打造节日氛围】推广总阅读量：12w，互动量：1000+。' },
    { src:rows0704,title:'宝格丽 x 怡含怡含',description:'【情人节限定石榴红 吸睛100%】推广总阅读量：25w，互动量：5000+。' },
    { src:rows0804,title:'祖玛珑 x 琪崽崽',description:'【治愈系周末第一步，用香氛营造氛围感】推广总阅读量：55781，互动量：1000+。' },
    { src:rows0904,title:'露华浓 x 彭特务',description:'【纯欲甜妹 VS 千禧辣妹 甜辣风格深唇挚爱】播放量：442w，互动量：73538。' },
    { src:rows1004,title:'兰蔻 x 满满cyim',description:'【兰蔻 | 维稳宝藏精华 实力安利好物分享】推广总声量：阅读量3306.6w，讨论量51912。' }    
];