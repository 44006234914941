import React, { Component,Fragment } from 'react';
import './index.css';
import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

const footerbg = cdn('images/footer/footer_bg.png');
const logo = cdn('images/footer/ruhnn.png');

class Footer extends Component {
    render() {
        return (
             <Fragment>
              <div className={`footer flexces ${this.props.className}`} >
                  <div className='footer-circle' />
                  <div className="flexrss-bottom" >
                    <div className="joinUs" style={{marginRight: 90}} >
                            <div style={{fontWeight: 600}}>加入我们</div>
                            <a href="/joinUs">成为红人</a>
                            <a href="https://ewbmxoqgfx.jobs.feishu.cn/index" target="_blank">社会招聘</a>
                            <a href="https://ewbmxoqgfx.jobs.feishu.cn/652250" target="_blank">校园招聘</a>
                    </div>
                    <div className="contactUs" >
                        <div style={{fontWeight: 600}}>联系我们</div>
                        <div>地址：杭州市上城区高德置地广场A2幢37-39F</div>
                        <div>红人签约：yuyd@ruhnn.com</div>
                        <div>品牌合作：KOL@ruhnn.com</div>
                    </div>
                    <div className="contactUs" style={{marginLeft: 45}}>
                        <div style={{visibility: 'hidden', fontWeight: 600}}>联系我们</div>
                        <div>美妆直播合作：huangxy@ruhnn.com</div>
                        <div>女装直播合作：hew@ruhnn.com</div>
                        <div>电商合作：fsds@ruhnn.com</div>
                    </div>
                  </div>
                  <div className="copyright">
                      <a style={{color: '#fff'}} href="https://beian.miit.gov.cn/#/Integrated/index" target='_blank'>
                          浙ICP备20013375号-4 Copyright &copy; 2012-2022 如涵版权所有
                      </a>
                  </div>
                  <img src={logo} className="logo" />
              </div>
             </Fragment>
        );
    }
}

export default Footer;