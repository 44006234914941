import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);
export const weibo = cdn('images/advertising/weibo.png');
export const redBook = cdn('images/advertising/redBook.png');
export const bzhan = cdn('images/advertising/bzhan.png');
export const douyin = cdn('images/advertising/douyin.png');
export const kuaishou = cdn('images/marketing/kuaishou.png');

export const list11 = [
    {
        name: '@莉贝琳',
        id: 1,
        introduce: '休闲风干货分享型穿搭达人，口播感染力满满的生活分享博主。',
        image: cdn('images/starResources/all/1.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        wbUrl: 'https://weibo.com/liblin',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/5ac33e41e8ac2b4b04fa9106?xhsshare=CopyLink&appuid=5ed8a685000000000101ed84&apptime=1612431649',
    },
    {
        name: '@宝剑嫂',
        id: 2,
        introduce: 'B站百大UP主 戏精搞笑 邻家亲切',
        image: cdn('images/starResources/all/2.jpg'),
        blbl: true,
        blblUrl: 'https://space.bilibili.com/113362335\n',
        dy: true,
        dyUrl: 'https://v.douyin.com/JAdwJQv/',
        wb: true,
        wbUrl: 'https://weibo.com/u/5724402884',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/5b320418e8ac2b2954dcbb81',
    },
    {
        name: '@彭特务',
        id: 3,
        introduce: '敏感肌彩妆换头技术流 TVC质感大片出品',
        image: cdn('images/starResources/all/3.jpg'),
        blbl: true,
        blblUrl: 'https://space.bilibili.com/28738201',
        dy: true,
        dyUrl: 'https://v.douyin.com/JAdK4ts',
        wb: true,
        wbUrl: 'https://weibo.com/PENGTEWU6',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/5aa77d0e4eacab7662216fa4',
    },
    {
        name: '@精致大姨夫',
        id: 4,
        introduce: '专业护肤产品测评成分党 ',
        image: cdn('images/starResources/all/4.jpg'),
        blbl: false,
        blblUrl: 'https://space.bilibili.com/391192292',
        dy: true,
        dyUrl: 'https://v.douyin.com/JARRqGQ',
        wb: true,
        wbUrl: 'https://weibo.com/u/7411343880',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/5bc56aaac0f39e0001627d17',
    },
    {
        name: '@文不叮/Winnie文',
        id: 5,
        introduce:'小红书TOP级KOL B站2020百大UP主',
        image: cdn('images/starResources/all/5.jpg'),
        dy: false,
        wb: true,
        wbUrl: 'https://weibo.com/u/2033204117',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/5a4ee75ce8ac2b4cb41b1649?xhsshare=CopyLink&appuid=5e7a22700000000001007fae&apptime=1590752236',
    },
    {
        name: '@布兰尼老田田',
        id: 6,
        introduce: '专业美妆技术流 沉浸式专栏爆款频出',
        image: cdn('images/starResources/all/6.jpg'),
        blbl: false,
        dy: false,
        wb: false,
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/565f83a40cdc2b0d52360892?xhsshare=CopyLink&appuid=5e7a22700000000001007fae&apptime=1599566174',
    },
    {
        name: '@小李朝ye',
        id: 7,
        introduce: '精分搞笑达人',
        image: cdn('images/starResources/all/7.jpg'),
        blbl: false,
        dy: true,
        dyUrl: 'https://v.douyin.com/JARSwBN',
        wb: false,
        wbUrl: 'https://weibo.com/u/5227892437',
        xhs: false,
        ks: true,
        ksUrl: 'https://v.kuaishou.com/6HblCg',
    },
    {
        name: '@Vivekatt',
        id: 8,
        introduce: 'B站百大up 美妆区前三博主 vogue等多家国际时尚杂志合作职业造型师 北欧follower最多的油管博主 北欧&海外lifestyle',
        image: cdn('images/starResources/all/8.jpg'),
        blbl: true,
        blblUrl: 'https://space.bilibili.com/16307541',
        dy: false,
        wb: true,
        wbUrl: 'https://weibo.com/u/1907184700',
        xhs: false,
    },
    {
        name: '@二门_',
        id: 9,
        introduce: '仿妆博主',
        image: cdn('images/starResources/all/9.jpg'),
        blbl: true,
        blblUrl: 'https://space.bilibili.com/298213453',
        dy: false,
        wb: false,
        xhs: false,
    },
    {
        name: '@小野智恩',
        id: 10,
        introduce: '小个子日韩系穿搭达人 亲和力超强的生活分享达人',
        image: cdn('images/starResources/all/10.jpg'),
        blbl: true,
        blblUrl: 'https://v.douyin.com/JAdwV42',
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@王宛尘',
        id: 11,
        introduce: '毒蛇宝藏重庆“王子文”轻熟龄肌，分享30+小姐姐质感生活',
        image: cdn('images/starResources/all/11.jpg'),
        blbl: false,
        dy: true,
        dyUrl: 'https://v.douyin.com/JAdbME2',
        wb: true,
        wbUrl: 'https://weibo.com/vanessa924',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/5aec1fdfe8ac2b2e40489a40',
    },
    {
        name: '@笑笑同学啊_',
        id: 12,
        introduce: '镜头感超强的前职业摄影师 爆款频出的时尚美妆达人',
        image: cdn('images/starResources/all/12.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        wbUrl: 'https://weibo.com/xiachenxiao',
        xhs: false,
    },
    /*{
        name: '@曾子容',
        id: 13,
        introduce: '野生但在努力的美妆博主',
        image: cdn('images/starResources/all/13.jpg'),
        blbl: false,
        dy: true,
        dyUrl: 'https://v.douyin.com/JARFuHP',
        wb: false,
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/5b0131dc11be102b316a969f',
    },*/
    {
        name: '@UG你大哥',
        id: 14,
        introduce: '整容级美妆大佬 学生党宠爱的大眼彩妆博主',
        image: cdn('images/starResources/all/14.jpg'),
        blbl: true,
        blblUrl: 'https://space.bilibili.com/387755664',
        dy: false,
        dyUrl: 'https://v.douyin.com/JAegfef',
        wb: false,
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/5cf1fd44000000001602c2bb',
        ks: true,
        ksUrl: 'https://v.kuaishou.com/8lQWXx'
    },
    {
        name: '@满满Cyim',
        id: 15,
        introduce: '韩风甜美系穿搭达人 专业彩妆学习经验的时尚博主',
        image: cdn('images/starResources/all/15.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@鸽鸽dovie',
        id: 16,
        introduce: '清纯韩系原相机女孩 淡颜风格彩妆分享家',
        image: cdn('images/starResources/all/16.jpg'),
        blbl: true,
        blblUrl: 'https://space.bilibili.com/361433786',
        dy: true,
        dyUrl: 'https://v.douyin.com/3XqVY7',
        wb: true,
        wbUrl: 'https://weibo.com/cyiman',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/54dd7dba2e1d936d63815da2',
    },
    {
        name: '@只小萌',
        id: 18,
        introduce: '韩系通勤风穿搭博主 对镜拍教科书 内容超下饭的生活Vlogger',
        image: cdn('images/starResources/all/18.jpg'),
        blbl: true,
        dy: true,
        dyUrl: 'https://v.douyin.com/3XxtdJ/',
        wb: true,
        wbUrl: 'https://weibo.com/u/3101275054',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/56ca75b384edcd3659f7a8a3',
    },
    {
        name: '@杨霞',
        id: 19,
        introduce: '时尚辣妈 美妆博主 健身达人',
        image: cdn('images/starResources/all/19.jpg'),
        blbl: true,
        blblUrl: 'https://space.bilibili.com/287121537',
        dy: true,
        dyUrl: 'https://v.douyin.com/JAR887S',
        wb: true,
        wbUrl: 'https://weibo.com/ygxia',
        xhs: true,
        xhsUrl: 'https://www.xiaohongshu.com/user/profile/59b4de956a6a69158d8e9d72',
    },
    {
        name: '@汤几一',
        id: 20,
        introduce: '日系时尚ICON',
        image: cdn('images/starResources/all/20.jpg'),
        blbl: true,
        dy: false,
        wb: false,
        xhs: true,
    },
    {
        name: '@晁然然然然',
        id: 21,
        introduce: '文艺气质国民学姐 全平台带货达人 明星红人',
        image: cdn('images/starResources/all/21.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@小魏huhu',
        id: 22,
        introduce: '高颜值播音系学姐 欢乐学生党美妆博主',
        image: cdn('images/starResources/all/22.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@顽童大人',
        id: 23,
        introduce: '时尚带货小能手 全年销售破亿女装店主 2019微博新锐红人',
        image: cdn('images/starResources/all/23.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@梁一口echo',
        id: 24,
        introduce: 'B站知名up主 邻家妹妹系生活VLOG分享红人 ',
        image: cdn('images/starResources/all/24.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@憨娜hannah',
        id: 25,
        introduce: '大码时尚穿搭博主 甜美风美妆达人',
        image: cdn('images/starResources/all/25.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@Sasaaaa',
        id: 26,
        introduce: '精致生活导师 医美直播带货达人',
        image: cdn('images/starResources/all/26.jpg'),
        blbl: false,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@lu.meng',
        id: 27,
        introduce: '皇家艺术学院当代艺术研究生 小众有调性穿搭博主 超高艺术审美 女性力量输出',
        image: cdn('images/starResources/all/27.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@邓邓Jt',
        id: 28,
        introduce: '时尚邻家大姐姐 购物分享达人',
        image: cdn('images/starResources/all/28.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@绒耳朵儿',
        id: 29,
        introduce: '中性风甜酷穿搭博主 艺术生插画师',
        image: cdn('images/starResources/all/29.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@腻腻ninii',
        id: 30,
        introduce: '超模质感的时髦达人 高奢挚爱，高调品宣',
        image: cdn('images/starResources/all/30.jpg'),
        blbl: false,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@李璐Gemma',
        id: 31,
        introduce: '10年抗痘经验背书的成分党博主 轻欧美系美妆时尚达人',
        image: cdn('images/starResources/all/31.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@RMIUC',
        id: 32,
        introduce: '年近40，年轻保养系博主 大片制造机，深受大牌喜爱 新西兰留学背景 专业素养+高配合度=高质量内容输出',
        image: cdn('images/starResources/all/32.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@BB大哥',
        id: 33,
        introduce: '港风高级脸 分差鬼马精灵 美妆护肤推荐王',
        image: cdn('images/starResources/all/33.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@怡含怡含',
        id: 34,
        introduce: '韩系时尚彩妆分享红人 韩国音乐留学生',
        image: cdn('images/starResources/all/34.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@小越小越-',
        id: 35,
        introduce: '甜酷美风精致穿搭向导',
        image: cdn('images/starResources/all/35.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@Babeei張張-',
        id: 36,
        introduce: '时尚穿搭博主 涂鸦小能手 小众好物挖掘机',
        image: cdn('images/starResources/all/36.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: false,
    },
    {
        name: '@舟好甜',
        id: 37,
        introduce: '行走的小个子穿搭种草机 氧气感四川女孩儿 川式搞笑情侣CPB站百大UP主 戏精搞笑 邻家亲切',
        image: cdn('images/starResources/all/37.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@Rmoong龙龙',
        id: 38,
        introduce: '韩系圆脸妹妹 妈生皮原创博主 百变妆容技术流',
        image: cdn('images/starResources/all/38.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@琪崽崽',
        id: 39,
        introduce: '学生党博主 可爱幼龄感少女',
        image: cdn('images/starResources/all/39.jpg'),
        blbl: false,
        dy: false,
        wb: false,
        xhs: true,
    },
    {
        name: '@良潇_',
        id: 40,
        introduce: '高级感时尚博主 生活vlog分享达人 健身属性 专业彩妆学习经验背书',
        image: cdn('images/starResources/all/40.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: false,
    },
    {
        name: '@是静静JING_',
        id: 41,
        introduce: '韩系色彩穿搭博主 微胖界衣架子 美妆护肤小百科',
        image: cdn('images/starResources/all/41.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: false,
    },
    {
        name: '@是南希Nancy_',
        id: 42,
        introduce: '韩系微胖可爱女孩 笑容治愈',
        image: cdn('images/starResources/all/42.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: false,
    },
    {
        name: '@上下名',
        id: 43,
        introduce: '甜酷腿精种草机 淡颜天花板女孩 视觉美学高端玩家',
        image: cdn('images/starResources/all/43.jpg'),
        blbl: false,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@-鱼九九-',
        id: 44,
        introduce: '复古风时尚穿搭红人 轻敏混油肌彩妆分享达人 96年嫩颜已婚少妇',
        image: cdn('images/starResources/all/44.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
 /*   {
        name: '@男人相',
        id: 45,
        introduce: '时尚变美技巧分享小达人 东北迪丽热巴，韩系氛围感美女',
        image: cdn('images/starResources/all/45.jpg'),
        blbl: false,
        dy: false,
        wb: false,
        xhs: true,
    },*/
    {
        name: '@喜悦姑娘Clara',
        id: 46,
        introduce: 'lifestyle blogger 精致高调性时尚穿搭博主',
        image: cdn('images/starResources/all/46.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@MC萱萱RubyLi',
        id: 47,
        introduce: '海归双语主持人 时尚优雅高知博主',
        image: cdn('images/starResources/all/47.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@小马晕晕yunn',
        id: 48,
        introduce: '韩风时尚分享红人 恋爱CP属性',
        image: cdn('images/starResources/all/48.jpg'),
        blbl: false,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@陶四七-',
        id: 49,
        introduce: '同济大学毕业的艺术系高知美女 高奢宠爱的行走画报 高甜撒糖cp',
        image: cdn('images/starResources/all/49.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@元气菊仔',
        id: 50,
        introduce: '热爱美食的肌肉女孩 爆款频出的健身达人 口播风格超嗨的分享型博主',
        image: cdn('images/starResources/all/50.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@有竹-',
        id: 51,
        introduce: '高级复古精致脸，高奢宠爱 精致调性生活，传递独特的生活方式 新中式风格KOL ',
        image: cdn('images/starResources/all/51.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@桃瑞思',
        id: 52,
        introduce: '东华大学毕业的高颜值时髦博主  集"高奢"与"生活"于一身的分享达人',
        image: cdn('images/starResources/all/52.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@林零亿',
        id: 53,
        introduce: '精致微胖穿搭博主 口播带货达人',
        image: cdn('images/starResources/all/53.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@沈大美Shirine',
        id: 54,
        introduce: '趣味生活vlog创作者 TVC女主脸博主',
        image: cdn('images/starResources/all/54.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@瘦瘦璐-',
        id: 55,
        introduce: '清冷氛围感美女 时尚穿搭博主',
        image: cdn('images/starResources/all/55.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@美希Mazing',
        id: 56,
        introduce: '配音演员级美妆妆教大佬 护肤干货专家 微胖时尚穿搭达人',
        image: cdn('images/starResources/all/56.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@喜大牙',
        id: 57,
        introduce: '149cm小个子日系鬼马可爱少女',
        image: cdn('images/starResources/all/57.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@超级轩-',
        id: 58,
        introduce: '甜盐系高颜少女 时尚穿搭达人 知名美妆VLOG博主',
        image: cdn('images/starResources/all/58.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: false,
    },
    {
        name: '@山生',
        id: 59,
        introduce: '日系清新氧气小美女 天生好白皮',
        image: cdn('images/starResources/all/59.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@帕尔哈迪迪',
        id: 60,
        introduce: '日美潮流穿搭男博主 口播一流综艺感十足的玩梗达人',
        image: cdn('images/starResources/all/60.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@Tuitui_',
        id: 61,
        introduce: '单眼皮换脸美妆博主 英国NTU 时尚管理IFM',
        image: cdn('images/starResources/all/61.jpg'),
        blbl: false,
        dy: false,
        wb: false,
        xhs: true,
    },
    {
        name: '@羊大锅锅',
        id: 62,
        introduce: '日系中性风穿搭博主少年感少女感并存艺术生插画师',
        image: cdn('images/starResources/all/62.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@大七仔呀',
        id: 63,
        introduce: '小众时尚穿搭达人 外冷内热傻大妞',
        image: cdn('images/starResources/all/63.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@LiLi长不大',
        id: 64,
        introduce: '美妆技术流 购物小天才 微胖穿搭博主 情侣CP',
        image: cdn('images/starResources/all/64.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@一只梁不了',
        id: 65,
        introduce: 'B站美妆生活UP主 清冷优雅精致生活的vlogger 医美顾问属性',
        image: cdn('images/starResources/all/65.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: false,
    },
    {
        name: '@饶萌呀',
        id: 66,
        introduce: '圆脸可爱系美妆博主 招猫逗狗的铲屎官 摄影能手',
        image: cdn('images/starResources/all/66.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@杨十一',
        id: 67,
        introduce: '高级感轻法式时尚博主',
        image: cdn('images/starResources/all/67.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@陈鱼白',
        id: 68,
        introduce: '140斤微胖穿搭红人 显瘦穿搭高手',
        image: cdn('images/starResources/all/68.jpg'),
        blbl: false,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@全智鹅',
        id: 69,
        introduce: '韩系鬼马少女 综艺感十足的分享种草机',
        image: cdn('images/starResources/all/69.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@柒七Kylin',
        id: 70,
        // introduce: 'B站百大UP主 戏精搞笑 邻家亲切',
        image: cdn('images/starResources/all/70.jpg'),
        blbl: true,
        dy: false,
        wb: false,
        xhs: false,
    },
    {
        name: '@吕根lg',
        id: 71,
        introduce: '痞帅的阳光大男孩  时尚穿搭的教科书 生活分享型博',
        image: cdn('images/starResources/all/71.jpg'),
        blbl: false,
        dy: true,
        wb: true,
        xhs: false,
    },
    {
        name: '@一颗七崽',
        id: 72,
        introduce: '日韩风时尚穿搭彩妆分享红人',
        image: cdn('images/starResources/all/72.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@戴黎婷',
        id:73,
        introduce: '微电影感高颜值女主 甜酷穿搭 甜蜜情侣CP',
        image: cdn('images/starResources/all/73.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@Elin_顾零一',
        id: 74,
        introduce: '小众甜酷穿搭博主 美院毕业野生造型师 高艺术调性',
        image: cdn('images/starResources/all/74.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@蒋橙橙KZ',
        id: 75,
        introduce: '微博时尚美妆博主 VLOG短视频达人',
        image: cdn('images/starResources/all/75.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@是妖妖阿',
        id: 76,
        introduce: '精分鬼马美妆博主 美妆护肤干货分享达人',
        image: cdn('images/starResources/all/76.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: false,
    },
    {
        name: '@刘梓萌嗎',
        id: 77,
        introduce: '欧美甜酷辣妹 时尚美妆 医美达人',
        image: cdn('images/starResources/all/77.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: false,
    },
    {
        name: '@BooBoo_蛋清',
        id: 78,
        introduce: '甜酷复古穿搭 小众好物挖掘机',
        image: cdn('images/starResources/all/78.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@D登登',
        id: 79,
        introduce: '大码微胖轻熟风姐姐 变装小达人',
        image: cdn('images/starResources/all/79.jpg'),
        blbl: false,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@Lsy秦公子',
        id: 80,
        introduce: '美妆好物分享达人 时尚穿搭博主',
        image: cdn('images/starResources/all/80.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@五十青',
        id: 81,
        introduce: '韩风浓颜系 时尚好物安利 生活vlog分享',
        image: cdn('images/starResources/all/81.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@铁一WENDYZ',
        id: 82,
        introduce: '可盐可甜的美妆技术流 氛围感清冷美女 韩国留学时尚达人',
        image: cdn('images/starResources/all/82.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: false,
    },
/*    {
        name: '@满杯谌谌',
        id: 83,
        introduce: '初恋脸甜酷女孩 小个子穿搭达人 写真分享元祖博主',
        image: cdn('images/starResources/all/83.jpg'),
        blbl: false,
        dy: false,
        wb: false,
        xhs: true,
    },*/
    {
        name: '@张曼慢慢长胖',
        id: 84,
        introduce: '娱乐圈在逃时尚博主',
        image: cdn('images/starResources/all/84.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@晶晶stunna',
        id: 85,
        introduce: '轻欧美风技术流美妆博主 热辣风格穿搭达人',
        image: cdn('images/starResources/all/85.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@一鮮Poppy/Po痞',
        id: 86,
        introduce: '韩系风时尚辣妈 精致生活博主',
        image: cdn('images/starResources/all/86.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@章馨贝',
        id: 87,
        introduce: '欧美风时尚穿搭达人 双胞胎博主',
        image: cdn('images/starResources/all/87.jpg'),
        blbl: false,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@章馨心Erin',
        id: 88,
        introduce: '服装设计专业的清冷调时尚达人 双胞胎博主',
        image: cdn('images/starResources/all/88.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@是好运鸭',
        id: 89,
        introduce: '表演系生活UP主 综艺感十足的阳光活力美少女',
        image: cdn('images/starResources/all/89.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@阿斗Adou-',
        id: 90,
        introduce: '日系复古高级感 时尚穿搭博主 情侣CP',
        image: cdn('images/starResources/all/90.jpg'),
        blbl: true,
        dy: false,
        wb: true,
        xhs: false,
    },
    {
        name: '@麦麦薯',
        id: 91,
        introduce: '痘肌美妆博主 音乐系毕业的韩系清新女孩',
        image: cdn('images/starResources/all/91.jpg'),
        blbl: false,
        dy: false,
        wb: true,
        xhs: true,
    },
    {
        name: '@甜辣酱Sweetchill',
        id: 92,
        introduce: '圆脸微胖博主 实用分享UP主',
        image: cdn('images/starResources/all/92.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
    {
        name: '@贝儿Belinda',
        id: 93,
        // introduce: 'B站百大UP主 戏精搞笑 邻家亲切',
        image: cdn('images/starResources/all/93.jpg'),
        blbl: true,
        dy: true,
        wb: true,
        xhs: true,
    },
];

export const list = [
    {
        "name": "@莉贝琳",
        "id": 1,
        "introduce": "休闲风干货分享型穿搭达人，口播感染力满满的生活分享博主。",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/1.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "wbUrl": "https://weibo.com/liblin",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5ac33e41e8ac2b4b04fa9106?xhsshare=CopyLink&appuid=5ed8a685000000000101ed84&apptime=1612431649"
    },
/*    {
        "name": "@宝剑嫂",
        "id": 2,
        "introduce": "B站百大UP主 戏精搞笑 邻家亲切",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/2.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "blblUrl": "https://space.bilibili.com/113362335",
        "dy": true,
        "dyUrl": "https://v.douyin.com/JAdwJQv/",
        "wb": true,
        "wbUrl": "https://weibo.com/u/5724402884",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b320418e8ac2b2954dcbb81"
    },*/
    {
        "name": "@彭特务",
        "id": 3,
        "introduce": "敏感肌彩妆换头技术流 TVC质感大片出品",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/3.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "blblUrl": "https://space.bilibili.com/28738201",
        "dy": true,
        "dyUrl": "https://v.douyin.com/JAdK4ts/",
        "wb": true,
        "wbUrl": "https://weibo.com/PENGTEWU6",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5aa77d0e4eacab7662216fa4"
    },
    {
        "name": "@精致大姨夫",
        "id": 4,
        "introduce": "专业护肤产品测评成分党 ",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/4.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "blblUrl": "https://space.bilibili.com/391192292",
        "dy": true,
        "dyUrl": "https://v.douyin.com/JARRqGQ/",
        "wb": true,
        "wbUrl": "https://weibo.com/u/7411343880",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5bc56aaac0f39e0001627d17"
    },
    {
        "name": "@文不叮/Winnie文",
        "id": 5,
        "introduce": "小红书TOP级KOL B站2020百大UP主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/5.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "dy": false,
        "wb": true,
        "wbUrl": "https://weibo.com/u/2033204117",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a4ee75ce8ac2b4cb41b1649?xhsshare=CopyLink&appuid=5e7a22700000000001007fae&apptime=1590752236"
    },
    {
        "name": "@布兰尼老田田",
        "id": 6,
        "introduce": "专业美妆技术流 沉浸式专栏爆款频出",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/6.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": false,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/565f83a40cdc2b0d52360892?xhsshare=CopyLink&appuid=5e7a22700000000001007fae&apptime=1599566174"
    },
    {
        "name": "@小李朝ye",
        "id": 7,
        "introduce": "精分搞笑达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/7.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "dyUrl": "https://v.douyin.com/JARSwBN/",
        "wb": false,
        "wbUrl": "https://weibo.com/u/5227892437",
        "xhs": false,
        "ks": true,
        "ksUrl": "https://v.kuaishou.com/6HblCg"
    },
    {
        "name": "@Vivekatt",
        "id": 8,
        "introduce": "B站百大up 美妆区前三博主 vogue等多家国际时尚杂志合作职业造型师 北欧follower最多的油管博主 北欧&海外lifestyle",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/8.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "blblUrl": "https://space.bilibili.com/16307541",
        "dy": false,
        "wb": true,
        "wbUrl": "https://weibo.com/u/1907184700",
        "xhs": false
    },
    {
        "name": "@二门_",
        "id": 9,
        "introduce": "仿妆博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/9.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "blblUrl": "https://space.bilibili.com/298213453",
        "dy": false,
        "wb": false,
        "xhs": false
    },
    {
        "name": "@小野智恩",
        "id": 10,
        "introduce": "小个子日韩系穿搭达人 亲和力超强的生活分享达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/10.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "blblUrl": "https://v.douyin.com/JAdwV42",
        "dy": true,
        "wb": true,
        "xhs": true,
        "dyUrl": "https://v.douyin.com/JAdwV42/"
    },
    {
        "name": "@王宛尘",
        "id": 11,
        "introduce": "毒蛇宝藏重庆“王子文”轻熟龄肌，分享30+小姐姐质感生活",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/11.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "dyUrl": "https://v.douyin.com/JAdbME2/",
        "wb": true,
        "wbUrl": "https://weibo.com/vanessa924",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5aec1fdfe8ac2b2e40489a40"
    },
    {
        "name": "@笑笑同学啊_",
        "id": 12,
        "introduce": "镜头感超强的前职业摄影师 爆款频出的时尚美妆达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/12.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "wbUrl": "https://weibo.com/xiachenxiao",
        "xhs": false
    },
    {
        "name": "@曾子容",
        "id": 13,
        "introduce": "野生但在努力的美妆博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/13.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "dyUrl": "https://v.douyin.com/JARFuHP/",
        "wb": false,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b0131dc11be102b316a969f"
    },
    {
        "name": "@UG你大哥",
        "id": 14,
        "introduce": "整容级美妆大佬 学生党宠爱的大眼彩妆博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/14.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "blblUrl": "https://space.bilibili.com/387755664",
        "dy": false,
        "dyUrl": "https://v.douyin.com/JAegfef/",
        "wb": false,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5cf1fd44000000001602c2bb",
        "ks": true,
        "ksUrl": "https://v.kuaishou.com/8lQWXx"
    },
    {
        "name": "@满满Cyim",
        "id": 15,
        "introduce": "韩风甜美系穿搭达人 专业彩妆学习经验的时尚博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/15.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/cyiman",
        "dyUrl": "https://v.douyin.com/3XqVY7/",
        "blblUrl": "https://space.bilibili.com/361433786",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/54dd7dba2e1d936d63815da2",
        "ksUrl": "https://v.kuaishou.com/8qPnGf"
    },
    {
        "name": "@鸽鸽dovie",
        "id": 16,
        "introduce": "清纯韩系原相机女孩 淡颜风格彩妆分享家",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/16.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "blblUrl": "https://space.bilibili.com/399041163",
        "dy": true,
        "dyUrl": "https://v.douyin.com/JAebS2d/",
        "wb": true,
        "wbUrl": "https://weibo.com/u/7401945807",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5970495050c4b440fab52a1e"
    },
    {
        "name": "@只小萌",
        "id": 18,
        "introduce": "韩系通勤风穿搭博主 对镜拍教科书 内容超下饭的生活Vlogger",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/18.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "dyUrl": "https://v.douyin.com/3XxtdJ/",
        "wb": true,
        "wbUrl": "https://weibo.com/u/3101275054",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/56ca75b384edcd3659f7a8a3"
    },
    {
        "name": "@杨霞",
        "id": 19,
        "introduce": "时尚辣妈 美妆博主 健身达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/19.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "blblUrl": "https://space.bilibili.com/287121537",
        "dy": true,
        "dyUrl": "https://v.douyin.com/JAR887S/",
        "wb": true,
        "wbUrl": "https://weibo.com/ygxia",
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/59b4de956a6a69158d8e9d72",
        "ksUrl": "https://v.kuaishou.com/7Zfoi0"
    },
    {
        "name": "@汤几一",
        "id": 20,
        "introduce": "日系时尚ICON",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/20.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": false,
        "xhs": true,
        "blblUrl": "https://space.bilibili.com/271872444",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5cff5b5e0000000005001da2?xhsshare=CopyLink&appuid=5e7a22700000000001007fae&apptime=1599566829",
        "dyUrl": "https://v.douyin.com/e5o1sdf/"
    },
    {
        "name": "@晁然然然然",
        "id": 21,
        "introduce": "文艺气质国民学姐 全平台带货达人 明星红人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/21.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/2140998643",
        "blblUrl": "https://space.bilibili.com/479497091",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5ab4baa94eacab1d1256d28f",
        "dyUrl": "https://v.douyin.com/JSKWhTk/"
    },
    {
        "name": "@小魏huhu",
        "id": 22,
        "introduce": "高颜值播音系学姐 欢乐学生党美妆博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/22.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "blblUrl": "https://space.bilibili.com/388486893",
        "wbUrl": "https://weibo.com/u/6617909290",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b60732111be10060e78569e"
    },
    {
        "name": "@顽童大人",
        "id": 23,
        "introduce": "时尚带货小能手 全年销售破亿女装店主 2019微博新锐红人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/23.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/6478549937",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5aa2b8f911be10284d1fe31a",
        "dyUrl": "https://v.douyin.com/JAewush/"
    },
    {
        "name": "@梁一口echo",
        "id": 24,
        "introduce": "B站知名up主 邻家妹妹系生活VLOG分享红人 ",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/24.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/2230278551",
        "blblUrl": "https://space.bilibili.com/13177698",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b3c477be8ac2b6f7b0b58e0",
        "dyUrl": "https://v.douyin.com/JAdCaA9/"
    },
    {
        "name": "@憨娜hannah",
        "id": 25,
        "introduce": "大码时尚穿搭博主 甜美风美妆达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/25.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true
    },
    {
        "name": "@Sasaaaa",
        "id": 26,
        "introduce": "精致生活导师 医美直播带货达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/26.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/superlisa",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/55fdcf4162a60c314e57b2ad",
        "dyUrl": "https://v.douyin.com/JAdwyuY/"
    },
    {
        "name": "@lu.meng",
        "id": 27,
        "introduce": "皇家艺术学院当代艺术研究生 小众有调性穿搭博主 超高艺术审美 女性力量输出",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/27.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/543a03c4d6e4a9068205bed9?xhsshare=CopyLink&appuid=5aa41eac4eacab6a3df4c42b&apptime=1622001086"
    },
    {
        "name": "@邓邓Jt",
        "id": 28,
        "introduce": "时尚邻家大姐姐 购物分享达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/28.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "dyUrl": "https://v.douyin.com/3XWLVH/",
        "wbUrl": "https://weibo.com/u/6455459673",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a72ee1ce8ac2b7f12f4b2eb"
    },
    {
        "name": "@绒耳朵儿",
        "id": 29,
        "introduce": "中性风甜酷穿搭博主 艺术生插画师",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/29.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/3946599572",
        "blblUrl": "https://space.bilibili.com/43610986",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b9f6f33b2e7dc000145640f",
        "dyUrl": "https://v.douyin.com/JAeqqqX/"
    },
    {
        "name": "@腻腻ninii",
        "id": 30,
        "introduce": "超模质感的时髦达人 高奢挚爱，高调品宣",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/30.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7075015508",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5d37ecdf0000000016006505",
        "dyUrl": "https://v.douyin.com/JLjBYmw/"
    },
    {
        "name": "@李璐Gemma",
        "id": 31,
        "introduce": "10年抗痘经验背书的成分党博主 轻欧美系美妆时尚达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/31.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/1857740417",
        "blblUrl": "https://space.bilibili.com/259078238",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a58b3a94eacab59732d153d",
        "dyUrl": "https://v.douyin.com/JARetmr/"
    },
    {
        "name": "@RMIUC",
        "id": 32,
        "introduce": "年近40，年轻保养系博主 大片制造机，深受大牌喜爱 新西兰留学背景 专业素养+高配合度=高质量内容输出",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/32.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5baf16da5e1560000129ed6f?xhsshare=CopyLink&appuid=5ee7093d000000000101c17a&apptime=1626081775",
        "dyUrl": "https://v.douyin.com/equkvmB/"
    },
    {
        "name": "@BB大哥",
        "id": 33,
        "introduce": "港风高级脸 分差鬼马精灵 美妆护肤推荐王",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/33.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "dyUrl": "https://v.douyin.com/3XVuBb/"
    },
    {
        "name": "@怡含怡含",
        "id": 34,
        "introduce": "韩系时尚彩妆分享红人 韩国音乐留学生",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/34.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7192507679",
        "dyUrl": "https://v.douyin.com/JLjVPW3/",
        "blblUrl": "https://space.bilibili.com/267193834",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a168ecc11be1037d2c3e342",
        "ksUrl": "https://v.kuaishou.com/4Ixsgq"
    },
    {
        "name": "@小越小越-",
        "id": 35,
        "introduce": "甜酷美风精致穿搭向导",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/35.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7075591676",
        "blblUrl": "https://space.bilibili.com/267344127",
        "dyUrl": "https://v.douyin.com/JSKc16u/"
    },
    {
        "name": "@Babeei張張-",
        "id": 36,
        "introduce": "时尚穿搭博主 涂鸦小能手 小众好物挖掘机",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/36.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": false,
        "dyUrl": "https://v.douyin.com/3Cw29x/",
        "wbUrl": "https://weibo.com/u/7006403277",
        "blblUrl": "https://space.bilibili.com/239190651",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5bac92a229a7160001d4e3bb"
    },
    {
        "name": "@舟好甜",
        "id": 37,
        "introduce": "行走的小个子穿搭种草机 氧气感四川女孩儿 川式搞笑情侣CPB站百大UP主 戏精搞笑 邻家亲切",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/37.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "dyUrl": "https://v.douyin.com/3XXpP2/",
        "wbUrl": "https://weibo.com/u/6105548227",
        "blblUrl": "https://space.bilibili.com/174436283",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/593cbc1d6a6a694d485208d9"
    },
    {
        "name": "@Rmoong龙龙",
        "id": 38,
        "introduce": "韩系圆脸妹妹 妈生皮原创博主 百变妆容技术流",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/38.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7304339849",
        "blblUrl": "https://space.bilibili.com/46213972",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5501b7bda46e9667b4a8f7e1",
        "dyUrl": "https://v.douyin.com/JAdqVMF/"
    },
    {
        "name": "@琪崽崽",
        "id": 39,
        "introduce": "学生党博主 可爱幼龄感少女",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/39.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": false,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/595a119a82ec390499b5d0da?xhsshare=CopyLink&appuid=5b94945339a6860001cfdf64&apptime=1601264216",
        "ksUrl": "https://v.kuaishou.com/7Q2t6z"
    },
    {
        "name": "@良潇_",
        "id": 40,
        "introduce": "高级感时尚博主 生活vlog分享达人 健身属性 专业彩妆学习经验背书",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/40.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/lyannnn",
        "blblUrl": "https://space.bilibili.com/10038463"
    },
    {
        "name": "@是静静JING_",
        "id": 41,
        "introduce": "韩系色彩穿搭博主 微胖界衣架子 美妆护肤小百科",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/41.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/u/2658528351"
    },
    {
        "name": "@是南希Nancy_",
        "id": 42,
        "introduce": "韩系微胖可爱女孩 笑容治愈",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/42.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/u/6941996923"
    },
    {
        "name": "@上下名",
        "id": 43,
        "introduce": "甜酷腿精种草机 淡颜天花板女孩 视觉美学高端玩家",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/43.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7143381284",
        "dyUrl": "https://v.douyin.com/JYXVyYD/",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5d37ed4800000000100299bf",
        "ksUrl": "https://v.kuaishou.com/4IuFgb",
        "blblUrl": "https://space.bilibili.com/455436639"
    },
    {
        "name": "@-鱼九九-",
        "id": 44,
        "introduce": "复古风时尚穿搭红人 轻敏混油肌彩妆分享达人 96年嫩颜已婚少妇",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/44.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/3736549051",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b57d2efe8ac2b24164ffc55"
    },
    {
        "name": "@男人相",
        "id": 45,
        "introduce": "时尚变美技巧分享小达人 东北迪丽热巴，韩系氛围感美女",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/45.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": false,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/58250e215e87e730dce5c379?xhsshare=CopyLink&appuid=5e7a22700000000001007fae&apptime=1599540151"
    },
    {
        "name": "@喜悦姑娘Clara",
        "id": 46,
        "introduce": "lifestyle blogger 精致高调性时尚穿搭博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/46.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/5988018282",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/596f20d582ec3965e7d47959?xhsshare=CopyLink&appuid=5e7a22700000000001007fae&apptime=1599539767",
        "blblUrl": "https://space.bilibili.com/473881702"
    },
    {
        "name": "@MC萱萱RubyLi",
        "id": 47,
        "introduce": "海归双语主持人 时尚优雅高知博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/47.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/5344170396",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/598fe73e5e87e70500b899af?xhsshare=CopyLink&appuid=5a17973e4eacab6be06b9771&apptime=1609404664",
        "dyUrl": "https://v.douyin.com/eBLBFRg/",
        "blblUrl": "https://space.bilibili.com/96815887/?share_source=copy_link&share_medium=iphone&bbid=Z148581490E7065F4A16907F63820C8FBED4&ts=1609404924"
    },
    {
        "name": "@小马晕晕yunn",
        "id": 48,
        "introduce": "韩风时尚分享红人 恋爱CP属性",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/48.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/59e8133c11be103d3d4267b2",
        "dyUrl": "https://v.douyin.com/JAeqY9x/",
        "wbUrl": "https://weibo.com/u/7333106952",
        "blblUrl": "https://space.bilibili.com/456756480"
    },
    {
        "name": "@陶四七-",
        "id": 49,
        "introduce": "同济大学毕业的艺术系高知美女 高奢宠爱的行走画报 高甜撒糖cp",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/49.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7347314025",
        "blblUrl": "https://space.bilibili.com/21955179",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a44af6ce8ac2b0edc365821"
    },
    {
        "name": "@元气菊仔",
        "id": 50,
        "introduce": "热爱美食的肌肉女孩 爆款频出的健身达人 口播风格超嗨的分享型博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/50.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7327681493",
        "blblUrl": "https://space.bilibili.com/2613918",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a8ace53e8ac2b4dc50a6cf9"
    },
    {
        "name": "@有竹-",
        "id": 51,
        "introduce": "高级复古精致脸，高奢宠爱 精致调性生活，传递独特的生活方式 新中式风格KOL ",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/51.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7214142909",
        "blblUrl": "https://space.bilibili.com/29298433",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a80eeac4eacab18ef2790d7"
    },
    {
        "name": "@桃瑞思",
        "id": 52,
        "introduce": "东华大学毕业的高颜值时髦博主  集\"高奢\"与\"生活\"于一身的分享达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/52.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7347311656",
        "dyUrl": "https://v.douyin.com/JSKg33f/"
    },
    {
        "name": "@林零亿",
        "id": 53,
        "introduce": "精致微胖穿搭博主 口播带货达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/53.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "ksUrl": "https://v.kuaishou.com/6INELg"
    },
    {
        "name": "@沈大美Shirine",
        "id": 54,
        "introduce": "趣味生活vlog创作者 TVC女主脸博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/54.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "dyUrl": "https://v.douyin.com/JARSxQN/",
        "wbUrl": "https://weibo.com/u/2693420092",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/544d0c4ed6e4a977c7e60940",
        "ksUrl": "https://v.kuaishou.com/55Ob6Z"
    },
/*    {
        "name": "@瘦瘦璐-",
        "id": 55,
        "introduce": "清冷氛围感美女 时尚穿搭博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/55.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/2933424717",
        "ksUrl": "https://v.kuaishou.com/6X1epc",
        "blblUrl": "https://space.bilibili.com/478634034",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/565b15a903eb846d12ef217d",
        "dyUrl": "https://v.douyin.com/JAeX5xx/"
    },*/
    {
        "name": "@美希Mazing",
        "id": 56,
        "introduce": "配音演员级美妆妆教大佬 护肤干货专家 微胖时尚穿搭达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/56.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "blblUrl": "https://space.bilibili.com/24145122",
        "wbUrl": "https://weibo.com/eliprincess",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a6364ea4eacab61add6ebd9?xhsshare=CopyLink&appuid=5e7a22700000000001007fae&apptime=1591694381",
        "dyUrl": "https://v.douyin.com/eoPVQ6Q/"
    },
    {
        "name": "@喜大牙",
        "id": 57,
        "introduce": "149cm小个子日系鬼马可爱少女",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/57.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/2316122521",
        "blblUrl": "https://space.bilibili.com/282461654",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a55960811be107627c7ec88",
        "dyUrl": "https://v.douyin.com/JAecT7m/"
    },
    {
        "name": "@超级轩-",
        "id": 58,
        "introduce": "甜盐系高颜少女 时尚穿搭达人 知名美妆VLOG博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/58.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": false,
        "blblUrl": "https://space.bilibili.com/317965577"
    },
    {
        "name": "@山生",
        "id": 59,
        "introduce": "日系清新氧气小美女 天生好白皮",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/59.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a8050a0e8ac2b65b746af8b"
    },
    {
        "name": "@帕尔哈迪迪",
        "id": 60,
        "introduce": "日美潮流穿搭男博主 口播一流综艺感十足的玩梗达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/60.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "dyUrl": "https://v.douyin.com/3CKPe9/",
        "wbUrl": "https://weibo.com/cadyzone",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5c8103de000000001202aaed",
        "ksUrl": "https://v.kuaishou.com/6rttDV",
        "blblUrl": "https://space.bilibili.com/246326806"
    },
    {
        "name": "@Tuitui_",
        "id": 61,
        "introduce": "单眼皮换脸美妆博主 英国NTU 时尚管理IFM",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/61.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": false,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/55aed89c62a60c596e626148"
    },
    {
        "name": "@羊大锅锅",
        "id": 62,
        "introduce": "日系中性风穿搭博主少年感少女感并存艺术生插画师",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/62.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/3189361685",
        "blblUrl": "https://space.bilibili.com/96266769",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a852e4311be10211cdc2ab6",
        "dyUrl": "https://v.douyin.com/JARRdA4/"
    },
    {
        "name": "@大七仔呀",
        "id": 63,
        "introduce": "小众时尚穿搭达人 外冷内热傻大妞",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/63.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/6882318661",
        "blblUrl": "https://space.bilibili.com/395578743",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5c1b1ca80000000005025751",
        "dyUrl": "https://v.douyin.com/JSKKoQS/"
    },
    {
        "name": "@LiLi长不大",
        "id": 64,
        "introduce": "美妆技术流 购物小天才 微胖穿搭博主 情侣CP",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/64.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/5036476546",
        "blblUrl": "https://space.bilibili.com/324497942",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/56c9d6a54775a75a36408cd7",
        "dyUrl": "https://v.douyin.com/JAeovf5/"
    },
    {
        "name": "@一只梁不了",
        "id": 65,
        "introduce": "B站美妆生活UP主 清冷优雅精致生活的vlogger 医美顾问属性",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/65.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/u/2101427611",
        "blblUrl": "https://space.bilibili.com/43618809",
        "dyUrl": "https://v.douyin.com/JAeCHFN/",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b32010511be107cf599da03?xhsshare=CopyLink&appuid=5ac9fee8e8ac2b7458dc971a&apptime=1634007902"
    },
    {
        "name": "@饶萌呀",
        "id": 66,
        "introduce": "圆脸可爱系美妆博主 招猫逗狗的铲屎官 摄影能手",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/66.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/2268084821",
        "blblUrl": "https://space.bilibili.com/320164244",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/55fa9b59b7ba2258ce0fc911"
    },
    {
        "name": "@杨十一",
        "id": 67,
        "introduce": "高级感轻法式时尚博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/67.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/56ee40ee4775a70646bdab01"
    },
    {
        "name": "@陈鱼白",
        "id": 68,
        "introduce": "140斤微胖穿搭红人 显瘦穿搭高手",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/68.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/5263651048",
        "dyUrl": "https://v.douyin.com/JNCCKTh/",
        "blblUrl": "https://space.bilibili.com/473162216",
        "ksUrl": "https://v.kuaishou.com/7uVnTY",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a8a3b1e11be100595f3d563?xhsshare=CopyLink&appuid=5ac5fbd911be10148a93fa89&apptime=1601202855"
    },
    {
        "name": "@全智鹅",
        "id": 69,
        "introduce": "韩系鬼马少女 综艺感十足的分享种草机",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/69.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/6975501107",
        "blblUrl": "https://space.bilibili.com/406745567",
        "dyUrl": "https://v.douyin.com/JLj62Bd/",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/56ca6a6184edcd7e46b78e4a"
    },
    {
        "name": "@柒七Kylin",
        "id": 70,
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/70.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": false,
        "xhs": false,
        "blblUrl": "https://space.bilibili.com/2024816"
    },
    {
        "name": "@吕根lg",
        "id": 71,
        "introduce": "痞帅的阳光大男孩  时尚穿搭的教科书 生活分享型博",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/71.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/u/5035241021",
        "dyUrl": "https://v.douyin.com/JAdpHB9/"
    },
    {
        "name": "@一颗七崽",
        "id": 72,
        "introduce": "日韩风时尚穿搭彩妆分享红人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/72.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/hhhhuying",
        "blblUrl": "https://space.bilibili.com/383467617",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a7ac5d4e8ac2b5486003105",
        "ksUrl": "https://v.kuaishou.com/8hk9Ou",
        "dyUrl": "https://v.douyin.com/JAeCukc/"
    },
    {
        "name": "@戴黎婷",
        "id": 73,
        "introduce": "微电影感高颜值女主 甜酷穿搭 甜蜜情侣CP",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/73.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "dyUrl": "https://v.douyin.com/3CoLsY/",
        "wbUrl": "https://weibo.com/u/6900530553",
        "blblUrl": "https://space.bilibili.com/404173732",
        "ksUrl": "https://v.kuaishou.com/5Uct5d",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a3736b111be100f7ff8c077"
    },
    {
        "name": "@Elin_顾零一",
        "id": 74,
        "introduce": "小众甜酷穿搭博主 美院毕业野生造型师 高艺术调性",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/74.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7003340825",
        "blblUrl": "https://space.bilibili.com/436755002",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b174d88e8ac2b75286761f8",
        "ksUrl": "https://v.kuaishou.com/5Haq1Y"
    },
    {
        "name": "@蒋橙橙KZ",
        "id": 75,
        "introduce": "微博时尚美妆博主 VLOG短视频达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/75.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/6553130389",
        "blblUrl": "https://space.bilibili.com/12761478",
        "dyUrl": "https://v.douyin.com/JAdxupM/",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5549e88c4fac6365035a8532"
    },
    {
        "name": "@是妖妖阿",
        "id": 76,
        "introduce": "精分鬼马美妆博主 美妆护肤干货分享达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/76.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/u/2851737004",
        "blblUrl": "https://space.bilibili.com/11897578",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5aaa110f11be10028dbf92cc?xhsshare=CopyLink&appuid=5bac92a229a7160001d4e3bb&apptime=1625476503"
    },
    {
        "name": "@刘梓萌嗎",
        "id": 77,
        "introduce": "欧美甜酷辣妹 时尚美妆 医美达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/77.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/u/2014482332",
        "dyUrl": "https://v.douyin.com/JLjaahx/"
    },
    {
        "name": "@BooBoo_蛋清",
        "id": 78,
        "introduce": "甜酷复古穿搭 小众好物挖掘机",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/78.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7118087170",
        "blblUrl": "https://space.bilibili.com/13010935",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5c45a6930000000005024c67"
    },
    {
        "name": "@D登登",
        "id": 79,
        "introduce": "大码微胖轻熟风姐姐 变装小达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/79.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/lesz888bian",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5c1b6d7b0000000005035cb0"
    },
    {
        "name": "@Lsy秦公子",
        "id": 80,
        "introduce": "美妆好物分享达人 时尚穿搭博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/80.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/3919689917",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/56fd10501c07df1f0a5bf286"
    },
    {
        "name": "@五十青",
        "id": 81,
        "introduce": "韩风浓颜系 时尚好物安利 生活vlog分享",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/81.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/2313791075",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/53c4c6cab4c4d63304f8d091",
        "dyUrl": "https://v.douyin.com/JSKsD6Y/"
    },
    {
        "name": "@铁一WENDYZ",
        "id": 82,
        "introduce": "可盐可甜的美妆技术流 氛围感清冷美女 韩国留学时尚达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/82.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/u/3214017907",
        "blblUrl": "https://space.bilibili.com/12968717",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5948cdac6a6a6902734f31a8"
    },
    {
        "name": "@满杯谌谌",
        "id": 83,
        "introduce": "初恋脸甜酷女孩 小个子穿搭达人 写真分享元祖博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/83.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": false,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5899b8735e87e716a722ed29"
    },
    {
        "name": "@张曼慢慢长胖",
        "id": 84,
        "introduce": "娱乐圈在逃时尚博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/84.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/6118471196",
        "blblUrl": "https://space.bilibili.com/359126602",
        "dyUrl": "https://v.douyin.com/JSKp3kN/",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5aeafd18438b7f6872bf4dea?xhsshare=CopyLink&appuid=5bac92a229a7160001d4e3bb&apptime=1615280382"
    },
    {
        "name": "@晶晶stunna",
        "id": 85,
        "introduce": "轻欧美风技术流美妆博主 热辣风格穿搭达人",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/85.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/2293171063",
        "blblUrl": "https://space.bilibili.com/25517109",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5573e9ef484fb66346e5a5bc"
    },
    {
        "name": "@一鮮Poppy/Po痞",
        "id": 86,
        "introduce": "韩系风时尚辣妈 精致生活博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/86.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "blblUrl": "https://space.bilibili.com/407027041"
    },
    {
        "name": "@章馨贝",
        "id": 87,
        "introduce": "欧美风时尚穿搭达人 双胞胎博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/87.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/6918911574",
        "blblUrl": "https://space.bilibili.com/401316210",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5bc1be7ee4e0c6000183975b",
        "ksUrl": "https://v.kuaishou.com/8MOI2a",
        "dyUrl": "https://v.douyin.com/JSKG8Uu/"
    },
    {
        "name": "@章馨心Erin",
        "id": 88,
        "introduce": "服装设计专业的清冷调时尚达人 双胞胎博主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/88.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/6918898167",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5c65aa0e00000000110356f7",
        "ksUrl": "https://v.kuaishou.com/6toT9A"
    },
    {
        "name": "@是好运鸭",
        "id": 89,
        "introduce": "表演系生活UP主 综艺感十足的阳光活力美少女",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/89.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5a111f3f11be106b69c6beb8?xhsshare=CopyLink&appuid=5c65824f0000000012022916&apptime=1605691644",
        "blblUrl": "https://space.bilibili.com/151086459"
    },
    {
        "name": "@阿斗Adou-",
        "id": 90,
        "introduce": "日系复古高级感 时尚穿搭博主 情侣CP",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/90.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": false,
        "wb": true,
        "xhs": false,
        "wbUrl": "https://weibo.com/u/7032445736",
        "blblUrl": "https://space.bilibili.com/298465263"
    },
    {
        "name": "@麦麦薯",
        "id": 91,
        "introduce": "痘肌美妆博主 音乐系毕业的韩系清新女孩",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/91.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": false,
        "dy": false,
        "wb": true,
        "xhs": true,
        "wbUrl": "https://weibo.com/u/7336487347",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/5b83916f233b200001a1ab0d"
    },
    {
        "name": "@甜辣酱Sweetchill",
        "id": 92,
        "introduce": "圆脸微胖博主 实用分享UP主",
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/92.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "blblUrl": "https://b23.tv/fAwcp0",
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/55d68215e4b1cf6a8998459d?xhsshare=CopyLink&appuid=5ac9fee8e8ac2b7458dc971a&apptime=1628242365"
    },
    {
        "name": "@贝儿Belinda",
        "id": 93,
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/93.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "blbl": true,
        "dy": true,
        "wb": true,
        "xhs": true,
        "xhsUrl": "https://www.xiaohongshu.com/user/profile/54edf3dcd39ea2559b23c57f?xhsshare=CopyLink&appuid=59a119b15e87e75f3dcc4def&apptime=1623043690",
        "blblUrl": "https://space.bilibili.com/458115110?from=search&seid=4686865926235948191"
    },
    {
        "name": "@曾永仪",
        'introduce': '时尚调性高，表现力好，出片率高，配合度高。',
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/94.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "id": 94,
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": 'https://weibo.com/u/7005372777',
        "xhsUrl": 'https://www.xiaohongshu.com/user/profile/5d4d34d3000000001102829a?xhsshare=CopyLink&appuid=5a06d0914eacab248e3528bd&apptime=1670400013',
        "dyUrl": 'https://v.douyin.com/hFq8qJS',
    },
    {
        "name": "@笑笑同学啊",
        'introduce': '爆款频出的美妆时尚博主，超强种草力，擅长“化妆换头”。',
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/95.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "id": 95,
        "blbl": false,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": 'https://weibo.com/xiachenxiao',
        "xhsUrl": 'https://www.xiaohongshu.com/user/profile/59e0b13c20e88f68e8af29a0?',
        "dyUrl": 'https://www.douyin.com/user/MS4wLjABAAAAowS33O7a-woQaxZv0jyS3P9wPa_Bv20SXV5hD3wUGa4',
    },
    {
        "name": "@ug你大哥",
        'introduce': '干货满满的美妆“换头”博主，整容级妆教一绝。',
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/96.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "id": 96,
        "blbl": true,
        "xhs": true,
        "blblUrl": "https://b23.tv/PcApuFb",
        "xhsUrl": 'https://www.xiaohongshu.com/user/profile/5cf1fd44000000001602c2bb?',
    },
    {
        "name": "@半途鹅飞",
        'introduce': '美妆、护肤、穿搭、颜值、学习、发型全面开花；数据又好又稳定；不拖稿，配合度超高。',
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/97.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "id": 97,
        "dy": true,
        "wb": true,
        "xhs": true,
        "wbUrl": 'https://weibo.com/u/5022247375',
        "xhsUrl": 'https://www.xiaohongshu.com/user/profile/5ac073f111be10046a0a023e?xhsshare=CopyLink&appuid=5bbaef1a18c19a00013c6cf1&apptime=1624604167',
        "dyUrl": 'https://www.douyin.com/user/MS4wLjABAAAAVd6KTgcq-u8KI2Tbvd0WzQO4YlOd4maHR9Z5Xogkt3i_Qtfm_Vvcvx594q8YSBXT',
    },
    {
        "name": "@張人0",
        'introduce': '高颜值氛围感帅哥；爆款话题制造者，万赞达人；氛围感制造者。',
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/98.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "id": 98,
        "xhs": true,
        "xhsUrl": 'https://www.xiaohongshu.com/user/profile/5c558542000000001000bdc4?xhsshare=CopyLink&appuid=54f49a0c2e1d935c7294cd7d&apptime=1597742795',
    },
    {
        "name": "@Babeei张张",
        'introduce': '风格突出，粉丝黏性高，很会穿的全能好物种草机。',
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/99.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "id": 99,
        "xhs": true,
        "xhsUrl": 'https://www.xiaohongshu.com/user/profile/5bac92a229a7160001d4e3bb',
    },
    {
        "name": "@兔染染Summer",
        'introduce': '变美百科全书、美白爆文制造机、韩国梨大学生、氛围感美女。',
        "image": "https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/starResources/all/100.jpg?x-oss-process=image/resize,w_800/auto-orient,1",
        "id": 100,
        "xhs": true,
        "xhsUrl": 'https://www.xiaohongshu.com/user/profile/5ab89dbf4eacab4d1bc90e8a?xhsshare=CopyLink&appuid=5f733e070000000001009b90&apptime=1669190794',
    },
];

export const otherKol = {
    "@文不叮/Winnie文": "Winnie文",
    "@Vivekatt": "vivekatt",
    "@二门_": "二门",
    "@笑笑同学啊_": "笑笑同学啊",
    "@温婉Wenwan_": "温婉",
    "@晁然然然然": "晁然",
    "@憨娜hannah": "憨娜Hannah",
    "@lu.meng": "luson妈",
    "@邓邓Jt": "邓邓",
    "@李璐Gemma": "李璐",
    "@小越小越-": "小越小越",
    "@Babeei張張-": "Babeei張張",
    "@Rmoong龙龙": "rmoong龙龙",
    "@良潇_": "良潇",
    "@-鱼九九-": "鱼九九",
    "@小马晕晕yunn": "小马晕晕",
    "@陶四七-": "陶四七",
    "@有竹-": "有竹",
    "@沈大美Shirine": "沈大美",
    "@瘦瘦璐-": "瘦瘦璐",
    "@超级轩-": "超级轩",
    "@Tuitui_": "tuitui",
    "@是妖妖阿": "是妖妖阿-",
    "@D登登": "登登",
    "@铁一WENDYZ": "铁一",
    "@晶晶stunna": "晶晶",
    "@一鮮Poppy/Po痞": "一鲜poppy",
    "@章馨心Erin": "章馨心",
    "@阿斗Adou-": "阿斗Adou",
    "@麦麦薯": "麦麦麦里",
    "@是静静JING_": "静静和nancy",
    "@是南希Nancy_": "静静和nancy",
    "@葡萄-Lee": "葡萄lee",
    "@張人0": "张人0",
}

/*export const otherKol = [
    {lock: '@文不叮/Winnie文', server: 'Winnie文'},
    {lock: '@Vivekatt', server: 'vivekatt'},
    {lock: '@二门_', server: '二门'},
    {lock: '@笑笑同学啊_', server: '笑笑同学啊'},
    {lock: '@温婉Wenwan_', server: '温婉'},
    {lock: '@晁然然然然', server: '晁然'},
    {lock: '@憨娜hannah', server: '憨娜Hannah'},
    {lock: '@lu.meng', server: 'luson妈'},
    {lock: '@邓邓Jt', server: '邓邓'},
    {lock: '@李璐Gemma', server: '李璐'},
    {lock: '@小越小越-', server: '小越小越'},
    {lock: '@Babeei張張-', server: 'Babeei張張'},
    {lock: '@Rmoong龙龙', server: 'rmoong龙龙'},
    {lock: '@良潇_', server: '良潇'},
    {lock: '@是静静JING_', server: undefined}, // 静静和nancy
    {lock: '@是南希Nancy_', server: undefined},
    {lock: '@-鱼九九-', server: '鱼九九'},
    {lock: '@小马晕晕yunn', server: '小马晕晕'},
    {lock: '@陶四七-', server: '陶四七'},
    {lock: '@有竹-', server: '有竹'},
    {lock: '@沈大美Shirine', server: '沈大美'},
    {lock: '@瘦瘦璐-', server: '瘦瘦璐'},
    {lock: '@超级轩-', server: '超级轩'},
    {lock: '@Tuitui_', server: 'tuitui'},
    {lock: '@是妖妖阿', server: '是妖妖阿-'},
    {lock: '@D登登', server: '登登'},
    {lock: '@铁一WENDYZ', server: '铁一'},
    {lock: '@满杯谌谌', server: undefined},
    {lock: '@张曼慢慢长胖', server: undefined},
    {lock: '@晶晶stunna', server: '晶晶'},
    {lock: '@一鮮Poppy/Po痞', server: '一鲜poppy'},
    {lock: '@章馨心Erin', server: '章馨心'},
    {lock: '@是好运鸭', server: undefined},
    {lock: '@阿斗Adou-', server: '阿斗Adou'},
    {lock: '@麦麦薯', server: '麦麦麦里'},
    {lock: '@甜辣酱Sweetchill', server: undefined},
    {lock: '@贝儿Belinda', server: undefined},
]*/

/*
function check() {
    const data = []
    fetch('https://www.layercake18.com/drp/data/mall/common/kol/fans')
        .then(response => response.json())
        .then(res => {
            console.log(res.result)
            list.forEach(item => {
                console.log(item.name.slice(1,item.name.length))
                const is = res.result.some(i =>  i.kolNick === item.name.slice(1, item.name.length));
                if (!is) {
                    const {kolNick} = res.result.find(i =>  i.kolNick.indexOf(item.name.slice(1,3)) > -1) || {};

                    data.push({lock: item.name, server: kolNick})
                }
            })
            window.data= data
            console.log(data, 'data')
        })
}

check();*/
