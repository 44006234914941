import React,{ Component } from 'react'
import './title.css'
class title extends Component {
    render() {
        return (
            <div className='title'>
                {this.props.title}
            </div>
        )
    }
}

export default title;