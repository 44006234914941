import React,{Component} from "react";

class Number extends Component{
    state = {
        interval: null,
    }
    componentDidMount(){
        const {step, data} = this.props;
        const time = this.data.innerText;
        const decimal = time.toString().split('.')[1] ? time.toString().split('.')[1].length : 0;
        let count = 0;
        const interval = setInterval(
            () => {
              const now = decimal ? count / Math.pow(10, decimal) : count;
            //   console.log(count, time, now, decimal)
              this.data.innerText = now.toFixed(decimal)
              if(now >= time*1) {
                  clearInterval(interval)
                  this.data.innerText = data;
                  this.setState({
                      interval: null
                  })
              }
              step ? count+= step : count++;
            }, this.props.time
        )
        this.setState({
            interval
        })
        // console.log("data", this.data)
    }
    componentWillUnmount() {
        if(this.state.interval) {
            clearInterval(this.state.interval)
            this.setState({
                interval: null
            })
        }
    }
    render() {
        return(
            <span className='number' ref={(data) => {this.data = data}}>{this.props.data}</span>
        )
    }
}

export default Number