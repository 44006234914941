import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

export const banner = cdn('images/marketing/banner.png', 1600);
export const cast1 = cdn('images/marketing/case-1.png');
export const cast2 = cdn('images/marketing/case-2.png');
export const cast3 = cdn('images/marketing/case-3.png');
export const cast4 = cdn('images/marketing/case-4.png');
export const cast5 = cdn('images/marketing/case-5.png');
export const cast6 = cdn('images/marketing/case-6.png');

export const cast7 = cdn('images/marketing/case-7.png');
export const cast8 = cdn('images/marketing/case-8.png');
export const cast9 = cdn('images/marketing/case-9.png');
export const cast10 = cdn('images/marketing/case-10.png');
export const cast11 = cdn('images/marketing/case-11.png');
export const cast12 = cdn('images/marketing/case-12.png');

export const anchor = cdn('images/marketing/anchor.png');
export const douin = cdn('images/marketing/douyin.png');
export const redBook = cdn('images/marketing/redBook.png');
export const weibo = cdn('images/marketing/weibo.png');
export const bilibili = cdn('images/marketing/bilibili.png');
export const kuaishou = cdn('images/marketing/kuaishou.png');
export const taobao = cdn('images/marketing/taobao.png');

export const cooperationMode = [
    {
        image:cdn('images/marketing/ways-1.png', 1000),
        content: {
            title: '纯佣模式',
            desc: '不收取坑位费、制作费等相关费用，仅抽取商品售卖金额的固定百分比作为佣金',
        },
        text: {
            title: '适用于：',
            desc: '合作达人量级小；品牌声量极高、直播价格机制有极大优势'
        }
    },
    {
        image:cdn('images/marketing/ways-2.png', 1000),
        content: {
            title: '坑位费+佣金模式',
            desc: '达人收取一定开播费用，用于直播投放，增加直播间人气，增加商品曝光及更多转化',
        },
        text: {
            title: '适用于：',
            desc: '主播资深带货经验、销量效果较稳定，在直播的同时品宣卖点实现品销合一，适合品牌类客户'
        }
    },
    {
        image:cdn('images/marketing/ways-3.png', 1000),
        content: {
            title: '全案模式',
            desc: '公司进行品效全案制作，其中包含：种草、品宣、带货、口碑沉淀等全运营链路，安排头部红人、中腰部红人、上升期红人参与全案合作，对效果负责',
        },
        text: {
            title: '适用于：',
            desc: '处于上升期需打开市场的新锐品牌、处于成熟期品牌新品系列上市'
        }
    },
    {
        image:cdn('images/marketing/ways-4.png', 1000),
        content: {
            title: '季度框&年框模式',
            desc: '签署季度或年度框架，在规定周期内达成固定销售金额、合作场次，相应费用从合同内扣除',
        },
        text: {
            title: '适用于：',
            desc: '红人深度合作品牌商家、处于稳定期长期投放品牌'
        }
    },
    {
        image:cdn('images/marketing/ways-5.png', 1000),
        content: {
            title: '矩阵式种草+商业化变现',
            desc: '不收取坑位费、制作费等相关费用，仅抽取商品售卖金额的固定百分比作为佣金。',
        },
        text: {
            title: '适用于：',
            desc: '抖音，有铺量品宣+商业化的需求；产品具有明显前后对比效果的；对于我方产出的优质内容有推广预算的品牌；受年轻大众市场欢迎的品牌；'
        }
    }];

export const arrow = cdn('images/marketing/arrow.png');
export const enter = "http://www.163.com";
export const logos = cdn('images/marketing/logos.png');
export const showAnchor =  cdn('images/marketing/showAnchor.png');

let arrayList = []
for (let i = 1; i < 31; i++) {
    arrayList.push(cdn(`images/marketing/logo-${i}.png`))
}

export const tabsList = [
    {
        name: '@奇怪的奇',
        avatarUrl: cdn(`images/marketing/content-1.jpeg`, 500),
        title: '抖音电商优质达人，美妆唇类种草机',
        content: '彩妆直播带货能力强，单场销售额10W+，单品销售量25w+，累积挂车gmv破75w',
        socialList: [{typeUrl: douin, num: '32.9w+'}, {typeUrl: redBook, alt: '抖音', num: '42w'}, {typeUrl: weibo, num: '228w'}, {typeUrl: bilibili, num: '24w'}, ]
    },
    {
        name: '@小李朝ye',
        avatarUrl: cdn(`images/marketing/content-2.jpeg`, 500),
        title: '抖音剧情类达人，全网搞笑达人TOP3，搞笑画风，金句频出，直播带货能力强。',
        content: '2019年洛杉矶时报专栏刊登，2019-2020克劳镜最具影响力幽默搞笑自媒体；单品销售量12000+件。2022年9、10双月小号短视频总GMV2000w+，单品销量5w+,商品曝光量3亿+,总播放量2亿+',
        socialList: [{typeUrl: douin, num: '1116w'}, {typeUrl: redBook, num: '13w'}, {typeUrl: weibo, num: '73.3'}, {typeUrl: kuaishou, num: '666.3w'}, ]
    },
    {
        name: '@莉贝琳',
        avatarUrl: cdn(`images/marketing/content-3.png`, 500),
        title: '10年淘宝店主经验，多年穿搭干货输出，纠正普通人的穿搭误区。',
        content: '全网粉丝影响力490w＋，多平台矩阵构建，37岁挑战更年轻的穿搭； 显瘦穿法、配饰细节，一个单品拯救一套穿搭； 各种风格、所有季节，没有解决不了的场景搭配； 内容作品涉及穿搭向、购物分享向、人生经验分享向。',
        socialList: [{typeUrl: douin, num: '4w+'}, {typeUrl: redBook, num: '102w+'}, {typeUrl: weibo, num: '188w+'}, {typeUrl: taobao, num: '196w+'}, ]
    },
  /*  {
        name: '@晁然然然然',
        avatarUrl: cdn(`images/marketing/content-4.jpeg`, 500),
        title: '国民学姐，知性温柔富有感染力； 带货达人，生活分享好物安利。',
        content: '国民学姐，中国航天局好物推荐官。社交平台粉丝覆盖总量660w＋，主要活跃微博、抖音、小红书平台；镜头感强，变现力佳，品宣质量高；与淘宝平台直播合作，每周五进行团购日直播专场。',
        socialList: [{typeUrl: douin, num: '245.5w'}, {typeUrl: redBook, num: '70.4w'}, {typeUrl: weibo, num: '171.6w'}, {typeUrl: taobao, num: '130w'}, ]
    },*/
    {
        name: '@精致大姨夫',
        avatarUrl: cdn(`images/marketing/content-5.jpeg`, 500),
        title: '专业护肤产品测评成分党、真实测评上万款护肤品、深耕护肤垂类行业17年。',
        content: '2021淘美妆年度最具影响力KOL；2021美伊最具影响力匠人创客大奖；2021千瓜小红书全领域最具影响力创作。参加小红书国货节拍摄，作为“国货推荐官”登上小红书开屏。',
        socialList: [{typeUrl: douin, num: '47w+'}, {typeUrl: redBook, num: '150w+'}]
    },
    {
        name: '@周嘎嘎儿',
        avatarUrl: cdn(`images/marketing/content-06.png`, 500),
        title: '搞笑生活类博主 短视频挂车种草机',
        content: '短视频挂车30天冲牙器单品gmv 10w+；单场户外直播单品累计销量2000+；防晒衣身体素颜霜等多个单品橱窗累计gmv 80w+',
        socialList: [{typeUrl: weibo, num: '435.9w'}, {typeUrl: taobao, num: '267w'}]
    },
    {
        name: '@彩妆师幽幽',
        avatarUrl: cdn(`images/marketing/content-07.png`, 500),
        title: '抖音美妆类达人，垂类底妆种草机，干货满满，搞笑画风，直播带货能力强',
        content: '一个月素人起号，单场直播最高在线突破1.7W，销售额突破11W，双月销售200W+，其中单品单场销售4W+，精准底妆类直播带货。',
        socialList: [{typeUrl: douin, num: '18.9w+'}, {typeUrl: redBook, num: '13.4w'}, {typeUrl: weibo, num: '336w+'}, {typeUrl: bilibili, num: '376w+'}, ]
    },

    {
        name: '@无糖豆豆xy',
        avatarUrl: cdn(`images/marketing/content-08.png`, 500),
        title: '抖音美妆种草达人，韩系元气美女，行走的唇部种草机',
        content: '10月单月挂车gmv达到100w；单品牌单品成交gmv165w+；优质种草视频单条播放量1500w+，成交gmv达到100w+。',
        socialList: [{typeUrl: redBook, num: '96.8w'}]
    },
    {
        name: '@温婉Wenwan_',
        avatarUrl: cdn(`images/marketing/content-9.jpeg`, 500),
        title: '时尚穿搭ICON，独树一帜的审美引领粉丝，颜值加持，美妆护肤两不误。',
        content: '全网粉丝1371.8w＋，直播带货达人，抖音最具商业价值颜值红人，抖音现象级红人； 视频类型涉及穿搭分享、美妆好物、生活vlog、爱用分享、变美干货等内容方向； 品牌专场直播单场销售额100w＋，销售量12000＋件。 微博2020十大影响力颜值大v； 2019年度带货红人； 微博2019最具商业价值颜值红人。',
        socialList: [{typeUrl: douin, num: '658w'}, {typeUrl: weibo, num: '385w'}, {typeUrl: kuaishou, num: '261.7w'}, ]
    }
]



export const logoList = arrayList
