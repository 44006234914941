import React, { Component, Fragment } from 'react';
import { InView } from 'react-intersection-observer';
import Header from '../../layout/header/index';
import Footer from '../../layout/footer';
import Describe from './describe';
import './starResources.css';
import Introduce from './personIntroduce';
import useAssets from '../../../common/useAssets';
import {Subtract} from "../home/data";
import classNames from "classnames";
const cdn = useAssets(false);

class page extends Component {
  constructor() {
    super();
    this.state = {
      choose: 'all',
      headImgSrc: cdn('images/resources/banner.png', 2000),
      describe: {
        title: '全平台海量红人资源',
        content: `如涵成功孵化200+知名KOL，并涵盖了业界各领域2000+红人资源\n具有领先的市场地位、成熟的网红培育体系\n如涵旗下KOL分布于多个女性消费垂直领域\n对互联网消费主流类目形成全覆盖\n精准覆盖18-35岁年轻女性粉丝，粉丝群体广泛、粘性强`,
        honor: [
          {
            title: '独家KOL影响力',
            data: '4.71',
            unit: '亿',
            time: 0,
            step: 2,
          },
          {
            title: '达人全网影响力',
            data: '34.13',
            unit: '亿',
            time: 0,
            step: 12,
          }
        ]
      },
      matrix: {
        title: '如涵红人矩阵',
        list: [
          {
            key: 'all',
            value: '全平台',
          },
          {
            key: 'wb',
            value: '微博',
          },
          {
            key: 'dy',
            value: '抖音',
          },
          {
            key: 'xhs',
            value: '小红书',
          },
          {
            key: 'blbl',
            value: 'B站',
          }
        ]
      }
    };
  }
  componentDidMount() {
    document.title = '如涵红人资源-如涵官网';
  }
  render() {
    const { headImgSrc, describe, matrix, choose } = this.state;
    return (
      <Fragment>
        <div className='flexccc resources'>
          <Header bg={{ background: '#333' }} />
          <div className='nav-head-img'>
            <img style={{ width: '100%'}} src={headImgSrc} alt='图片' />
            <div className='nav-head-text'>
              <img alt="arrow" src={cdn(`images/resources/banner-text.png`)} />
            </div>
          </div>
          <Describe src={headImgSrc} describe={describe} />
          <Introduce title={matrix.title} list={matrix.list} choose={choose} />
          <InView>
            {({ inView, ref, entry }) => (
                <img ref={ref} className={classNames('Subtract', {on: inView})} src={Subtract} alt="圈" />
            )}
          </InView>
          <Footer />
        </div>
      </Fragment>
    );
  }
}
export default page;
