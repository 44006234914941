import React, { Component, Fragment } from 'react';
import Header from '../../layout/header/index';
import Footer from '../../layout/footer';
import Banner from '../../layout/banner';
import QrCode from '../../layout/qrCode';
import ContentTitle from '../../layout/contentTitle';
import ContentItem from '../../element/contentItem';
import Swiper from '../../element/swiper';
import './index.css';
import Gallery from '../marketing/element/gallery';
import { banner, carousel, minip, minipBrand, prodInfo, qrcode, wxQrcode, logoList } from './data';
import Number from '../starResources/number';
import Fullswipe from './element/fullswipe';
import ContentSlide from './element/contentslide';
import {Subtract, Union} from "../advertising/data";

console.log(wxQrcode);

class Sharing extends Component {
    componentDidMount() {
        document.title = '如涵爱种草-如涵旗下达人真实种草通告平台-如涵官网';
    }
    render() {
        return (
            <Fragment>
                <div className="sharing flexccc" >
                    <Header className={'black'} />
                    <Banner imgurl={banner} />
                    <ContentTitle content={['如涵爱种草', '全平台达人', '产品介绍', '代表案例', '合作品牌']}></ContentTitle>
                    <ContentItem name="如涵爱种草" >
                        <img className="Union" src={Union} alt="圈" />
                        <div className="sharingTitle">
                            以数据为核心
                        </div>
                        <div className="sharingTitle">
                            提供全平台达人投放服务
                        </div>
                        <div className="sharingCount flexrbc" >
                            <div className="flexccc">
                                <label><Number data={8.1} time={15} step={2} /><span className=" plus">w+</span></label>
                                <span>入驻达人</span>
                            </div>
                            <div className="flexccc">
                                <label><Number data={29.42} time={0} step={20} /><i>亿</i><span className=" plus">+</span></label>
                                <span>全网粉丝</span>
                            </div>
                            <div className="flexccc">
                                <label><Number data={3000} time={20} step={50} /><span className=" plus">+</span></label>
                                <span>合作品牌</span>
                            </div>
                        </div>
                    </ContentItem>
                    {/* <ContentItem name="全平台达人" >
                        <Fullswipe />
                    </ContentItem> */}
                    <ContentItem name="产品介绍" ><ContentSlide /></ContentItem>
                    <ContentItem name="代表案例" >
                        <img className="Subtract" src={Subtract} alt="圈" />
                        <div className='cus-slice' style={{ width: 1100, marginTop: '40px' }}  >
                            <Swiper list={carousel} hasTab={false} />
                        </div>
                    </ContentItem>
                    <ContentItem name="合作品牌" >
                        <Gallery imgList={logoList} maxWidth={1260} col={9} />
                    </ContentItem>
                    <ContentItem name="更多方式关注爱种草" >
                        <div className=" flexrbc moreFocuson"  >
                            <div className=" flexccc" >
                                <img className='more-image' src={minipBrand} alt="关注品牌版小程序" />
                                <span style={{fontSize: 16}} >关注品牌版小程序</span>
                            </div>
                            <div className=" flexccc" >
                                <img className='more-image' src={minip} alt="关注达人版小程序" />
                                <span style={{fontSize: 16}} >关注达人版小程序</span>
                            </div>
                            <div className=" flexccc" >
                                <img className='more-image' src={qrcode} alt="关注微信公众号" />
                                <span style={{fontSize: 16}}>关注微信公众号</span>
                            </div>
                        </div>
                    </ContentItem>
                    <Footer />
                    <QrCode codeUrl={wxQrcode} />
                </div>
            </Fragment>
        )
    }
}
export default Sharing;