import React, { Component } from 'react';
import { growList } from './data';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';


class Awards extends Component {

    render() {
        const { hasArrows = true } = this.props
        const settings = {
            autoplay: true,
            className: "slick-swiper",
            infinite: true,
            arrows: true,
            centerPadding: "",
            slidesToScroll: 1,
            swipeToSlide: true,
            variableWidth: true,
        };

        return (<div className="grow-container">
                        <Slider {...settings}>
                            {growList.map(item => (
                                <div key={item.index}>
                                    <div className='grow-list-box'>
                                        <div className='grow-list-header'>
                                            <div className='grow-list-header-index'>{item.index}</div>
                                            <div className='grow-list-header-title'>{item.title}</div>
                                        </div>
                                        <div className='grow-list-content'>{item.content}</div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                </div>
        )

    }

}

export default Awards;