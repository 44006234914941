import React, { Component, Fragment } from 'react';
import {
    Player,
    ControlBar,
    PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
    ReplayControl, // 后退按钮
    ForwardControl,  // 前进按钮
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,  // 倍速播放选项
    VolumeMenuButton
} from 'video-react';
import "video-react/dist/video-react.css"; // import css
import './index.css'


class Video extends Component {
    state = {
        play: 1
    }
    componentDidMount() {
        this.player.subscribeToStateChange(this.handleStateChange.bind(this));
        this.player.load()
    }

    handleStateChange(state, prevState) {
        // status == 0 pause  +  status == 1 play
        const { status } = this.props;
        if (status === 0) this.player.pause();
        else this.player.play();
    }

    render() {
        const { videoUrl, autoPlay = false, muted = true, loop = false, img = '', onPlay, onPause, playerProp = {}, wrapProp = {} } = this.props;
        return (
            <Fragment>
                <div className="react-video-play" style={{ width: '100%' }} {...wrapProp}>
                    <Player
                        {...playerProp}
                        ref={c => {
                            this.player = c;
                        }}
                        muted={muted}
                        autoPlay={autoPlay}
                        loop={loop}
                        poster={img}
                        onPlay={() => {
                            onPlay && onPlay()
                        }}
                        onPause={() => {
                            onPause && onPause()
                        }}
                    >
                        <source
                            src={videoUrl}
                            type="video/mp4"
                        />
                        <ControlBar autoHide={false} disableDefaultControls={false}>
                            <ReplayControl seconds={10} order={1.1} />
                            <ForwardControl seconds={30} order={1.2} />
                            <PlayToggle />
                            <CurrentTimeDisplay order={4.1} />
                            <TimeDivider order={4.2} />
                            <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
                            <VolumeMenuButton />
                        </ControlBar>
                    </Player>
                </div>
            </Fragment>
        )
    }
}


export default Video;