import React, { Component, Fragment } from 'react';
import Header from '../../layout/header/index';
import Swiper from '../../element/swiper';
import Banner from '../../layout/banner';
import ContentTitle from '../../layout/contentTitle';
import QrCode from '../../layout/qrCode';
import Footer from '../../layout/footer';
import "./index.css";
import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

class page extends Component {
    state = {
        xyArr: [
            {
                name: '小熊加辣',
                id: 'ID：101003259',
                oNum: 0,
                cNum: '1.3w'
            },
            {
                name: '梓琪Paggie',
                id: 'ID：100216094',
                oNum: '30+',
                cNum: '3.8w'
            },
            {
                name: 'Sir',
                id: 'ID：365481636',
                oNum: 0,
                cNum: '2.4w'
            },
            {
                name: '鲜鲜超鲜',
                id: 'ID：1074324164',
                oNum: 0,
                cNum: '2.4w'
            },
        ]
    };
    render() {
        const { xyArr } = this.state
        const list1 = [
            {
                title: '线下内容课',
                image: cdn('images/starClass/01-1.png'),
                video: cdn('video/starClass/01-1.mp4')
            },
            {
                title: '线下运营课',
                image: cdn('images/starClass/01-2.png'),
                video: cdn('video/starClass/01-2.mp4')
            },
            {
                title: '线下直播课',
                image: cdn('images/starClass/01-3.png'),
                video: cdn('video/starClass/01-3.mp4')
            },
            {
                title: '线上精品课',
                image: cdn('images/starClass/01-4.png'),
            },
        ]
        return (
            <Fragment>
                <div className="starClass" >
                    <Header />
                    <Banner imgurl={cdn('images/starClass/banner.png')} />
                    <div style={{ marginTop: '-4px' }}>
                        <ContentTitle content={['项目介绍', '课程产品介绍', '优秀学员案例', '企业内训案例', '企业内训流程']}></ContentTitle>
                        <div className="contentTitle_by">
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt" style={{ margin: '10px 0 10px 0' }}>打造社交媒体IP</p>
                                <p className="contentTitle_byt" style={{ margin: '0 0 50px 0' }}>让你的影响力更具价值</p>
                                <p style={{ top: '100px', width: '68px' }} className="underline"></p>
                                <div className="t1">
                                    <div >基于如涵自身长期的IP成功孵化经验，星课堂将如涵内部完整孵化体系和教学内容进行整合设计，</div>
                                    <div>结合敏锐的行业洞察，输出匹配各类型、各阶段学员需求的教学体系，并通过线上线下等多种模式进行教学输出和培养赋能。</div>
                                </div>
                            </div>
                        </div>
                        <div className="contentTitle_by" >
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt">课程产品介绍</p>
                                <p style={{ top: '34px', width: '68px' }} className="underline"></p>
                                <div style={{ width: '63%', transform: `scale(${73 / 63})`, marginTop: '50px' }}>
                                    <Swiper list={list1} />
                                </div>
                            </div>
                        </div>
                        <div className="contentTitle_by">
                            <div className="contentTitle_byb" style={{ width: '74.2%', margin: '0 auto' }} >
                                <p className="contentTitle_byt">优秀学员案例 </p>
                                <p style={{ top: '34px', width: '68px' }} className="underline"></p>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {
                                        xyArr.map((item, index) => (
                                            <div style={{ margin: '0 12px' }} key={index}>
                                                <img style={{ width: '100%' }} src={cdn(`images/starClass/xy${index}.png`)} alt="学员" />
                                                <div>
                                                    <p className="contentTitle_name">
                                                        {item.name}
                                                    </p>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img style={{ marginRight: '4px' }} src={cdn('images/starClass/redBook.png')} alt="小红书" /><span>{item.id}</span>
                                                    </div>
                                                </div>
                                                <div className="contentTitle_num">
                                                    <div>
                                                        <p className="p1">{item.oNum}</p>
                                                        <p className="p2">报名时粉丝量</p>
                                                    </div>
                                                    <div className="contentTitle_shu"></div>
                                                    <div>
                                                        <p className="p1">{item.cNum}</p>
                                                        <p className="p2">目前粉丝量</p>
                                                    </div>
                                                </div>
                                            </div>))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="contentTitle_by">
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt">企业内训案例</p>
                                <p style={{ top: '34px', width: '68px' }} className="underline"></p>
                                <div style={{ width: '73%', }}>
                                    <Swiper type={'banner'}>
                                        <div>
                                            <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: '20px', overflow: 'hidden' }}>
                                                <div style={{ width: '100%', borderRadius: '20px', overflow: 'hidden', marginTop: '-12%', maxHeight: '730px' }}>
                                                    <img style={{ width: '100%' }} src={cdn('images/starClass/title4.jpeg', 1000)} alt="内容" />
                                                </div>
                                                <div style={{ height: '190px', padding: '25px 45px 0 45px' }}>
                                                    <div className="contentTitle_by_ct">铁建城发</div>
                                                    <div className="contentTitle_by_cb">中铁建城市开发有限公司定位为中国铁建城市开发与运营政策研究、资源整合、投融资模式设定的投资平台和专业运营公司；随着短视频行业异军突起、推广价值不断显现。作为一家带着创新基因的企业，需要把握最新趋势、紧跟时代步伐，抓住新媒体发展机遇，力争为铁建城发在城市运营领域探索新的发展。今年10月如涵为铁建城发定制化开展 “C位领潮人”新媒体账号孵化培养计划。计划包括定向培训、跟踪孵化、代运营加速三个阶段，由如涵全链路承接。</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: '20px', overflow: 'hidden' }}>
                                                <div style={{ width: '100%', borderRadius: '20px', overflow: 'hidden', marginTop: '-12%', maxHeight: '730px' }}>
                                                    <img style={{ width: '100%' }} src={cdn('images/starClass/title3.jpeg', 1000)} alt="内容" />
                                                </div>
                                                <div style={{ height: '190px', padding: '25px 45px 0 45px' }}>
                                                    <div className="contentTitle_by_ct">泸州洋淘港</div>
                                                    <div className="contentTitle_by_cb">泸州洋淘港是一家进口（跨境）贸易产业平台，也是泸州首家进口商品线下仓储式量贩体验中心，专业从事进口商贸流通全产业链业务，2021年8月如涵星课堂为泸州洋淘港进行了直播项目从0-1的搭建，并从直播人员组建、直播间搭建、直播流量以及直播话术等几个方面进行了系统化、体系化的授课。随后，泸州洋淘港紧锣密鼓地展开了直播的组建，已完成一次独立成功的直播，成为当地跨境电商直播首席商家，星课堂也将在后期对洋淘港进行持续长期的赋能动作。</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Swiper>
                                </div>

                            </div>
                        </div>
                        <div className="contentTitle_by">
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt">企业内训流程</p>
                                <p style={{ top: '34px', width: '68px' }} className="underline"></p>
                                <img style={{ width: '73%' }} src={cdn('images/starClass/title5.png', 1200)} alt="内容" />
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <QrCode><img className="img_code" src={cdn('images/starClass/code.png')} alt="二维码" /></QrCode>
                </div>
            </Fragment>
        )
    }
}
export default page;