import React, { Component } from 'react';
import { awardsList } from './data';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';


class Awards extends Component {

    render() {
        const { hasArrows = true } = this.props
        const settings = {
            autoplay: true,
            className: "slick-swiper",
            infinite: true,
            arrows: hasArrows,
            centerPadding: "",
            slidesToScroll: 1,
            swipeToSlide: true,
            variableWidth: true
        };

        return (<div className="awards-container">
                        <Slider {...settings}>
                        {awardsList.map((ele,index)=><div className="awardsItem" key={index} style={{width: 400}} >
                        <div className="img"><img src={ele.image}  alt={ele.title} /></div>
                        <label >{ele.year}</label>
                        <p>{ele.title}</p>
                        </div>)}
                        </Slider>
                </div>
        )

    }

}

export default Awards;