import React, {Component, Fragment} from 'react';
import Header from '../../layout/header/index';
import Footer from '../../layout/footer';
import Banner from '../../layout/banner';
import ContentTitle from '../../layout/contentTitle';
import ContentItem from '../../element/contentItem';
import History from './element/history';
import Bottom from './element/bottom';
import TeamInfo from './element/teamInfo';
import Awards from './element/awards';
import Grow from './element/grow';
import './index.css';
import {
    banner,
    ourmission,
    ourvission,
    // awards1,
    // awards2,
    // awards3,
    officeList,
    enjoyList,
} from './data';
import useAssets from "../../../common/useAssets";
import {Subtract, Union} from "../advertising/data";

const cdn = useAssets(false);

class About extends Component {
    componentDidMount() {
        document.title = '关于如涵-如涵官网';
    }

    render() {
        return (
            <Fragment>
                <div className="about flexccc">
                    <Header className={'black'} />
                    <div className='nav-head-img'>
                        <Banner imgurl={banner} />
                        <div className='nav-head-text'>
                            <img alt="arrow" src={cdn(`images/about/banner/banner-text.png`)} />
                        </div>
                    </div>
                    <ContentTitle
                        content={[
                            '公司简介',
                            '我们的使命',
                            '团队介绍',
                            '大事记',
                            '荣获奖项',
                            '公司环境',
                            '乐享如涵',
                        ]}
                    ></ContentTitle>
                    <ContentItem name="公司简介">
                        <img className="Union" src={Union} alt="圈" />
                        <div className="brief mt30" style={{width: 970, textAlign: 'left'}}>
                            如涵是国内较早的红人孵化公司，也是国内有影响力的红人营销平台。自2014年成功孵化张大奕，开创“网红电商”新模式后，先后获得赛富亚洲A轮融资、君联资本B轮融资、阿里巴巴C轮融资，并于2019年登陆美国纳斯达克，成为中国网红电商第一股。2021年，如涵推出“爱种草，达人真实分享通告平台 ”，以数据为核心提供全平台达人投放服务。
                        </div>
                        <div className="brief" style={{width: 970, textAlign: 'left', marginTop: 40}}>
                            截止2022年12月，如涵在红人孵化领域已持续开拓电商红人孵化、泛娱乐广告红人孵化、直播红人孵化等业务，并成功孵化虫虫、大金、宝剑嫂、精致大姨夫、温婉等知名KOL，拥有独家红人200+位，全网粉丝3.58亿， 爱种草平台达人数40000+。 未来，如涵将向着成为这个时代的时尚共创平台而努力，持续创造影响力，传播更多美好。
                        </div>
                    </ContentItem>
                    <ContentItem name="我们的使命">
                        <div style={{marginBottom: '6px'}} className="flexrcc our">
                            <div className='our-mission-left' />
                            <div
                                className={'ourmission'}
                                style={{marginRight: '23px', backgroundImage: `url(${ourmission})`}}
                            >
                                {/*<div className={'ourmission-en'}>
                                    OUR MISSION
                                </div>
                                <div className={'ourmission-ch'}>
                                    我们的使命
                                </div>*/}
                            </div>
                            {/*<div
                                className={'ourmission'}
                                style={{backgroundImage: `url(${ourvission})`}}
                            >
                                <div className={'ourmission-en'}>
                                    OUR VISSION
                                </div>
                                <div className={'ourmission-ch'}>
                                    我们的愿景
                                </div>
                            </div>*/}
                        </div>
                    </ContentItem>
                    <ContentItem name="自主成长">
                        <Grow />
                    </ContentItem>
                    <ContentItem name="团队介绍">
                        <TeamInfo />
                    </ContentItem>
                    <ContentItem name="大事记">
                        <img className="Subtract" src={Subtract} alt="圈" />
                        <History />
                    </ContentItem>
                    <ContentItem name="荣获奖项">
                        <Awards />
                        {/* <div style={{marginBottom:'6px'}} className="flexrbs awards">
                                <div className=" flexccs awardsItem">
                                    <div className="img"><img src={awards1}  alt="抖in合伙人"  /></div>
                                    <label >2020</label>
                                    <p>抖in合伙人</p>
                                </div>
                                <div className=" flexccs awardsItem">
                                    <div className="img" ><img src={awards2}  alt="小李朝ye-抖in城市星推官+巨量星图达人杰 Battle King"   /></div>
                                    <label >2021</label>
                                    <p>小李朝ye-抖in城市星推官+巨量星图达人杰 Battle King </p>
                                </div>
                                <div className=" flexccs awardsItem">
                                    <div className="img" ><img src={awards3}  alt="年度红人机构奖/十大影响力颜值机构/十大影响力时尚机构"   /></div>
                                    <label>2020</label>
                                    <p>年度红人机构奖/十大影响力颜值机构/十大影响力时尚机构</p>
                                </div>
                        </div>  */}
                    </ContentItem>
                    <ContentItem name="公司环境">
                        <Bottom officeList={officeList} />
                    </ContentItem>
                    <ContentItem name="乐享如涵">
                        <Bottom enjoyList={enjoyList} />
                    </ContentItem>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}
export default About;
