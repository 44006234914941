import React, { Component } from 'react';
import './tab.css';
class tab extends Component {
  render() {
    const { list } = this.props;
    const index = list.findIndex(i => i.key === this.props.choose);
    return (
      <div className='tab'>
        <div className='tab-active' style={{left: `${index * 20}%`}} />
        {list.map((item, index) => (
          <div
            key={index}
            className={this.props.choose === item.key ? 'tab-choose tab-item' : 'tab-item'}
            onClick={() => {
              this.props.changeChoice(item.key);
            }}>
            {item.value}
          </div>
        ))}
      </div>
    );
  }
}
export default tab;
