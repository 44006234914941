import React, { Component, Fragment } from 'react';
import CarouselItem from './carousel-item';
import useAssets from '../../../../common/useAssets';
import './index.css'

class Carousel extends Component {
    state = {
        imgArr: [
            { index: 0 }, { index: 1 }, { index: 2 }, { index: 3 }, { index: 4 }, { index: 5 }, { index: 6 }, { index: 7 }, { index: 8 }, { index: 9 }, { index: 10 },
            { index: 11 }, { index: 12 }, { index: 13 }, { index: 14 }, { index: 15 }, { index: 16 }, { index: 17 }, { index: 18 }, { index: 19 }, { index: 20 }, { index: 21 },
        ],
        cindex: 0,
        st: 0
    }
    constructor() {
        super()
        this.cdn = useAssets(false);
    }
    componentDidMount() {
        this.arrange(0);
    }

    _mod(n, m) {
        return (n % m + m) % m;
    }

    // 移动
    arrange(centerIndex) {
        var _that = this;
        const { imgArr } = this.state;
        var center = imgArr[centerIndex];
        var half = (imgArr.length - 1) / 2;
        var before = [];
        for (var i = centerIndex - 1; before.length < half; i--) {
            before.push(imgArr[this._mod(i, imgArr.length)]);
        }

        var after = [];
        for (var i = centerIndex + 1; after.length < imgArr.length - before.length - 1; i++) {
            after.push(imgArr[this._mod(i, imgArr.length)]);
        }
        if (center) {
            center['xtrans'] = 0;
            center['scale'] = 1;
            center['opacity'] = 1;
            center['zIndex'] = Math.max(before.length, after.length) + 1;
            center['rotateY'] = 0;
            this.setState({
                cindex: centerIndex
            })
            _that[`setItem${centerIndex}`].setState(center);
        }

        [before, after].forEach(function (list, listIndex) {
            var parentTrans = 0;
            list.forEach(function (item, i) {
                let obj = {}
                obj.scale = Math.pow(0.94, i + 1) > 0.7 ? Math.pow(0.94, i + 1) : 0.7;
                var absolute = 105 * obj.scale * 1.125 + parentTrans;
                parentTrans = absolute;
                obj.xtrans = (listIndex == 0 ? -1 : 1) * absolute;
                // obj.opacity = Math.max(1 - 0.25 * Math.pow(i / 10 + 1, 2), 0);
                obj.opacity = i > 7 ? 0 : 1;
                obj.zIndex = Math.max(before.length, after.length) - i;
                if (listIndex === 0 && i < 4) {
                    obj.rotateY = '-30deg'
                } else if (listIndex === 1 && i < 4) {
                    obj.rotateY = '30deg'
                } else {
                    obj.rotateY = 0
                }
                _that[`setItem${item.index}`].setState(obj);
            });
        });
        // // 清除延时定时器
        clearTimeout(_that.state.st);
        let st = setTimeout(() => {
            _that.arrange(_that.state.cindex + 1 > imgArr.length - 1 ? 0 : _that.state.cindex + 1)
        }, 3000);
        _that.setState({
            st
        })
    }

    render() {
        const { imgArr } = this.state;
        return (
            <Fragment>
                <div id="carousel" className="carousel" ref="carousel" >
                    {
                        imgArr.map((item, index) => {
                            return (
                                <CarouselItem key={index} index={index} ref={ref => this[`setItem${index}`] = ref} arrange={(i) => { this.arrange(i) }} img={this.cdn(`images/home/red/01-${index}.png`, 300)} />
                            )
                        })
                    }
                </div>
            </Fragment>
        )
    }
}
export default Carousel;