import React, { Component } from 'react';
import "./index.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class CarouselItem extends Component {
    state = {
        zIndex: 0,
        xtrans: 0,
        ytrans: 0,
        scale: 1,
        opacity: 1,
        rotateY: 0,
    }

    transform() {
        const { xtrans, ytrans, scale, rotateY } = this.state;
        return ['translate(' + (xtrans - 50) + '%, ' + (ytrans - 50) + '%)', 'scale(' + scale + ')', rotateY ? `perspective(1000px)` : '', `rotateY(${rotateY})`].join(' ');
        // return ['translate(' + (xtrans - 50) + '%, ' + (ytrans - 50) + '%)', 'scale(' + scale + ')'].join(' ');
    }

    centerSelf() {
        const { arrange, index } = this.props
        arrange && arrange(index)
    }

    render() {
        const { children, style = {}, img } = this.props
        const { opacity, zIndex } = this.state
        const newStyle = {
            transition: 'all 3s linear',
            transform: this.transform(),
            zIndex: zIndex,
            opacity: opacity
        }
        return (
            <div className="carousel-item" style={{ ...newStyle, ...style }} onClick={() => { this.centerSelf() }}>
                <LazyLoadImage src={img} alt="图片"/>
            </div>
        );
    }
}

export default CarouselItem;