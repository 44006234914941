import React,{Component} from "react";

import Title from "../../starResources/title"
import useAssets from '../../../../common/useAssets';
const cdn = useAssets(false);
class caseOnline extends Component{
    state = {
        interval: null,
        top: 0,
        srcList: this.props.srcList
    }
    componentDidMount() {
        this.interval()
        this.reset()
        
    }
    resetImgBlock() {

        const divs = this.dom.querySelectorAll('div.carousel-img')
        divs.forEach(
            (item, index) => {
                item.className = 'carousel-img';
            }
        )
    }
    addImgClass(type = 'right') {
        const top = this.state.top;
        this.carouselType(type)
        if(type === 'left') {
            console.log(this.state.top, top)
            top === 0 ? this.setTop(this.state.srcList.length - 1) : this.setTop(top - 1)
        } else {
            top === this.state.srcList.length - 1 ? this.setTop(0) : this.setTop( top + 1)
        }
    }
    carouselType(type) {
        
        const top = this.state.top;
        const length = this.state.srcList.length;
        const topImg = this.dom.querySelectorAll('div.carousel-img')[top];
        let left = "";
        let right = "";
        if(top === (length - 1)) {
            right = 0;
        } else {
            right = top + 1;
        }
        if(top === 0) {
            left = length  - 1;
        } else {
            left = top - 1;
        }
        
        const leftImg = this.dom.querySelectorAll('div.carousel-img')[left];
        const rightImg = this.dom.querySelectorAll('div.carousel-img')[right];
        topImg.classList.add('top');
        topImg.classList.add(`${type}-click-top`);
        
        leftImg.classList.add('left')
        leftImg.classList.add(`${type}-click-left`)
        
        rightImg.classList.add('right')
        rightImg.classList.add(`${type}-click-right`)
    }
    setTop(index) {
        this.setState({
            top: index
        })
    }
    reset(type) {
        
        this.resetImgBlock();
        this.addImgClass(type);
    }
    interval() {
        if(this.state.interval){
            this.clearInterval();
        }
        const interval = setInterval(
            () => {
                this.reset()
            },
            3000
        )
        this.setState({
            interval
        })
    }
    componentWillUnmount() {
        this.clearInterval();
    }
    clearInterval() {
        clearInterval(this.state.interval);
        this.setState({
            interval: null
        })
    }
    clickRight() {       
        this.interval()
        this.reset()
    }
    clickLeft() {
        this.interval()
        this.reset('left')
    }
    render() {
        const title = "合作案例"
        const {srcList} = this.state

        return (
            <div className="online-case">
                <div className="case-img-wrapper">
                    <div className="content case-carousel clearfix"  ref={(dom) => {this.dom = dom}}>
                        {
                            srcList.map(
                                (item, index) => (
                                    <div key={index} className={`carousel-img ${index === 0?'top': ''}`}>
                                        <img alt="合作案例" src={item} data-index={index}/>
                                        <div className="mask" />
                                    </div>
                                )
                            )
                        }
                        <div className='icon-slice left-button' onClick={this.clickLeft.bind(this)} />
                        <div className='icon-slice right-button' onClick={this.clickRight.bind(this)} style={{transform: 'translateY(-50%) rotateZ(180deg)'}} />
                       {/* <img  onClick={this.clickLeft.bind(this)} alt="left-button"  src={cdn('images/onlineRetailers/left.png')} className="left-button"/>
                        <img  onClick={this.clickRight.bind(this)}  alt="right-button"  src={cdn('images/onlineRetailers/right.png')} className="right-button"/>*/}
                    </div>
                </div>
            </div>
        )
    }
}
export default caseOnline;