import React, { Component, Fragment } from 'react';
import './index.css';
import { imgList } from './data'

class Fullswipe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            posList: [],
            rowList: [],
            classList: []
        }
        this.initPos.bind(this)
        this.generateRow.bind(this)
    }

    initPos() {
        const kocList = imgList
        const { row = 3, kocWidth = 156 } = this.props
        let posList = []
        const col = Math.floor(kocList.length / row)
        for (let i = 0; i < col; i++) {
            posList.push(i * (kocWidth + 10))
        }
        this.setState({ posList: posList })
    }

    generateRow() {
        const { row = 3 } = this.props
        const kocList = imgList
        const col = Math.floor(kocList.length / row)
        let classList = new Array(col).fill('', 0)
        let result = []
        for (let i = 0; i < row; i++) {
            let currentRow = kocList.filter((item, index) => { return index < col * (i + 1) && index >= col * i })
            // currentRow.push(currentRow[0])
            result.push(currentRow)
        }
        this.setState({ rowList: result, classList: classList })
        // console.log(result);
    }

    posAnimation() {
        let posList = this.state.posList
        let classList = this.state.classList
        const { kocWidth = 156 } = this.props

        posList.length > 0 && posList.forEach((position, index) => {
            let newPos = position - 5
            if (newPos < 20) {
                classList[index] = 'hide'
            }
            if (newPos < -156) {
                let lastKey = (index - 1) < 0 ? posList.length - 1 : (index - 1)
                posList[index] = posList[lastKey] + kocWidth + 10
                classList[index] = ''
            } else {
                posList[index] = newPos
            }
        })
        this.setState({ posList: posList, classList: classList })
    }

    componentDidMount() {
        this.generateRow()
        this.initPos()
        // const { row = 3 } = this.props
        // const kocList = imgList
        // const col = Math.floor(kocList.length / row)
        this.timer = setInterval(() => {
            this.posAnimation()
        }, 30)
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        const kocList = imgList
        const { posList, rowList, classList } = this.state

        // const col = Math.floor(kocList.length / row)
        return (
            <Fragment>
                {
                    kocList && kocList.length > 0 && <div className='koc-container'>{
                        rowList.map((row, rowIndex) => {
                            return (
                                <div className={`koc-row ${rowIndex % 2 === 1 ? 'offset' : ''} `} key={rowIndex}>
                                    {
                                        row.map((item, index) => {
                                            return (
                                                <div className={'koc-box ' + classList[index]} key={index} style={{ left: posList[index] }} >
                                                    <img src={item} alt="koc" draggable={false} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )

                        })

                    }
                    </div>
                }
            </Fragment>
        )
    }

}

export default Fullswipe