/*
 * @Author: your name
 * @Date: 2021-11-07 17:02:30
 * @LastEditTime: 2021-11-07 23:16:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \client-pc\src\markup\pages\onlineRetailers\data.js
 */
import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);
const flowList = [
    {
        title: '提供账号',
        content: '商家提供店铺淘宝联盟账号ID',
        index: '01'
    },
    {
        title: '开通权限',
        content: '如涵联系阿里小二开通合作权限',
        index: '02'
    },
    {
        title: '充值费用',
        content: '商家登陆淘宝联盟充值推广费用',
        index: '03'
    },
    {
        title: '发起合作',
        content: '商家在淘宝联盟后台发起合作（双方约定推广红人、商品、时间、UV等细节）',
        index: '04'
    },
    {
        title: '报名',
        content: '商家将合约链接发给如涵报名',
        index: '05'
    },
    {
        title: '红人推广',
        content: '如涵红人按计划进行推广。商家可在合约中直接查看UV数、收藏、加购、付款数、付款金额',
        index: '06'
    },
    {
        title: '公示质量',
        content: '合作结束17天后淘宝联盟公示合约质量分，最终推广费用=UV数 x UV单价 x 质量分',
        index: '07'
    },
]
const patternList = [
    {
        title: 'CPA合作',
        describe: `适用于  服装：淘宝/天猫已开店商家，DSR三高、稳定上新，返单速度较快，店铺风格为红人风/店主风
        。标品：通过我司产品审核且级别为S/A/B的产品。`,
        src: cdn('images/onlineRetailers/pattern/cpa.jpg'),
        bgSrc: cdn('images/onlineRetailers/pattern/bg-1.png'),
        position: 'left-bottom',
    },
    {
        title: '抖音短视频带货合作',
        describe: `适用于：服配类淘宝/天猫/品牌/供应链，须有抖音小店支持一件代发，款式及价格有较高性价比。`,
        src: cdn('images/onlineRetailers/pattern/douyin.jpg'),
        bgSrc: cdn('images/onlineRetailers/pattern/bg-2.png'),
        position: 'right-bottom',
    },
    {
        title: '服饰广告合作',
        describe: `适用于  淘宝/天猫/品牌商家，有全平台种草&探店需求`,
        src: cdn('images/onlineRetailers/pattern/clothes.jpg'),
        bgSrc: cdn('images/onlineRetailers/pattern/bg-3.png'),
        position: 'right-bottom',
    },
]
const planData = {
    title: "如涵轻店计划",
    describe: "如涵轻店计划是拥有优质流量的红人在社交媒体上通过图文和视频推\n广产品，通过进店UV结算付费的合作模式。",
    list: [
        {
            type: 'Kol',
            name: '红人矩阵',
            describe: '声量强 ｜全网优质红人数\n百位',
        },
        {
            type: 'Media',
            name: '社交媒体',
            describe: '覆盖全 ｜微博/快手/抖音/\nB站/小红书等',
        },
        {
            type: 'Traffic',
            name: '优质流量',
            describe: 'ROI高 ｜1亿+垂直年轻女\n性粉丝群体',
        },
        {
            type: 'Quality',
            name: '品效合一',
            describe: '回报高 ｜品宣+导购，双效\n合一',
        },
        {
            type: 'Effect',
            name: '效果付费',
            describe: '放心投 ｜社交传播策划配\n合进店UV结算付费',
        },
    ]
}
const lightShop = [
    {
        name: '满满Cyim',
        src: cdn('images/onlineRetailers/lightShop/9.jpg'),
    },
    {
        name: '小野智恩-',
        src: cdn('images/onlineRetailers/lightShop/26.jpg'),
    },
    {
        name: '憨娜Hannah',
        src: cdn('images/onlineRetailers/lightShop/5.jpg'),
    },
    {
        name: '绒耳朵儿',
        src: cdn('images/onlineRetailers/lightShop/10.jpg'),
    },
    {
        name: '只小萌',
        src: cdn('images/onlineRetailers/lightShop/25.jpg'),
    },
    {
        name: '曾永仪_',
        src: cdn('images/onlineRetailers/lightShop/1.jpg'),
    },
    {
        name: '陈鱼白.HEIC',
        src: cdn('images/onlineRetailers/lightShop/2.jpg'),
    },
    {
        name: '大七仔呀',
        src: cdn('images/onlineRetailers/lightShop/3.jpg'),
    },
    {
        name: 'D登登',
        src: cdn('images/onlineRetailers/lightShop/4.jpg'),
    },
    {
        name: '是静静JING_&是南希Nancy_',
        src: cdn('images/onlineRetailers/lightShop/6.jpg'),
    },
    {
        name: '可爱炸炸-',
        src: cdn('images/onlineRetailers/lightShop/7.jpg'),
    },
    {
        name: '林零亿-',
        src: cdn('images/onlineRetailers/lightShop/8.jpg'),
    },
    {
        name: '-上下名-',
        src: cdn('images/onlineRetailers/lightShop/11.jpg'),
    },
    {
        name: '瘦瘦璐-',
        src: cdn('images/onlineRetailers/lightShop/12.jpg'),
    },
    {
        name: '双沐-',
        src: cdn('images/onlineRetailers/lightShop/13.jpg'),
    },
    {
        name: '王宛尘',
        src: cdn('images/onlineRetailers/lightShop/14.jpg'),
    },
    {
        name: '喜大牙',
        src: cdn('images/onlineRetailers/lightShop/16.jpg'),
    },
    {
        name: '小马晕晕yunn',
        src: cdn('images/onlineRetailers/lightShop/17.jpg'),
    },
    {
        name: '小越小越-',
        src: cdn('images/onlineRetailers/lightShop/18.jpg'),
    },
    {
        name: '笑笑同学啊_',
        src: cdn('images/onlineRetailers/lightShop/19.jpg'),
    },
    {
        name: '羊呢桃tt',
        src: cdn('images/onlineRetailers/lightShop/20.jpg'),
    },
    {
        name: '一颗七崽',
        src: cdn('images/onlineRetailers/lightShop/21.jpg'),
    },
    {
        name: '怡含怡含',
        src: cdn('images/onlineRetailers/lightShop/22.jpg'),
    },
    {
        name: '有竹',
        src: cdn('images/onlineRetailers/lightShop/23.jpg'),
    },
    {
        name: '-Babeei张张-',
        src: cdn('images/onlineRetailers/lightShop/24.jpg'),
    },
    {
        name: '舟好甜',
        src: cdn('images/onlineRetailers/lightShop/27.jpg'),
    },
]
const caseList = [
    cdn('images/onlineRetailers/case/case1.png', 1000),
    cdn('images/onlineRetailers/case/case2.png', 1000),
    cdn('images/onlineRetailers/case/case3.png', 1000),
    cdn('images/onlineRetailers/case/case4.png', 1000),
    cdn('images/onlineRetailers/case/case5.png', 1000),
    cdn('images/onlineRetailers/case/case6.png', 1000),
]
export {flowList, patternList, planData, lightShop, caseList}