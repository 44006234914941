import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);
export const banner = cdn('images/about/banner.png', 1800);
export const ourmission = cdn('images/about/ourmission2.png');
export const ourvission = cdn('images/about/ourvission.png');
export const awards1 = cdn('images/about/awards-1.png');
export const awards2 = cdn('images/about/awards-2.png');
export const awards3 = cdn('images/about/awards-3.png');
export const bottom1 = cdn('images/about/bottom-1.png');
export const bottom2 = cdn('images/about/bottom-2.png');

export const officeList = [{
    title:'优越的地理位置',
    info:'位于钱江新城CBD双轴交汇—高德置地广场',
    image:cdn('images/about/bottom/bottom1-1.png'),
    },
    {
    title:'高配置办公环境',
    info:'IMAC、制冰机、宠物房等配备设施，让你的办公体验大升级',
    image:cdn('images/about/bottom/bottom1-2.png'),
    },
    {
    title:'专业影棚直播间',
    info:'配有百万摄影器材库，想不出片都难',
    image:cdn('images/about/bottom/bottom1-3.png'),
    },
    {
    title:'一线江景休闲区',
    info:'如涵“名媛们”的下午茶聚集地',
    image:cdn('images/about/bottom/bottom1-4.png'),
    },
    ];

export const enjoyList = [{
    title:'节日氛围',
    info:'你想要的仪式感这里都有，快乐从不缺席',
    image:cdn('images/about/bottom/bottom2-1.png', 2000),
    },
    {
    title:'生日惊喜',
    info:'有一种礼物，叫别人家公司的生日礼物',
    image:cdn('images/about/bottom/bottom2-2.png', 2000),
    },
    {
    title:'团建outing',
    info:'双倍幸福的集体享受，照片也无法诠释出的从头到脚的快乐',
    image:cdn('images/about/bottom/bottom2-3.png', 2000),
    },
    {
    title:'加班餐下午茶',
    info:'吃饱喝好，每天都是被美食治愈的一天',
    image:cdn('images/about/bottom/bottom2-4.png', 2000),
    },
    {
    title:'红人训',
    info:'根据红人定位定制专业课程，以真实为基础塑造个人IP,扩大影响力，实现精准涨粉',
    image:cdn('images/about/bottom/bottom2-5.png', 2000),
    }];
