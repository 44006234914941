import React,{Component} from "react";
import Title from "../../starResources/title"
import Slider from "react-slick";
import "./flow.css"
import useAssets from '../../../../common/useAssets';
const cdn = useAssets(false);
class flow extends Component{
    render() {
        var settings = {
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            variableWidth: true
        };
        const flowList = this.props.flowList
        return (
            <div>
                <div className="flow-carousel">
                    <Slider {...settings}>
                        {flowList.map((item, index) =>
                            (
                                <div key={index} className="flow-wrapper">
                                    <div>
                                        <div className="flow-block">
                                            <div className="fc-title">
                                                <div style={{textAlign: 'justify'}}>{item.title}</div>
                                            </div>
                                            <div className="fc-describe">{item.content}</div>
                                            <div className="fc-index">
                                                <img alt="arrow" src={cdn(`images/onlineRetailers/flow/${index + 1}.png`)} />
                                            </div>
                                        </div>
                                        {
                                            flowList.length !== index + 1 ? (
                                                <div className="fc-arrow-wrapper">
                                                    <img alt="arrow" src={cdn('images/onlineRetailers/arrow.png')} />
                                                </div>) : ''
                                        }
                                    </div>
                                </div>
                            )
                        )}
                    </Slider>
                </div>
            </div>
        )
    }
}
export default flow;