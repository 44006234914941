import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

export const tvc = cdn('video/home/tvc.mp4');
export const Subtract = cdn('images/home/Subtract.png', 1100 );
export const Union = cdn('images/home/Union.png', 1100);
export const title5 = cdn('images/home/title5.png', 1200);

export const list1 = [
    {
        title: '《敦煌》',
        name: '@彭特务 x 卡姿兰敦煌',
        image: cdn('images/home/01-4.png', 600), // -zip
        video: cdn('video/home/01-4.mp4')
    },
    {
        title: '《双重的我》',
        name: '@祖祖zuzu x 冰希黎',
        image: cdn('images/home/01-6.png'), // -zip
        video: cdn('video/home/01-6.mp4')
    },
    {
        title: '《方圆无界》',
        name: '@腻腻ninii x 卡地亚',
        image: cdn('images/home/01-7.png'), // -zip
        video: cdn('video/home/01-7.mp4')
    },
    {
        title: '《偷心猎人》',
        name: '@彭特务 x ASSASSINA',
        image: cdn('images/home/01-5.png', 600), // -zip
        video: cdn('video/home/01-5.mp4')
    },
    {
        title: '《一生锁爱》',
        name: '@彭特务 x 花西子',
        image: cdn('images/home/01-2.png', 600),
        video: cdn('video/home/01-2.mp4')
    },
    {
        title: '《成都》',
        name: '@彭特务 x 雅顿',
        image: cdn('images/home/01-3.png', 600), // -zip
        video: cdn('video/home/01-3.mp4')
    },
    {
        title: '《特工NI终极任务》',
        name: '@腻腻ninii x 完美日记',
        image: cdn('images/home/01-1.png', 600),
        video: cdn('video/home/01-1.mp4')
    },
]
export const list2 = [
    {
        title: 'KOL业务',
        imageL: cdn('images/home/02-1-1.png', 500), // -zip
        image: cdn('images/home/02-1-2.png', 500), // -zip
        imageR: cdn('images/home/02-1-3.png', 500),
    },
    {
        title: '达人业务',
        image: cdn('images/home/02-2.png', 1200),
        url: '/sharing'
    },
    {
        title: '知识付费',
        image: cdn('images/home/02-3.png', 500),
        url: '/starClass'
    },
]