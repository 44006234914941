import React,{Component} from "react";
import "./index.css"
import Title from "../../../starResources/title"
import useAssets from '../../../../../common/useAssets';
const cdn = useAssets(false);
const bgSrc = cdn('images/onlineRetailers/lightShop/bg.png')
const degreesToRads = deg => (deg * Math.PI) / 180.0;
const map = ['zero','first', 'second', 'third', 'forth', 'fifth', 'sixth'];

const frequency = 30;
class lightShop extends Component{
    dom = []
    constructor(props) {
        super();
        this.state = {
            min: 2,
            max: props.personList.length - 4,
            personList: props.personList,
            center: 2,
            interval: null,
            timeList: [],
            isFreeze: false,
            stroke: 0,
            stop: false,
        }
        this.stop = false;
        this.num = 0;
    }
    componentWillUnmount() {
        if(this.interval) {
            clearInterval(this.interval)
        }
    }
    componentDidMount() {
        this.initData();
    }

    initData() {
        this.clearClassName();
        this.setClassName();
        this.setPosition();
        this.handleInterval();
    }

    setClassName() {
        const doms = document.querySelectorAll('.ls-content section');
        if(doms[this.state.center - 3]){
            doms[this.state.center - 3].classList.add(map[0])
        }
        doms[this.state.center - 2].classList.add(map[1])
        doms[this.state.center - 1].classList.add(map[2])
        doms[this.state.center].classList.add(map[3])
        doms[this.state.center + 1].classList.add(map[4])
        doms[this.state.center + 2].classList.add(map[5])
        if( doms[this.state.center + 3]){
            doms[this.state.center + 3].classList.add(map[6])
        }
    }
    clearClassName() {
        const doms = document.querySelectorAll('.ls-content section');
        doms.forEach((item) => {
            item.classList = []
        }
        )
    }
    setPosition() {
        this.setDegrees(38)
    }
    setDegrees(degree) {

        const doms = document.querySelectorAll('.ls-content section');
        this.getPosition(degree - 95, doms[this.state.center - 3])
        this.getPosition(degree - 76, doms[this.state.center - 2])
        this.getPosition(degree - 57, doms[this.state.center - 1])
        this.getPosition(degree - 38, doms[this.state.center])
        this.getPosition(degree - 19, doms[this.state.center + 1])
        this.getPosition(degree, doms[this.state.center + 2])
        this.getPosition(degree + 19, doms[this.state.center + 3])
    }
    resetThirdSize(degree) {
        const changeDegree = 38 - degree;
        const width =371 - changeDegree * (371 - 252) / 19;

        const dom = document.querySelector('.ls-content .third div');
        if(dom){
            dom.style.width = width + 'px';
            dom.style.height = width + 'px';
        }
    }
    resetForthSize(degree) {
        const changeDegree = 38 - degree;
        const width =changeDegree * (371 - 252) / 19 + 251;

        const dom = document.querySelector('.ls-content .forth div');
        if(dom){
            dom.style.width = width + 'px';
            dom.style.height = width + 'px';
        }
    }
    getPosition(angle, dom) {
        if(!dom) {
            return;
        }
        const width = window.innerWidth * 0.67;
        const r = width * 2 / Math.sqrt(3);
        const deg = degreesToRads(angle);
        const top =  r * Math.cos(deg) -  r * Math.cos(degreesToRads(40));
        const left = r * Math.sin(degreesToRads(40)) + r * Math.sin(deg);
        dom.style.left = left + 'px';
        dom.style.top = top + 'px';
    }
    handleInterval() {
        this.interval = setInterval(() => {
                this.moveStroke('right')
                this.once.bind(this)()
            }
        ,4000);
    }
    once() {
        this.stop = true;
        const temp =[];
        for(let i = 1; i <= frequency; i++) {
            temp[i] =  setTimeout(
                () => {
                    const degree = 38 - 19 * i / frequency
                    this.setDegrees(degree)
                    this.resetThirdSize(degree)
                    this.resetForthSize(degree)
                    if(i === frequency) {
                        this.getNext()
                    }
                }, i * 1000 / frequency
            )
        }
        this.setState({
            timeList: temp
        })
    }
    getNext() {
        const {center, max, personList} = this.state;
        this.stop = false;
        if (center !== max) {
            this.setState({
                center: center + 1
            });
            this.clearClassName();
            this.setClassName();
            this.setPosition();
        }

        if (max - center === 1) {
            const list = JSON.parse(JSON.stringify(personList));
            const newData = list[this.num];
            this.num = this.num + 1
            list.push(newData)
            this.setState({
                max: max + 1,
                personList: list,
            })
        }
    }
    getShowImg() {
        return document.querySelector('.ls-content section.show-block');
    }
    getRelativePosition() {
        const dom = document.querySelector('.ls-content');
        return getComputedStyle(dom).height;
    }
    toRight() {
        if (this.stop) return;
        this.stop = true;
        /*if(this.state.isFreeze) return;
        this.setState({isFreeze: true})
        setTimeout( () => { this.setState({isFreeze: false}) }, 1000)
        if(this.state.isFreeze) {
            return;
        }*/
        this.moveStroke('right');
        this.clearTimer()
        clearInterval(this.interval)
        this.clearClassName();
        this.setClassName()
        this.setPosition()
        this.once()
        this.handleInterval()
        /*if(this.state.center !== this.state.max + 1) {
            this.moveStroke('right');
            this.clearTimer()
            clearInterval(this.interval)
            this.clearClassName();
            this.setClassName()
            this.setPosition()
            this.once()
            this.handleInterval()
        }*/
    }
    clearTimer() {
        this.state.timeList.forEach((item) => {
            clearTimeout(item)
        })
    }
    toLeft() {
        if (this.stop) return;
        /*if(this.state.isFreeze) return;
        this.setState({isFreeze: true})
        setTimeout( () => { this.setState({isFreeze: false}) }, 1000)
        */
        const {center, min} = this.state;
        if(center === min) {
            return
        }
        this.setState({
            center: this.state.center - 1
        })
        this.clearClassName()
        this.setClassName()
        this.setPosition()
        this.clearTimer()
        this.leftMove()
        clearInterval(this.interval)
        this.handleInterval()
    }
    leftMove() {
        this.moveStroke('left');
        for(let i = 1; i <= frequency; i++) {
            const temp =[];
            temp[i] =  setTimeout(
                () => {
                    const degree = 19 + 19 * i / frequency
                    this.setLeftMove(degree)
                    this.resetSecondLeftSize(degree)
                    this.resetThirdLeftSize(degree)
                    if(i === frequency) {
                        this.clearClassName();
                        this.setClassName()
                        this.setPosition()
                    }
                }, i * 1000 / frequency
            )
            this.setState({
                timeList: temp
            })
        }
    }
    resetThirdLeftSize(degree) {
        const changeDegree = 38 - degree;
        const width =changeDegree * (371 - 252) / 19 + 251;

        const dom = document.querySelector('.ls-content .third div');
        if(dom){
            dom.style.width = width + 'px';
            dom.style.height = width + 'px';
        }
    }
    resetSecondLeftSize(degree) {

        const changeDegree = 38 - degree;
        const width =371 - changeDegree * (371 - 252) / 19;

        const dom = document.querySelector('.ls-content .second div');
        if(dom){
            dom.style.width = width + 'px';
            dom.style.height = width + 'px';
        }
    }

    setLeftMove(degree) {
        const doms = document.querySelectorAll('.ls-content section');
        this.getPosition(degree - 95, doms[this.state.center - 3])
        this.getPosition(degree - 76, doms[this.state.center - 2])
        this.getPosition(degree - 57, doms[this.state.center - 1])
        this.getPosition(degree - 38, doms[this.state.center])
        this.getPosition(degree - 19, doms[this.state.center + 1])
        this.getPosition(degree, doms[this.state.center + 2])
        this.getPosition(degree + 19, doms[this.state.center + 3])
    }

    moveStroke(type) {
        let data = 0;
        this.timer = setInterval(() => {
            if (type === 'left') {
                data -= 5;
            } else {
                data += 5;
            }
            if (data > 160 || data < -160) {
                clearInterval(this.timer);
                return;
            }
            this.setState({
                stroke: data
            });
        }, 30);
    }

    render() {
        const title = "轻店红人矩阵"
        const right = cdn('images/onlineRetailers/right.png')
        const left = cdn('images/onlineRetailers/left.png')
        const describe = "孵化轻店红人。3-6个月通过红人带货能力测试后，确定可以为该红人开设轻店铺。定制传播方案，输出种草\n内容，与粉丝互动并引导粉丝进店购买，流量精准导入。"
        return (
            <div className="light-shop">
                <div className="shop-describe">
                    {describe}
                </div>
                {/* <img style={{width:'100%', marginTop: '34px' }} className="light-img" src={src} alt="轻店红人"/> */}
                <div className="ls-content" ref={(lsDOM) => {this.lsDOM = lsDOM}}>
                    {/*<img src={bgSrc}  alt="背景" />*/}
                    <div style={{position: 'relative', top: -65}}>
                        <svg width="100%" height="100%" viewBox="0 0 1440 333" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path style={{strokeDashoffset: this.state.stroke}} d="M-37.96 3.99534C393.077 435.863 1090.15 437.64 1519 7.96472" stroke="#49C5B6" strokeWidth="6" strokeLinecap="round" strokeDasharray="0.01 16" />
                        </svg>
                    </div>
                    {
                        this.state.personList.map((item, index) => {
                            return (
                                <section className="ls-card" key={index}>
                                    <div>
                                        <img src={item.src} alt="红人图"/>
                                    </div>
                                    <span>@{item.name}</span>
                                </section>
                            )
                        })
                    }
                </div>
                <div className="ls-operate">
                    <div className='icon-slice' onClick={this.toLeft.bind(this)} />
                    <div className='icon-slice right' style={{marginLeft: 35}} onClick={this.toRight.bind(this)} />
                </div>
            </div>
        )
    }
}
export default lightShop;