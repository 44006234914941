import React,{Component} from "react";
import Title from "../../starResources/title"
import "./pattern.css"
import useAssets from '../../../../common/useAssets';
import ContentItem from "../../../element/contentItem";
const cdn = useAssets(false);
class pattern extends Component{
    state = {
        choose: 0,
    }
    click(index) {
        this.setState({choose: index})
    }
    render() {
        const patternList = this.props.patternList
        const title = "合作模式"
        const src = cdn('images/onlineRetailers/pattern/bg-expend.png');
        const srcTop = cdn('images/backtotop.png');
        return (
            <div className="online-pattern">
                <div className="pattern-wrapper">
                    {
                        patternList.map(
                            (item, index) => { 
                                return (
                                    <React.Fragment key={index}>
                                        <div  onClick={this.click.bind(this, index)} className="pattern-open" style={{display: `${this.state.choose === index ? 'flex' : 'none'}`}}>
                                            <img alt="合作图片" src={item.src}/>
                                            <div className="po-content"  style={{background: `url(${src})`, backgroundSize: `auto 100%`,backgroundPosition: `left top` }}>
                                                <div className="po-title">{item.title}</div>
                                                <div className="po-describe">{item.describe}</div>
                                            </div>
                                        </div>
                                        <div onClick={this.click.bind(this, index)}  className="pattern-close" style={{display: `${this.state.choose === index ? 'none' : 'block'}`, background: `url(${item.bgSrc})`, backgroundColor: '#fff', backgroundSize: `90% auto`, backgroundRepeat: 'no-repeat',backgroundPosition: `${item.position.replace('-', ' ')}` }}>
                                            <div className="pc-title">
                                                {item.title}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        )
                    }
                </div>

                <div className='shop-add'>
                    <a className="marketingEnter" href='https://www.kol18.com/' target="_blank" >
                        <label className="hand">商家注册入口</label>
                        <div className='iconfont icon-slice slice' />
                    </a>
                </div>
            </div>
        )
    }
}
export default pattern;