import React, {Component, Fragment} from 'react';
import Header from '../../layout/header/index';
import Footer from '../../layout/footer';
import './index.css';
import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

// const img0 = require("../../../assets/images/joinUs/0.png");
// const img1 = require("../../../assets/images/joinUs/1.png");
// const img2 = require("../../../assets/images/joinUs/2.png");
// const img3 = require("../../../assets/images/joinUs/3.png");
// const img4 = require("../../../assets/images/joinUs/4.png");
// const img5 = require("../../../assets/images/joinUs/5.png");
// const img6 = require("../../../assets/images/joinUs/6.png");
// const img7 = require("../../../assets/images/joinUs/7.png");
// const img8 = require("../../../assets/images/joinUs/8.png");
// const img9 = require("../../../assets/images/joinUs/9.png");

function getImg() {
    let arr = [];
    for (let i = 0; i < 93; i++) {
        // 93
        if (i !== 70) {
            arr.push(cdn(`images/joinUs/${i + 1}.jpg`, 500));
        }
    }
    return arr;
}

class page extends Component {
    constructor() {
        super();
        this.state = {
            imgArr: getImg(),
            imgArr1: [],
            imgArr2: [],
        };
        this.funArr = this.funArr.bind(this);
    }
    funArr() {
        let arr = [];
        while (arr.length < 10) {
            let ran = Math.floor(Math.random() * 93);
            if (arr.indexOf(ran) === -1) {
                arr.push(ran);
            }
        }
        return arr;
    }
    componentDidMount() {
        document.title = '加入如涵-如涵官网';
        this.setState({
            imgArr2: this.funArr().map(ele => this.state.imgArr[ele]),
        });
        // 初始化数据
        let active = document.getElementsByClassName('imgBox')[0];
        setInterval(() => {
            this.setState({
                imgArr1: this.funArr().map(ele => this.state.imgArr[ele]),
            });
            let time = setTimeout(() => {
                active.classList.remove('active');
                clearTimeout(time);
                this.setState({
                    imgArr2: JSON.parse(JSON.stringify(this.state.imgArr1)),
                });
            }, 7000);
            active.classList.add('active');
            // let temp = JSON.parse(JSON.stringify(this.state.imgArr));
            // this.setState({
            // 	imgArr1: temp.sort(() => {
            // 		return 0.5 - Math.random();
            // 	}),
            // });
        }, 5800);
    }
    render() {
        const {imgArr1, imgArr2} = this.state;
        return (
            <Fragment>
                <div className="flexccc joinUs">
                    <Header bg={{background: '#333333'}} />
                    <div className={'bg-wrap'}>
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                paddingBottom: '30%',
                                overflow: 'hidden',
                                bottom: 0,
                                left: 0,
                            }}
                        >
                            <img
                                className="Subtract"
                                src={cdn('images/joinUs/Subtract.png', 2000)}
                                alt="圈"
                            />
                        </div>
                        <div className="contentTitle_by" style={{marginTop: '110px'}}>
                            <div className="contentTitle_byb">
                                <div className="imgBox">
                                    {imgArr2.map((ele, i) => (
                                        <div key={`${i}`} className={`box${i} box`}>
                                            <div className="img2">
                                                <img src={imgArr2[i]} alt="" />
                                            </div>
                                            <div className="img1">
                                                <img src={imgArr1[i]} alt="" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div
                            className="contentTitle_by"
                            style={{
                                marginTop: 100,
                            }}
                        >
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt">如涵期待每一位伙伴的加入</p>
                                <div className="t1">
                                    <img
                                        style={{
                                            marginRight: '22px',
                                            width: '20px',
                                            height: '20px',
                                        }}
                                        src={cdn('images/joinUs/txt.png')}
                                        alt="图片"
                                    />
                                    <div style={{width: '66%', textAlign: 'left'}}>
                                        如涵拥有强大的红人影响力，优质红人精准覆盖18-35岁年轻女性群体，各平台均衡发展。如果您想成为如涵红人，请添加微信：ruhnn03，或微信扫描下方二维码。
                                    </div>
                                </div>
                                <img
                                    style={{width: '96px', height: '96px'}}
                                    src={cdn('images/joinUs/code.png')}
                                    alt="二维码"
                                />
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Fragment>
        );
    }
}
export default page;
