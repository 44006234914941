import React from 'react'

const MenuLevel = (props) => (
  <li
    onMouseOver={props.onMouseOver}
    onMouseLeave={props.onMouseLeave}
    className={(props.showMenuItem === props.index) ? 'menu-hover' : ''}
  >
    {props.url ? <a href={props.url}>{props.text}</a> : <span>{props.text}</span>}
    {props.children && <div className={(props.showMenuItem === props.index) ? 'submenu-show' : 'submenu-hidden'}>
      <div style={{ height: '8px', backgroundColor: 'rgba(0,0,0,0)' }}></div>
      {
        props.children.map((item, index) => (
          item.url && <a
            href={item.url}
            target={item.target}
            key={item.text + index}
            data-id={index}
            onMouseOver={props.onSubItemMouseOver}
            onMouseLeave={props.onSubItemMouseLeave}
            className={`${(props.showSubMenuItem === index) ? 'submenuitem-hover' : ''}  ${index === 0 ? 'radiusT' : ''} ${index === props.children.length - 1 ? 'radiusB' : ''}`}
          >
            {item.text}
          </a>
        ))
      }
    </div>
    }
  </li >
)

export default MenuLevel