import React, { Component, Fragment } from 'react';
// import {InView} from "react-intersection-observer";
import Header from '../../layout/header/index';
import Footer from '../../layout/footer';
import PersonIntroduce from './personIntroduce';
import PhotoList from './photoList';
import './introduce.css';
import { detailList } from './data.js';
// import classNames from "classnames";
// import {Subtract} from "../home/data";
import useAssets from '../../../common/useAssets';
import {Union} from "../advertising/data";
const cdn = useAssets(false);

class introduce extends Component {
  state = {
    //接收的数据
    id: Number(this.getQueryString('id'))
  };
  getQueryString(key) {
    var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substring(1).match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null;
  }
  render() {
    return (
      <Fragment>
        <div className='introduce'>
          <img className="Union" src={Union} alt="圈" />
          <Header bg={{background: '#333333'}} />
          <PersonIntroduce detailList={detailList} id={this.state.id} />
          <PhotoList detailList={detailList} id={this.state.id} />
          <Footer />
        </div>
      </Fragment>
    );
  }
}
export default introduce;
