import React, { Component, Fragment } from 'react';
import Header from '../../layout/header/index';
import Footer from '../../layout/footer';
import Pattern from './element/pattern'
import LightShop from './element/lightShop'
import Case from './element/case'
import Flow from './element/flow'
import Brand from './element/brand'
import Plan from './element/plan'
import QrCode from '../../layout/qrCode';
import './onlineRetailers.css'
import ContentTitle from '../../layout/contentTitle';
import ContentItem from '../../element/contentItem';

import useAssets from '../../../common/useAssets';
import {planData, patternList, lightShop, caseList, flowList} from './data'
import {Subtract, Union} from "../advertising/data";
const cdn = useAssets(false);

const onlineRetailersUrl = cdn('images/qrCode/onlineRetailers/code.png');

class page extends Component {
    componentDidMount() {
        document.title = '如涵电商业务-如涵官网';
    }

    render() {
        const list = [
            '如涵轻店计划',
            '合作模式',
            '轻店红人矩阵',
            '合作案例',
            '合作流程',
            '合作品牌'
        ]
        const headImg = cdn('images/onlineRetailers/head.png', 1000)
        return (
            <Fragment>
                <div className="online" >
                    <Header bg={{background: '#333333'}} />
                    <div className="nav-head-img">
                        <img src={headImg} alt="主图"/>
                        <div className='nav-head-text'>
                            <img alt="arrow" src={cdn(`images/onlineRetailers/banner/banner-text.png`)} />
                        </div>
                    </div>
                    <ContentTitle content={list}></ContentTitle>
                    {/* <Navigation list={list} choose={choose}/> */}
                    <ContentItem name='如涵轻店计划'>
                        <img className="Union" src={Union} alt="圈" />
                        <Plan {...planData}/>
                    </ContentItem>
                    <ContentItem name='合作模式'>
                        <Pattern patternList={patternList}/>
                    </ContentItem>
                    <ContentItem name='轻店红人矩阵'>
                        <LightShop personList={lightShop}/>
                    </ContentItem>
                    <ContentItem name='合作案例'>
                        <Case srcList={caseList}/>
                    </ContentItem>
                    <ContentItem name='合作流程'>
                        <Flow flowList={flowList}/>
                    </ContentItem>
                    <ContentItem name='合作品牌'>
                        <Brand/>
                    </ContentItem>
                    <Footer />
                    <QrCode codeUrl={onlineRetailersUrl} />
                </div>
            </Fragment>
        )
    }
}
export default page;