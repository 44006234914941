import useAssets from '../../../../../common/useAssets';
const cdn = useAssets(false);

export const awards1 = cdn('images/about/awards-1.png');
export const awards2 = cdn('images/about/awards-2.png');
export const awards3 = cdn('images/about/awards-3.png');

export const awardsList = [
    { year:2020, title:'抖in合伙人', image:cdn('images/about/awards-1.png') },
    { year:2021, title:'抖音-小李朝 抖in城市星推官+巨量星图达人节Battle King', image:cdn('images/about/awards-2.png')},
    { year:2020, title:'微博-年度红人机构奖十大影响力颜值机构十大影响力时尚机构', image:cdn('images/about/awards-3.png')},
    /*{ year:2019, title:'2019微博十大影响力颜值视频红人', image:cdn('images/about/awards-4.png')},*/
    { year:2020, title:'彭特务微博2020年度百大视频号', image:cdn('images/about/awards-5.png')},
   /* { year:2019, title:'2019微博十大影响力颜值大V', image:cdn('images/about/awards-6.png')}, */
    { year:2021, title:'小红书时尚潮流季-时尚造风者', image:cdn('images/about/awards-8.png')},
    { year:2020, title:'年度最具商业价值机构', image:cdn('images/about/awards-9.png')},
    /*{ year:2020, title:'宝剑嫂-最具影响力时尚自媒体', image:cdn('images/about/awards-11.png')},*/
    { year:2020, title:'小李朝ye-克劳锐最具影响力幽默搞笑自媒体', image:cdn('images/about/awards-12.png')},
    { year:2020, title:'淘美妆-最具影响力MCN机构', image:cdn('images/about/awards-13.png')},
    { year:2020, title:'彭特务淘美妆年度最具人气KOL', image:cdn('images/about/awards-14.png')},
    { year:2020, title:'Topdigital-红人KOL营销银奖', image:cdn('images/about/awards-15.png')},
    { year:2020, title:'CBE美博会美伊年度最具价值服务机构大奖', image:cdn('images/about/awards-16.png')},
    /*{ year:2020, title:'宝剑嫂-美伊年度创客大奖', image:cdn('images/about/awards-17.png')},*/
    { year:2021, title:'特务-美伊年度创客大奖', image:cdn('images/about/awards-18.png')},
    { year:2021, title:'精致大姨夫-CBE美博会美伊年度匠人创客大奖', image:cdn('images/about/awards-19.png')},
    /*{ year:2021, title:'曾子容-美伊年度创客大奖', image:cdn('images/about/awards-20.png')},*/
    { year:2021, title:'中国化妆品大会蓝玫奖年度最具影响力MCN机构2021', image:cdn('images/about/awards-21.png')},
    { year:2021, title:'金狮-最佳短视频MCN机构', image:cdn('images/about/awards-22.png')},
    { year:2020, title:'中国广告协会年度价值主播机构', image:cdn('images/about/awards-23.png')},
    /*{ year:2020, title:'2020B站百大UP主宝剑嫂', image:cdn('images/about/awards-24.png')},
    { year:2020, title:'2020B站百大UP主雨哥', image:cdn('images/about/awards-25.png')},
    { year:2020, title:'2020B站百大UP主宝剑嫂雨哥', image:cdn('images/about/awards-26.png')},*/
    { year:2020, title:'2020年微播易-年度影响力机构', image:cdn('images/about/awards-27.png') },
    { year:2021, title:'2021年品观化妆品蓝玫奖-2021年度最具影响力MCN机构', image:cdn('images/about/awards-28.png') },
    { year:2021, title:'2021年金狮国际广告影片奖-最佳短视频MCN机构(花西子)', image:cdn('images/about/awards-29.png') },
    { year:2021, title:'2021年千瓜数据-最具商业价值MCN机构', image:cdn('images/about/awards-30.png') },
    { year:2021, title:'2021年中国广告协会-年度影响力机构', image:cdn('images/about/awards-31.png') },
    { year:2021, title:'2021年微博-2021年度十大影响力颜值机构', image:cdn('images/about/awards-32.png') },
    { year:2021, title:'2021年微博-2021年度十大影响力时尚机构', image:cdn('images/about/awards-33.png') },
    { year:2021, title:'2021年微博-2021年度微博红人机构', image:cdn('images/about/awards-34.png') },

    { year:2021, title:'2021年上海国际广告节-年度MCN机构', image:cdn('images/about/awards-35.png') },
    { year:2022, title:'2022年精致大姨夫-Topdigital年度影响力红人', image:cdn('images/about/awards-36.png') },
    { year:2022, title:'2022年卡姿兰20周年整合营销-Topdigital整合营销铜奖', image:cdn('images/about/awards-37.png') },
    { year:2022, title:'2022年品观CiE美妆大赏-最具影响力MCN', image:cdn('images/about/awards-38.png') },
    { year:2022, title:'2022年精致大姨夫-品观CiE美妆大赏最具增长力KOL', image:cdn('images/about/awards-39.png') },
    { year:2022, title:'2022年Admen国际大奖-2022商业价值总评榜年度最具成长力公司', image:cdn('images/about/awards-40.png') },
    { year:2022, title:'22022年纽西之谜 X 如涵之夜案例-实战金奖', image:cdn('images/about/awards-41.png') },

]
