import React from 'react'
import MenuLevel from './MenuLevel'
import useAssets from '../../../common/useAssets';
import classNames from 'classnames';
const cdn = useAssets(false);

const menuitems = [
    {
        text: '加入我们',
        submenu: [
            {
                text: '成为红人',
                url: '/joinUs',
            },
             {
                text: '社会招聘',
                url: 'https://ewbmxoqgfx.jobs.feishu.cn/index',
                 target: '_blank',
            },
            {
                text: '校园招聘',
                url: 'https://ewbmxoqgfx.jobs.feishu.cn/652250',
                target: '_blank',
            },
        ]
    },
    {
        text: '品牌合作',
        url: '/coBranding',
    },
    {
        text: '红人资源',
        url: '/starResources',
    },
    {
        text: '业务介绍',
        submenu: [
            {
                text: '爱种草',
                url: '/sharing',
            },
            /*{
                text: '星课堂',
                url: '/starClass',
            },*/
            {
                text: '直播业务',
                url: '/marketing',
            },
            {
                text: '广告业务',
                url: '/advertising',
            },
            {
                text: '电商业务',
                url: '/onlineRetailers',
            },
        ]
    },
    {
        text: '关于如涵',
        url: '/about',
    }
]

class page extends React.Component {
    constructor() {
        super()
        this.state = {
            showMenuItem: -1,
            showSubMenuItem: -1,
        }
    }

    handleMenuLevelHover = (index) => {
        this.setState({ showMenuItem: index })
    }

    handleMenuLevelLeave = () => {
        this.setState({ showMenuItem: -1 })
    }

    handleSubMenuLevelHover = (index, e) => {
        this.setState({
            showMenuItem: index,
            showSubMenuItem: +e.target.attributes.getNamedItem('data-id').value
        })
    }

    handleSubMenuLevelLeave = (e) => {
        this.setState({ showSubMenuItem: -1 })
    }

    render() {
        const { bg = {}, className } = this.props;
        return (
            <div className={classNames('headerMenu', className)} style={{ ...bg }}>
                <a href="/" ><img className="headerMenu_img" src={cdn('images/logo.png', 1800)} alt="logo" /></a>
                <ul>
                    {
                        menuitems.map((level, index) => (
                            <MenuLevel
                                text={level.text}
                                url={level.url}
                                key={index}
                                index={index}
                                onMouseOver={() => { this.handleMenuLevelHover(index) }}
                                onMouseLeave={this.handleMenuLevelLeave}
                                onSubItemMouseOver={(e) => { this.handleSubMenuLevelHover(index, e) }}
                                onSubItemMouseLeave={this.handleSubMenuLevelLeave}
                                showSubMenuItem={this.state.showSubMenuItem}
                                showMenuItem={this.state.showMenuItem}
                            >
                                {level.submenu}
                            </MenuLevel>
                        ))
                    }
                    <div></div>
                </ul>
            </div>
        )
    }
}

export default page