import React, { Component, Fragment } from 'react';
import { historyImgList,historyYearList } from './data';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classNames from 'classnames';

class History extends Component {
   constructor(){
       super();
       this.state = {
           nav1: null,
           nav2: null
       }
   }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    handleSlickGoTo = () => {
        this.slider2.slickGoTo(0)
    }

    render() {
        return (
            <Fragment>
                {/*<img src={historyImgList[currShowIndex]}  alt="大事记" className="belisted"  />*/}
                {/*<div className="timeline flexrac" style={{ marginTop:'54px' }}  >*/}
                {/*    {historyYearList.map((year,index)=>(*/}
                {/*    <div className={index===currShowIndex?'timeline-item on':'timeline-item'}*/}
                {/*        onClick={()=>this.onChange(index)} >*/}
                {/*        <label className="hand">{year}</label>*/}
                {/*        <i></i>*/}
                {/*    </div>*/}
                {/*    ))}*/}
                {/*    <div className="timeline-cord" ></div>*/}
                {/*</div>*/}
                <div style={{ width: 605 }}>
                <Slider
                    speed={1000}
                    asNavFor={this.state.nav2}
                    ref={slider => (this.slider1 = slider)}
                    arrows={false}
                    variableWidth={true}
                    infinite={false}
                    slidesToShow={1}
                    slidesToScroll={1}
                    className={'history'}
                >
                    {historyImgList.map(url => (
                        <div key={url}>
                            <div style={{overflow: 'hidden'}}><img className="belisted" src={url} alt="大事记" /></div>
                        </div>
                    ))}
                </Slider>
                </div>
                <div className={'timeline'}>
                    <Slider
                        speed={1000}
                        sliderActive={2}
                        autoplay={true}
                        centerPadding={0}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        slidesToShow={1}
                        slidesToScroll={1}
                        variableWidth={true}
                        focusOnSelect={true}
                        arrows={false}
                        infinite={false}
                        centerMode={true}
                        afterChange={current => {
                            if (historyYearList.length - 1 === current) {
                                if (this.timer) clearTimeout(this.timer);
                                this.timer = setTimeout(() => {
                                    this.slider2.slickGoTo(0, false);
                                }, 2000)
                            }

                        }}
                    >
                        {historyYearList.map((year,index)=>(
                            <div key={year} className={classNames(`timeline-item ${index === historyYearList.length -1 ? 'timeline-item-hidden' : ''}`)} style={{width: 165}}>
                                <label className="hand">{year}</label>
                                <br/>
                                <i />
                            </div>
                        ))}
                    </Slider>
                </div>
            </Fragment>
        )
    }
}
export default History;