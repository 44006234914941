import React, { Component, Fragment } from 'react';
import './index.css';
import { joinUrl,arrow,buession1,buession2,buession3 } from './data'
class TeamInfo extends Component {
   render() {
        return (
            <Fragment>
                <div style={{marginBottom:'6px'}} className="flexrbs teamInfo">
                            <div className=" flexccs teamInfoItem">
                                <div className="img"><img src={buession1}  alt="客户服务团队" className="imgGray"  /></div>
                                <div className="ft20 label">
                                客户服务团队
                                </div>
                                <p>为客户定制社交媒体整合投放策略，匹配最优KOL矩阵资源，专业AE全程跟进投放执行，保障项目完美落地</p>
                            </div>
                            <div className=" flexccs teamInfoItem">
                                <div className="img" ><img src={buession2}  alt="" className="imgGray" style={{ marginRight:'23px'}} /></div>
                                <div className="ft20 label">
                                内容团队
                                </div>
                                <p>为红人提供视频类、平面类的专业内容服务，定制红人专属IP视觉风格，帮助红人提升内容调性和内容能力</p>
                            </div>
                            <div className=" flexccs teamInfoItem">
                                    <div className="img" ><img src={buession3}  alt="" className="imgGray" style={{ marginRight:'23px'}} /></div>
                                    <div className="ft20 label">
                                    运营团队
                                    </div>
                                    <p>为红人发展提供全阶段运营规划及经纪服务，以提升红人影响力与商业价值为目标，打造优质kol资源</p>
                            </div>
                            {/*<a className="aboutJoin"  href={joinUrl} target="_top" >
                                <label className="hand">加入我们</label>
                                <img src={arrow} alt="加入我们" />
                            </a>*/}
                </div>
            </Fragment>
        )
    }
}
export default TeamInfo;