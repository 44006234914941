import useAssets from '../../../../../common/useAssets';
const cdn = useAssets(false);

export const historyImgList = [
    cdn('images/about/history-2014.png'),
    cdn('images/about/history-2015.png'),
    cdn('images/about/history-2016.png'),
    cdn('images/about/history-2017.png'),
    cdn('images/about/history-2018.png'),
    cdn('images/about/history-2019.png'),
    cdn('images/about/history-2020.png'),
    cdn('images/about/history-2021.png')
];

export const historyYearList = [2014,2015,2016,2017,2018,2019,2020,2021]; 