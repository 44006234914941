import React, { Component, Fragment } from 'react';
import { InView } from 'react-intersection-observer';
import Header from '../../layout/header/index';
import Swiper from '../../element/swiper';
import Video from '../../element/video';
import Footer from '../../layout/footer';
import Carousel from './carousel';
import { tvc, list1, list2, Subtract, Union, title5 } from './data';
import "./index.css";
import classNames from 'classnames';
import Brand from "../onlineRetailers/element/brand";

class page extends Component {
    state = {
        homeT1tCoverHide: false,
        renderOne: false,
        renderTwo: false,
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({renderOne: true});
        }, 550);
        setTimeout(() => {
            this.setState({renderTwo: true});
        }, 1000);

    }

    render() {
        const { homeT1tCoverHide, renderOne, renderTwo } = this.state;

        return (
            <Fragment>
                <div className="flexccc home" >
                    <Header bg={{ background: 'rgba(0, 0, 0, 0.2)' }} />
                    <div style={{ width: '100%', marginTop: '-72px' }}>
                        <Video wrapProp={{style: {width: '100%', maxHeight: '100vh', overflow: 'hidden'}}} videoUrl={tvc} autoPlay={true} loop={true} />
                        <div className="contentTitle_by" >
                            <div className="contentTitle_byb">
                                <p className="contentTitle_byt">今天的如涵</p>
                                <p style={{ top: '38px', width: '68px' }} className="underline" />
                                 <div className="t1">
                                    <div>国内领先的电商红人孵化与营销平台</div>
                                    <div>阿里巴巴首个入股的MCN机构</div>
                                    <div>具有成熟的红人培训体系和深厚的红人储备</div>
                                    <div>致力于为更多品牌实现品效合一</div>
                                </div>
                                <div style={{ display: 'flex', marginTop: '40px' }}>
                                    {
                                        [{
                                            num1: '200+',
                                            title1: '独家KOL',
                                            num2: '20000+',
                                            title2: '入驻达人',
                                        },
                                        {
                                            num1: <span>4.71<span style={{ fontSize: '24px' }}>亿</span></span>,
                                            title1: '独家KOL粉丝',
                                            num2: <span>34.13<span style={{ fontSize: '24px' }}>亿</span></span>,
                                            title2: '达人全网粉丝',
                                        },
                                        {
                                            num1: '3000+',
                                            title1: '合作品牌',
                                            num2: '2000+',
                                            title2: '入驻商家',
                                        }].map((item, index) => (
                                            <div key={index} className="contentTitle_num" style={{width: 313, margin: index == 1 ? '0 15%' : '0' }}>
                                                <div>
                                                    <p className="p1">{item.num1}</p>
                                                    <p className="p2">{item.title1}</p>
                                                </div>
                                                <div className="contentTitle_shu"></div>
                                                <div>
                                                    <p className="p1">{item.num2}</p>
                                                    <p className="p2">{item.title2}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        {renderOne && (
                            <div className="contentTitle_by" >
                                <InView>
                                    {({ inView, ref, entry }) => (
                                        <img ref={ref} className={classNames('Subtract', {on: inView})} src={Subtract} alt="圈" />
                                    )}
                                </InView>
                                <div className="contentTitle_byb">
                                    <p className="contentTitle_byt">优质案例</p>
                                    <p style={{ top: '38px', width: '68px' }} className="underline" />
                                    <div style={{ width: '63vw' }}>
                                        <Swiper
                                            ref={ref => this.homeT1t = ref && ref.slider && ref.slider}
                                            type={'banner'}
                                            touchMove={false}
                                            hasArrows={false}
                                        >
                                            {
                                                list1.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div style={{ width: '100%', borderRadius: '20px', minHeight: '36vw', maxHeight: '36vw', overflow: 'hidden', position: 'relative' }}>
                                                                <video
                                                                    loop
                                                                    controls
                                                                    style={{ width: '100%', height: '100%' }}
                                                                    className={`player${index}`}
                                                                    poster={item.image}
                                                                    src={item.video}
                                                                />
                                                                {
                                                                    !homeT1tCoverHide &&
                                                                    <div className="homeT1tCover"
                                                                         onClick={() => {
                                                                             this.setState({
                                                                                 homeT1tCoverHide: true
                                                                             })
                                                                             const players = document.querySelectorAll(`.home .slick-swiper .player${index}`);
                                                                             if (players && players.length > 0) {
                                                                                 for (let i = 0; i < players.length; i++) {
                                                                                     const dom = players[i].parentNode.parentNode.parentNode.parentNode
                                                                                     if (index == dom.getAttribute("data-index")) {
                                                                                         players[i].play();
                                                                                     }
                                                                                 }
                                                                             }
                                                                         }}
                                                                    >
                                                                        <div className="play"></div>
                                                                        <img
                                                                            style={{ width: '100%', height: '100%', borderRadius: '20px', objectFit: 'cover'}}
                                                                            src={item.image}
                                                                            alt="内容"
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                        <div style={{ margin: '20px -5px 0' }}>
                                            <Swiper
                                                ref={ref => this.homeT2t = ref && ref.slider && ref.slider}
                                                touchMove={false}
                                                type={'banner'}
                                                slidesToShow={5}
                                                afterChange={(currentSlide) => {
                                                    this.homeT1t && this.homeT1t.slickGoTo(currentSlide)
                                                    this.setState({
                                                        homeT1tCoverHide: false
                                                    })
                                                }}
                                                beforeChange={(currentSlide, nextSlide) => {
                                                    // 切换暂停视频播放
                                                    const players = document.querySelectorAll(`.home .slick-swiper .player${currentSlide}`);
                                                    if (players && players.length > 0) {
                                                        for (let i = 0; i < players.length; i++) {
                                                            if (players[i].play) {
                                                                players[i].pause();
                                                            }
                                                        }
                                                    }
                                                }}
                                            >
                                                {
                                                    list1.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div className={'homeT1Cover-wrap'} style={{boxSizing: 'border-box',padding: '0 5px', width: '100%', cursor: 'pointer', borderRadius: '20px', maxHeight: '36vw', overflow: 'hidden', position: 'relative' }}
                                                                     onClick={() => {
                                                                         this.homeT2t && this.homeT2t.slickGoTo(index)
                                                                         this.homeT1t && this.homeT1t.slickGoTo(index)
                                                                         this.setState({
                                                                             homeT1tCoverHide: false
                                                                         })
                                                                     }}
                                                                >
                                                                    <div className="homeT1Cover">
                                                                        <div className="homeT1Cover-by">
                                                                            <div className="homeT1Cover-t">{item.title}</div>
                                                                            <div className="homeT1Cover-c">{item.name}</div>
                                                                        </div>
                                                                    </div>
                                                                    <img style={{ width: '100%', height: '130px', borderRadius: '20px',objectFit: 'cover' }} src={item.image} alt="内容" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {renderTwo && (
                            <div className="contentTitle_by" >
                                <div className="contentTitle_byb">
                                    <p className="contentTitle_byt">业务介绍</p>
                                    <p style={{ top: '38px', width: '68px' }} className="underline" />
                                    <div style={{ width: '63vw', }}>
                                        {/* <Swiper type={'banner'} hasArrows={false}>list={list2}
                                            {
                                                list2.map((item, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {
                                                                index == 0 ?
                                                                    (
                                                                        <div className="flex">
                                                                            <div className="imgHover">
                                                                                <a href="/marketing">
                                                                                    <img className="imgGray" style={{ width: '100%' }} src={item.imageL} alt="内容" />
                                                                                    <div className="describe">
                                                                                        <div className="describe-t">直播业务</div>
                                                                                        <div className="describe-c">精准定位，内容种草为品牌力增值。</div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                            <div className="imgHover" style={{ margin: '0 20px' }}>
                                                                                <a href="/advertising">
                                                                                    <img className="imgGray" style={{ width: '100%' }} src={item.image} alt="内容" />
                                                                                    <div className="describe">
                                                                                        <div className="describe-t">广告业务</div>
                                                                                        <div className="describe-c">个性化定制，投放效能最大化。</div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                            <div className="imgHover">
                                                                                <a href="/onlineRetailers">
                                                                                    <img className="imgGray" style={{ width: '100%' }} src={item.imageR} alt="内容" />
                                                                                    <div className="describe">
                                                                                        <div className="describe-t">电商业务</div>
                                                                                        <div className="describe-c">助力品牌站内导流及高效转化。</div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (
                                                                        <div style={{ width: '100%', borderRadius: '20px', maxHeight: '26vw', overflow: 'hidden' }}>
                                                                            <a href={item.url}>
                                                                                <img style={{ width: '100%' }} src={item.image} alt="内容" />
                                                                            </a>
                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Swiper> */}
                                        {
                                            list2.slice(0,1).map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        {
                                                            index === 0 ?
                                                                (
                                                                    <div className="flex">
                                                                        <div className="imgHover">
                                                                            <a href="/marketing">
                                                                                <img className="imgGray" style={{ width: '100%' }} src={item.imageL} alt="内容" />
                                                                                <div className="describe-wrap">
                                                                                    <div className="describe">
                                                                                        <div className="describe-t">直播业务</div>
                                                                                        <div className="describe-c">精准定位，内容种草为品牌力增值。</div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div className="imgHover" style={{ margin: '0 20px' }}>
                                                                            <a href="/advertising">
                                                                                <img className="imgGray" style={{ width: '100%' }} src={item.image} alt="内容" />
                                                                                <div className="describe-wrap">
                                                                                <div className="describe">
                                                                                    <div className="describe-t">广告业务</div>
                                                                                    <div className="describe-c">个性化定制，投放效能最大化。</div>
                                                                                </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div className="imgHover">
                                                                            <a href="/onlineRetailers">
                                                                                <img className="imgGray" style={{ width: '100%' }} src={item.imageR} alt="内容" />
                                                                                <div className="describe-wrap">
                                                                                <div className="describe">
                                                                                    <div className="describe-t">电商业务</div>
                                                                                    <div className="describe-c">助力品牌站内导流及高效转化。</div>
                                                                                </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                : null
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        <div style={{height: 30}} />
                                        {
                                            list2.slice(1,2).map((item, index) => {
                                                return (
                                                    <div className={'imgHover-new'} key={index}>
                                                        <div style={{ width: '100%', borderRadius: '20px', maxHeight: '26vw' }}>
                                                            <a href={item.url}>
                                                                <img className={'imgGray'} style={{ width: '100%' }} src={item.image} alt="内容" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        {renderTwo && (
                            <div className="contentTitle_by" >
                                <InView>
                                    {({ inView, ref, entry }) => (
                                        <img ref={ref} className={classNames('Union', {on: inView})} src={Union} alt="圈" />
                                    )}
                                </InView>
                                <div className="contentTitle_byb">
                                    <p className="contentTitle_byt">如涵红人矩阵</p>
                                    <p style={{ top: '38px', width: '68px' }} className="underline" />
                                    <div style={{ width: '100vw', overflow: 'hidden', marginTop: '-10px' }}>
                                        <Carousel />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="contentTitle_by" style={{ marginTop: '100px' }}>
                            <Brand slider={true} />
                            {/*
                            <div className="contentTitle_byb">

                                <p className="contentTitle_byt">合作品牌</p>
                                <p style={{ top: '38px', width: '68px' }} className="underline" />
                                <div style={{width: '100%' }}>
                                    <img className="content-image" src={title5} alt="内容" />
                                     <img className="animationImg1" src={title5} alt="内容" />
                                </div>
                            </div>
                            */}
                        </div>
                    </div>
                    <Footer />
                </div >
            </Fragment >
        )
    }
}
export default page;