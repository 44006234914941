import React, { Component } from 'react';
import {otherKol} from '../starResources/data';
import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

const type = {
  '微博': 'wb',
  '抖音': 'dy',
  'bilibili': 'blbl',
  '小红书': 'xhs',
  '快手': 'kuaishou',
}

class personIntroduce extends Component {

  state = {
    effect: [],
  }

  componentDidMount() {
    this.handleFetch();
  }

  // 获取数据
  handleFetch() {
    const {id, detailList} = this.props;
    const data = detailList.find(i => i.id === id) || {};
    if (!data.name) return;
    const param = {simpleNameLike: otherKol[data.name] || data.name.replace('@', '')};
    fetch(`https://www.layercake18.com/drp/data/mall/common/kol/fans?simpleNameLike=${param.simpleNameLike}`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json'},
    })
        .then(response => response.json())
        .then(res => {
          if (!res.success) return;
          let {channelList = []} = res.result[0] || {};

          const index = data.name.indexOf('大姨夫');
          if (index > -1) {
            channelList = channelList.filter(i => ![5,9].includes(i.channelId));
          }
          if (channelList) {
            this.setState({
              effect: channelList.map(i => ({
                type: type[i.channelName],
                number: mun(i.fans),
                indexUrl: i.indexUrl,
              })),
            })
          }
        });
  }

  render() {
    const title = '红人简介';
    const information = {
      type: '平台独广',
      effect: [
        {
          type: 'dy',
          number: '80w'
        },
        {
          type: 'blbl',
          number: '70w'
        },
        {
          type: 'wb',
          number: '80w'
        },
        {
          type: 'xhs',
          number: '97w'
        }
      ]
    };

    const iconList = {
      dy: cdn('images/starResources/dy.png'),
      wb: cdn('images/starResources/wb.png'),
      blbl: cdn('images/starResources/blbl.png'),
      xhs: cdn('images/starResources/xhs.png'),
      kuaishou: cdn('images/marketing/kuaishou.png'),
    };

    return (
      <div className='content-wrapper'>
        <div className='person-title'>
          <div className='bar'></div>
          <span>{title}</span>
        </div>
        {this.props.detailList.map((item, index) => {
          if (item.id === this.props.id) {
            return (
              <div className='introduce-wrapper'>
                <div className='main-img'>
                  <img src={item.images[0]} alt='主图' />
                </div>
                <div style={{flex: 1}}>
                  <div className='introduce-title'>
                    <span className='font-bold'>{item.name}</span>
                    <div className='title-tag'>{information.type}</div>
                    {/* {information.typeList.map((item, index) => (
                      <div className='title-tag' key={index}>
                        {item}
                      </div>
                    ))} */}
                  </div>
                  <div className='introduce-title'>
                    <div className='person-property'>粉丝影响力</div>
                    <div className='person-describe part1'>
                      {this.state.effect.map((item, index) => (
                        <div key={index}>
                          <a href={item.indexUrl} target='_blank'>
                            <img src={iconList[item.type]} alt='图标' />
                            <span>{item.number}</span>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='introduce-title part2'>
                    <div className='person-property'>红人标签</div>
                    <div className='person-describe part2'>
                      {item.tags.map((item, index) => (
                        <span key={index}  className='tag'>{item}</span>
                      ))}
                    </div>
                  </div>
                  <div className='introduce-title part3'>
                    <div className='person-property'>红人简介</div>
                    <div className='person-describe'>
                      <div>{item.introduction}</div>
                    </div>
                  </div>
                  {item.hotSpots && (
                      <div className='introduce-title part4'>
                        <div className='person-property'>红人热点</div>

                        <div className='person-describe'>
                          {item.hotSpots.map((item, index) => (
                              <div className='hot' key={index}>
                                {item}
                              </div>
                          ))}
                        </div>
                      </div>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default personIntroduce;


function mun(value) {
  if (!value) return 0;
  if (value > 9999) {
    return (value / 10000).toFixed(0) + 'w';
  }
  return value;
}
