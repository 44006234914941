import React, { Component } from 'react';
import Title from './title';
import Tab from './tab';
import Swiper from '../../element/swiper';
import { Link } from 'react-router-dom';
import { list, weibo, redBook, bzhan, douyin, kuaishou } from './data';
// import Card from "./card"
class introduce extends Component {
  constructor(props) {
    super(props);

    let all = list;
    let alls = [];
    for (let i = 0; i < Math.ceil(all.length / 8); i++) {
      let arr = all.slice(i * 8, i * 8 + 8)
      alls.push(arr)
    }

    this.state = {
      choose: this.props.choose,
      showList: alls,
    };
  }
  changeChoice(key) {
    let all = [];
    let blbl = [];
    let dy = [];
    let wb = [];
    let xhs = [];
    list.forEach((item, index) => {
      if (item.blbl) blbl.push(item);
      if (item.dy) dy.push(item);
      if (item.wb) wb.push(item);
      if (item.xhs) xhs.push(item);
    })

    if (key === 'all') {
      all = list;
    } else if (key === 'wb') {
      all = wb;
    } else if (key === 'dy') {
      all = dy;
    } else if (key === 'xhs') {
      all = xhs;
    } else if (key === 'blbl') {
      all = blbl;
    }
    let alls = [];
    for (let i = 0; i < Math.ceil(all.length / 8); i++) {
      let arr = all.slice(i * 8, i * 8 + 8)
      alls.push(arr)
    }
    this.slider && this.slider.slickGoTo(1)
    this.setState({
      choose: key,
      showList: alls,
    });
  }
  render() {
    const { title } = this.props;
    const { showList } = this.state;
    return (
      <div style={{ maxWidth: 1300, marginTop: 40 }}>
        <Title title={title} />
        <div className='tab-wrapper'>
          <Tab
            {...this.props}
            choose={this.state.choose}
            changeChoice={(key) => {
              this.changeChoice(key);
            }}
          />
        </div>
        <div className="starResources-introduce" style={{ width: '72%', margin: '0 auto' }}>
          <Swiper ref={ref => (this.slider = ref && ref.slider && ref.slider)} type={'banner'}>
            {
              showList.map((im, idx) => {
                return (
                  <div key={idx} >
                    {
                      [1, 2].map((m, i) => {
                        return (
                          <div key={i} className="introduce-flex">
                            {
                              im.slice(i * 4, i * 4 + 4).map((item, index) => {
                                return (
                                  <Link key={index} style={{ width: '23.4%' }} target='_blank' to={`/starIntroduce?id=${item.id}`}>
                                    <div className="introduce-flex-body" >
                                      <div className="introduce-flex-img" >
                                        <img
                                          style={{ width: '100%' }}
                                          alt='内容'
                                          src={item.image}
                                        />
                                      </div>
                                      <div className="introduce-flex-content">
                                        <div style={{ marginBottom: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                          <div className="introduce-flex-content-name">{item.name}</div>
                                          <div style={{ display: 'flex' }}>
                                            {item.blblUrl && (
                                                <span onClick={e => {e.stopPropagation();}}>
                                                  <a href={item.blblUrl} target="_blank">
                                                    <img className="introduce-flex-content-img" src={bzhan} alt="平台" />
                                                  </a>
                                                </span>
                                            )}
                                            {item.dyUrl && (
                                                <span onClick={e => {e.stopPropagation();}}>
                                                  <a href={item.dyUrl} target="_blank"><img className="introduce-flex-content-img" src={douyin} alt="平台" /></a>
                                                </span>
                                            )}
                                            {item.wbUrl && (
                                                <span onClick={e => {e.stopPropagation();}}>
                                                  <a href={item.wbUrl} target="_blank"><img className="introduce-flex-content-img" src={weibo} alt="平台" /></a>
                                                </span>
                                            )}
                                            {item.xhsUrl && (
                                                <span onClick={e => {e.stopPropagation()}}>
                                                  <a href={item.xhsUrl} target="_blank"><img className="introduce-flex-content-img" src={redBook} alt="平台" /></a>
                                                </span>
                                            )}
                                            {item.ksUrl && (
                                                <span onClick={e => {e.stopPropagation()}}>
                                                  <a href={item.ksUrl} target="_blank"><img className="introduce-flex-content-img" src={kuaishou} alt="平台" /></a>
                                                </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="introduce-flex-content-introduce">
                                          {item.introduce}
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                )
                              })
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </Swiper>
        </div>
      </div>
    );
  }
}
export default introduce;
