import React, { Component } from 'react';
import useAssets from '../../../common/useAssets';
const cdn = useAssets(false);

class photoList extends Component {
  render() {
    const title = '红人照片';
    const qrcode= cdn('images/introduce/qrcode.jpg')
    return (
      <div className='content-wrapper'>
        <div className='person-title'>
          <div className='bar'></div>
          <span>{title}</span>
        </div>
        {this.props.detailList.map((item, index) => {
          if (item.id === this.props.id) {
            return (
              <div className='photoList'>
                {item.images.map((item, index) => {
                  if (index > 0) {
                    return (
                      <div key={index} className='img-wrapper'>
                        <img src={item} alt='个人照片' />
                      </div>
                    );
                  }
                })}
              </div>
            );
          }
        })}
        {/*<div className='img-qrcode'>
          <img src={qrcode} alt="二维码" />
        </div>*/}
      </div>
    );
  }
}

export default photoList;
