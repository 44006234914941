/**
 * 使用图片字体视频等资源
 * @param {*} url 资源路径，不带开头部分
 * @param {*} isRequire 是否要求本地资源
 * @returns 
 */
export default (isRequire)=>{
    const BASEURL = 'https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/';
    const goAssets = (url)=>{            
        try {
            return require(`../../assets/${url}`);
        } catch (e) {
            console.error(`Assets文件夹里找不到${url}`);
            return `${BASEURL}${url}`;
        }
    };
    const goOss = (url, size = 800, def)=>{
        if(url){
            const isMp4 = url.toLowerCase().indexOf('.mp4') > -1;
            // const postfix = (url.toLowerCase().indexOf('.mp4')===-1)?'?x-oss-process=image/resize,p_73/quality,Q_100':'';
            // return `${BASEURL}${url}${postfix}`;
            if (isMp4) {
                return `${BASEURL}${url}`;
            }
            if (def) {

            }
            const postfix = def ? `?x-oss-process=image/resize,p_73/quality,Q_100` : `?x-oss-process=image/resize,w_${size}/auto-orient,1`
            return `${BASEURL}${url}${postfix}`;
        }
        return null;  
    }
    if(isRequire) {
        return goAssets;
    }
    return goOss;
}