import React from 'react';

export default function Contact() {

    return (
        <div className='contact-box'>
            <div className='contact-box-flex'>
                <div className='contact-box-item'>
                    <img alt='精致大姨夫商务合作' src='https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/marketing/contact-1.png' />
                    <div className='ft16 mt10' style={{fontWeight: 'bold'}}>精致大姨夫商务合作</div>
                </div>
                <div className='contact-box-item'>
                    <img alt='小李朝及美妆直播商务合作' src='https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/marketing/contact-2.png' />
                    <div className='ft16 mt10' style={{fontWeight: 'bold'}}>小李朝及美妆直播商务合作</div>
                </div>
                <div className='contact-box-item'>
                    <img alt='女装直播商务合作' src='https://ruhnn-web.oss-cn-hangzhou.aliyuncs.com/ruhnn-index/images/marketing/contact-3.png' />
                    <div className='ft16 mt10' style={{fontWeight: 'bold'}}>女装直播商务合作</div>
                </div>
            </div>
        </div>
    )
}