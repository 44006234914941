import React, {Component, Fragment} from 'react';
import './index.css';
import classNames from 'classnames';

class Bottom extends Component {
    constructor() {
        super();
        this.state = {currShowIndex: 0};
    }
    onClick(currShowIndex) {
        this.setState({currShowIndex});
    }
    render() {
        const {currShowIndex} = this.state;
        const {officeList = [], enjoyList = []} = this.props;
        return (
            <Fragment>
                {enjoyList.length > 0 && (
                    <div className="bottom-company enjoy flexrss">
                        <img src={enjoyList[currShowIndex].image} alt="712 531" />
                        <div className="environment flexcsc">
                            {enjoyList.map((ele, index) => (
                                <div
                                    onMouseOver={() => this.onClick(index)}
                                    className={classNames('hand box', {
                                        on: currShowIndex === index,
                                    })}
                                >
                                    <div>
                                        <label>{ele.title}</label>
                                        <p>{ele.info}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {officeList.length > 0 && (
                    <div className="bottom-company flexrss ">
                        <div className="environment flexcsc">
                            {officeList.map((ele, index) => (
                                <div
                                    onMouseOver={() => this.onClick(index)}
                                    className={classNames('hand box', {
                                        on: currShowIndex === index,
                                    })}
                                >
                                    <div>
                                        <label>{ele.title}</label>
                                        <p>{ele.info}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <img src={officeList[currShowIndex].image} alt="713 531" />
                    </div>
                )}
            </Fragment>
        );
    }
}
export default Bottom;
