import React, { Component, Fragment } from 'react';
import "./index.css";

class Gallery extends Component {


    render() {
        // const imgList = logoList
        const { maxWidth = 1210 , imgList, col = 10 } = this.props;
        return (
            <Fragment>
                {
                    imgList && imgList.length > 0 && <div className='gallery-container' style={{maxWidth:`${maxWidth}px`}}>
                        {
                            imgList.map((imgSrc, index) => {
                                return (
                                    <div className='gallery-box'  key={index} style={{width:`${Math.floor(maxWidth/col)}px`,height:`${Math.floor(maxWidth/col)}px`}}>
                                        <img src={imgSrc} alt="品牌资源"/>
                                    </div>
                                )
                            })
                        }
                    </div>

                }
            </Fragment>
        )
    }
}

export default Gallery