import React, { Component } from 'react'
import {  BrowserRouter,Route, Switch } from 'react-router-dom';
 
import BackToTop from './layout/backToTop'
import Error404 from './pages/404';
import About from './pages/about';              // 关于我们
import Home from './pages/home';                // 首页
import StarClass from './pages/starClass';      // 星课堂
import Advertising from './pages/advertising';  // 广告业务
import OnlineRetailers from './pages/onlineRetailers'; // 电商业务
import JoinUs from './pages/joinUs';                   // 加入我们
import StarResources from './pages/starResources/index';     // 红人资源
import StarIntroduce from './pages/introduce/index';     // 红人简介
import CoBranding from './pages/coBranding';           // 品牌合作
import Sharing from './pages/sharing';                 // 爱种草
import Marketing from './pages/marketing';             // 直播带货



class TheRouter extends Component {


    render() {
        return (<BrowserRouter>
                    <Switch>
                        <Route path='/' exact component={Home} />
                        <Route path='/404' component={Error404} />
                        <Route path='/about' component={About} />
                        <Route path='/home' component={Home} />
                        <Route path='/starClass' component={StarClass} />
                        <Route path='/advertising' component={Advertising} />
                        <Route path='/onlineRetailers' component={OnlineRetailers} />
                        <Route path='/starResources' component={StarResources} />
                        <Route path='/starIntroduce' component={StarIntroduce} />
                        <Route path='/coBranding' component={CoBranding} />
                        <Route path='/sharing' component={Sharing} />
                        <Route path='/marketing' component={Marketing} />
                        <Route path='/joinUs' component={JoinUs} />
                    </Switch>
                    <BackToTop />                
                </BrowserRouter>
        )
    }
}

export default TheRouter;